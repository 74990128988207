// @flow

import { put, takeLatest,all,call, takeEvery,takeLeading } from 'redux-saga/effects';
import { fetchJSON ,insertJSON,deleteJSON,SERVERURI,TOKEN} from '../../helpers/api';

import {
    GET_VILLES,GET_ETAT,ETAT_RECEIVED,GET_CATEGORIE,CATEGORIE_RECEIVED,ADD_REFERENCE,REFERENCE_ADDED,
    VILLES_RECEIVED,
    USERS_RECEIVED,
    GET_USERS,
    SET_VILLE_POSITION,UPDATE_USER,USER_UPDATED,UPDATE_USER_FAILURE,
    VILLE_POSITION_RECEIVED,UPDATE_REFERENCE,REFERENCE_UPDATED,
    INSERT_AGENCE,
    SET_SEGMENT,
    SEGMENT_ADDED,
    SEGMENT_REQUEST_FAILED,
    SEGMENT_PATCHED,
    PATCH_SEGMENT,SELECTED_ACTION,RECEIVED_SELECTED_ACTION,
    GET_FILES,FILES_RECEIVED,
    SELECTED_USER,RECEIVED_SELECTED_USER,
    AGENCE_ADDED,
    SEGMENTS_RECEIVED,
    GET_SEGMENTS,
    DELETE_SEGMENT,
    SEGMENT_DELETED,
    COMMERCIAUX_AGENCE_ADDED,
    SET_COMMERCIAUX_AGENCE,
    GET_CANAUX,
    CANAUX_RECEIVED,
    SELECTED_CANAL,
    RECEIVED_SELECTED_CANAL,
    RECEIVED_SELECTED_VILLE,
    SELECTED_VILLE,
    ACTION_RECEIVED,
    GET_ACTION,
    PLANIFIED_ACTION_RECEIVED,
    PLANIFIED_ACTION,
    CRITERES_SEGMENT_RECEIVED,
    CRITERES_SEGMENT,
    RECEIVED_SELECTED_SEGMENT,
    SELECTED_SEGMENT,
    UPLOAD_FILE,
    FILE_UPLOADED,
    SET_USER,
    USER_ADDED,
    ADD_FAILURE,MARKER_POSITION_RECEIVED,SET_MARKER_POSITION,GET_FILE_BY_NAME,FILE_RECIVED,SELECTED_PRIORITE,RECEIVED_SELECTED_PRIORITE,
    GET_CAUSE_PERTE,CAUSE_PERTE_RECEIVED,
    SET_FCM_TOKEN,FCM_TOKEN_ADDED,GET_FCM_TOKEN,FCM_TOKEN_RECEIVED,GET_CRM_DATA,CRM_DATA_RECEIVED


} from './constants';
import {addFailure} from "./actions";
import {loginUserFailed} from "../auth/actions";
import {PROSPECT_ADDED, PROSPECTS_RECEIVED} from "./prospect/constants";
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from "./prospect/saga";
import {RECEIVED_SELECTED_AGENCE} from "./agence/constants";








function notify(status,message){

    toast[status]('🌞 '+message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition:Zoom

    })
}
function* fetchVilles()
{

    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}`
           // ,'Access-Control-Allow-Origin':"https://192.168.1.214"
        },
    };
    try {
        const response = yield call(fetchJSON, SERVERURI+'/villes', options);
        yield put({ type: VILLES_RECEIVED, json: response["hydra:member"]});
    } catch (error) {
        let message;
        switch (error.status) {
            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
    }
}

function* getSelectedVille(action)
{
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}` },

    };

    const{id,ville}=action.payload

    try {

      //  const response = yield call(fetchJSON, SERVERURI+'/villes',options);
    //    yield put({ type: VILLES_RECEIVED, json: response["hydra:member"]});
        yield put({ type: RECEIVED_SELECTED_VILLE,id,ville});

    } catch (error) {
        let message;
        switch (error.status) {
            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

    }
}

function* getCrmData()
{

    const options = {
        method: 'POST',
        headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}`
        },

    };
    try {


        const response = yield call(insertJSON, SERVERURI+'/crm', options);
        yield put({ type: CRM_DATA_RECEIVED, json: response.data});
    }
    catch (error) {
        let message;
        switch (error) {
            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            case 404:
                message = 'Invalid credentials';
                break;
            default:
            //message = error;

        }
        yield put({ type: CRM_DATA_RECEIVED, json: error,status:"error", notifyMessage:"Une erreur est survenue veuillez contacter l'administrateur"});

    }
}



function* villesWatcher() {
    yield takeEvery(GET_VILLES, fetchVilles)
    yield takeEvery(GET_CRM_DATA,getCrmData)
    yield takeEvery(SELECTED_VILLE, action=>getSelectedVille(action))

}



function* setFCMTOken(action)
{
    const { fcm} = action.payload;
    console.log("hello from fcm saga:",fcm)
         yield put({ type: FCM_TOKEN_ADDED, fcm});

}


function* fcmWatcher() {
    yield takeEvery(SET_FCM_TOKEN, setFCMTOken)
}

////////////////////////////////////////
//////USERS SAGA////////////////////////
////////////////////////////////////////

//const filter ="?roles=ROLE_COORDINATEUR"
function* fetchUsers(action, baseUrl)
{
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}` },
    };
    try {
        const { role,selectName,id,agenceId} = action.payload;
        const response = yield call(fetchJSON, baseUrl+renderRole(role,id,agenceId), options);





        yield put({ type: USERS_RECEIVED, json: response,selectName,role});
    } catch (error) {
        let message;
        switch (error.status) {
            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

    }
}
function* usersWatcher() {
    yield takeEvery(GET_USERS, action=>fetchUsers(action,SERVERURI+'/users'))
}
////canaux d'acquisition/////

function* fetchCanaux()
{
    const options = {
        method: 'GET',headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}` },
    };

    try {
        const response = yield call(fetchJSON, SERVERURI+'/canals',options);
        yield put({ type: CANAUX_RECEIVED, json: response["hydra:member"]});

    } catch (error) {
        let message;
        switch (error.status) {
            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

    }
}


function* getSelectedCanal(action)
{
    const options = {
        method: 'GET',headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}`,options },
       /* headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },*/
    };

    const{canal}=action.payload

    try {

     //   const response = yield call(fetchJSON, SERVERURI+'/canals/'+canal,options);
        yield put({ type: RECEIVED_SELECTED_CANAL,canal});

    } catch (error) {
        let message;
        switch (error.status) {
            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

    }
}



function* canauxWatcher() {
    yield takeEvery(GET_CANAUX, fetchCanaux)
    yield takeEvery(SELECTED_CANAL, action=>getSelectedCanal(action))


}



////////////////////////////////////////////
function renderRole(role,id,agenceId){

    switch (role) {
        case 'affectationAgence':
            return  "?isActive=0";
        case 'selectedAffectationAgence':
            if(id!==undefined){
                return  "?agenceCommerciaux="+id;
            }
            else{
                return"?exists[agenceCommerciaux]=false"
            }

        case 'commercial':
            return  "?roles=ROLE_COMMERCIAL";
        case 'coordinateur':
            return  "?roles=ROLE_COORDINATEUR";
        case 'byId':
            return  "/"+id;

            case 'byAgence':
            return  "?agenceCommerciaux="+agenceId;
        case 'all':
            return  "";

        default:
            return "";
    }
}

/////////////////////////////////////////////////

function* setVillePosition(action)
{
        const { lat,long,population} = action.payload;
        yield put({ type: VILLE_POSITION_RECEIVED,long,lat,population}


        );

}


function* setMarkerPosition(action)
{
    const { latMarker,longMarker} = action.payload;
    yield put({ type: MARKER_POSITION_RECEIVED,latMarker,longMarker}


    );

}

function* positionWatcher() {
    yield takeEvery(SET_VILLE_POSITION, action=>setVillePosition(action))
    yield takeLatest(SET_MARKER_POSITION, setMarkerPosition)
}


//////////////////////////////////////////////////
////Création d'une nouvelle agence///////////////
/////////////////////////////////////////////////

/////////////////////////////////////////////////
////////////////////////////////////////////////
function* getCausePerte()
{
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}` },
    };
    try {
        const response = yield call(fetchJSON, SERVERURI+'/cause_pertes', options);
        yield put({ type: CAUSE_PERTE_RECEIVED, json: response["hydra:member"]});
    } catch (error) {
        let message;
        switch (error.status) {
            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
    }
}

function* getSegments()
{
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}` },
    };
    try {
        const response = yield call(fetchJSON, SERVERURI+'/segments', options);
        yield put({ type: SEGMENTS_RECEIVED, json: response["hydra:member"]});
    } catch (error) {
        let message;
        switch (error.status) {
            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
    }
}
function* setSegment(action)
{
    const { data,history} = action.payload;
    const options = {
        method: 'POST',
        headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${TOKEN()}`
        },

        body:JSON.stringify({
            nom:data.nom,
            description:data.description,
            descriptionCourte:data.descriptionCourte,
            couleur:data.couleur
        })
    }


    try {

        const response = yield call(insertJSON,SERVERURI+'/segments', options);

         yield put({
                type: SEGMENT_ADDED,
                data
            });
        yield* getSegments() //recharger la liste aprés l'insertion d'une nouvelle ligne
       /* yield call(() => {
            history.push('/configuration/Segments');
        });*/

   }
    catch (error) {
        let message;
        switch (error.status) {
            case 500:

                yield put({
                    type: SEGMENT_REQUEST_FAILED,
                    error:error.status,
                    response:"Le Champ doit etre unqiue",
                    //json: response["hydra:member"],
                });
            message="le Segment existe veuillez choisir un autre nom"


            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

    }
}
function* updateSegment(action)
{
    const { data,id} = action.payload;
    const options = {
        method: 'PUT',
        headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}`
        },

        body:JSON.stringify({
            nom:data.nom,
            description:data.description,
            descriptionCourte:data.descriptionCourte,
            couleur:data.couleur,
            id
        })
    }


    try {


             const response = yield call(fetchJSON,SERVERURI+'/segments/'+id, options);
        yield put({
            type: SEGMENT_PATCHED,data,
            id,

        });

        yield* getSegments()
   }
    catch (error) {
        let message;
        switch (error.status) {
            case 500:

                yield put({
                    type: SEGMENT_REQUEST_FAILED,
                    error:error.status,
                    response:"Le Champ doit etre unqiue",
                    //json: response["hydra:member"],
                });
                message="le Segment existe veuillez choisir un autre nom"


            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

    }
}


function* deleteSegment(action)
{
    const { history,id} = action.payload;
    const options = {
        method: 'DELETE',
        headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}`
        },

        body:JSON.stringify({
            id
        })
    }


    try {
        const response = yield call(deleteJSON ,SERVERURI+'/segments/'+id, options);
        yield put({
            type: SEGMENT_DELETED,
          id

        });

      yield* getSegments()
    }
    catch (error) {
        let message;
        switch (error.status) {

            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

    }
}

function* getSelectedSegment(action)
{
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}` },
        /* headers:{
             Accept: 'application/json',
             'Content-Type': 'application/json'
         },*/
    };

    const{segment}=action.payload

    try {

        //   const response = yield call(fetchJSON, SERVERURI+'/canals/'+canal,options);
        yield put({ type: RECEIVED_SELECTED_SEGMENT,json: segment});

    } catch (error) {
        let message;
        switch (error.status) {
            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

    }
}

function* getSelectedAction(action)
{
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}` },

    };

    const{response}=action.payload

    try {

        //   const response = yield call(fetchJSON, SERVERURI+'/canals/'+canal,options);
         yield put({ type: RECEIVED_SELECTED_ACTION,data: response["hydra:member"]});
       // yield put({ type: ACTION_RECEIVED, json: response["hydra:member"]});
      //  yield put({ type:  RECEIVED_SELECTED_USER,user});

    } catch (error) {
        let message;
        switch (error.status) {
            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

    }
}
function* getSelectedPriorite(action)
{
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}` },

    };

    const{response}=action.payload

    try {
        //   const response = yield call(fetchJSON, SERVERURI+'/canals/'+canal,options);
        yield put({ type: RECEIVED_SELECTED_PRIORITE,data: response["hydra:member"]});
        // yield put({ type: ACTION_RECEIVED, json: response["hydra:member"]});
        //  yield put({ type:  RECEIVED_SELECTED_USER,user});
    } catch (error) {
        let message;
        switch (error.status) {
            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

    }
}


function* segmentsWatcher() {

    yield takeEvery(SELECTED_SEGMENT, action=>getSelectedSegment(action))
}
/////////////////////////////////////////////////////////////////////////////////////*
//////////// Recupération de la position sur la map ///////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////
function* setCommerciauxAgence(action)
{
    const {data} = action.payload;
    try {

        yield put({ type: COMMERCIAUX_AGENCE_ADDED, data});

    }
    catch (error) {
        let message;
        switch (error.status) {
            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

    }
}



function* getActions()
{
    const options = {
        method: 'GET',headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}` },
    };
    try {
        const response = yield call(fetchJSON, SERVERURI+'/actions', options);
        yield put({ type: ACTION_RECEIVED, json: response["hydra:member"]});
    } catch (error) {
        let message;
        switch (error.status) {
            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
    }
}


function* getEtats()
{
    const options = {
        method: 'GET',headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}` },
    };
    try {
        const response = yield call(fetchJSON, SERVERURI+'/etats', options);
        yield put({ type: ETAT_RECEIVED, json: response["hydra:member"]});
    } catch (error) {
        let message;
        switch (error.status) {
            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
    }
}
function* getCategories()
{
    const options = {
        method: 'GET',headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}` },
    };
    try {
        const response = yield call(fetchJSON, SERVERURI+'/categories', options);
        yield put({ type: CATEGORIE_RECEIVED, json: response["hydra:member"]});
    } catch (error) {
        let message;
        switch (error.status) {
            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
    }
}



function* getPlanifiedActions()
{
    const options = {
        method: 'GET',headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}` },
    };
    try {
        const response = yield call(fetchJSON, SERVERURI+'/actions?planned=1', options);
        yield put({ type: PLANIFIED_ACTION_RECEIVED, json: response["hydra:member"]});
    } catch (error) {
        let message;
        switch (error.status) {
            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
    }
}

function* getCriteresSegment(action)
{
    const {idSegment} = action.payload
    const options = {
        method: 'GET',headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}` },
    };
    try {
        const response = yield call(fetchJSON, SERVERURI+'/critere_segments/'+idSegment, options);
        yield put({ type: CRITERES_SEGMENT_RECEIVED, json: response["hydra:member"]});
    } catch (error) {
        let message;
        switch (error.status) {
            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
    }
}


function* addReference(action)
{
    const {data} = action.payload;

    const options = {
        method: 'POST',
        headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}`
        },
        body:JSON.stringify({
            ref:data.refValue,
            InsttallationReferenceSteg:data.instId,
            type:"/gpcCRMv0/public/api/type_installations/1",
            Abonnement:"/gpcCRMv0/public/api/type_abonnements/1",
            dateDemande:new Date(),
            //new Date()
        })
    }


    try {


        const response = yield call(insertJSON,SERVERURI+'/references', options);

        yield put({
            type: REFERENCE_ADDED,json: response["hydra:member"],statusAddReference:["success","La référence a été ajoutée avec succés"]
        });

        /*   yield put({
               type: PROSPECT_ADDED,
               data,
               status:"success",notifyMessage:"Félicitations le prospect a été ajouté avec succés"
           });
           */

        //   yield* getSegments()
    }
    catch (error) {
        let message;
        switch (error.status) {
            case 500:
                yield put({
                    type: SEGMENT_REQUEST_FAILED,
                    error:error.status,
                    response:"Le Champ doit etre unqiue",
                    //json: response["hydra:member"],
                });
                message="le Segment existe veuillez choisir un autre nom"
            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put({
            type: PROSPECT_ADDED,

            statusAddReference:"error",notifyMessageAddReference:"Une erreur est survenue veuillez réessayer"

        });

    }
}


function* updateReference(action)
{
    const { data} = action.payload;
    const options = {
        method: 'PUT',
        headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}`
        },
        body:JSON.stringify({
            ref:data.refValue,


        })
    }


    try {


        const response = yield call(fetchJSON,SERVERURI+'/references/'+data.idRef, options);

        yield put({
            type: REFERENCE_UPDATED,json: response["hydra:member"],statusUpdateReference:"success",notifyMessageUpdateReference:"La réference a été mis à jour"
        });

     /*   yield put({
            type: PROSPECT_ADDED,
            data,
            status:"success",notifyMessage:"Félicitations le prospect a été ajouté avec succés"
        });
        */

     //   yield* getSegments()
    }
    catch (error) {
        let message;
        switch (error.status) {
            case 500:
            yield put({
                    type: SEGMENT_REQUEST_FAILED,
                    error:error.status,
                    response:"Le Champ doit etre unqiue",
                });
                message="le Segment existe veuillez choisir un autre nom"
            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put({
            type: PROSPECT_ADDED,
            statusUpdateReference:"error",notifyMessageUpdateReference:"Une erreur est survenue pour la création de la réference. Veuillez réessayer"
        });

    }
}


function* segmentWatcher() {
    yield takeEvery(SET_COMMERCIAUX_AGENCE, action=>setCommerciauxAgence(action))
 // yield takeLatest(SET_COMMERCIAUX_AGENCE, setCommerciauxAgence)
    yield takeEvery(SELECTED_ACTION, getSelectedAction)
    yield takeEvery(SELECTED_PRIORITE, getSelectedPriorite)
    yield takeEvery(SET_SEGMENT, setSegment)
    yield takeEvery(GET_SEGMENTS, getSegments)
    yield takeEvery(GET_ACTION, getActions)
    yield takeEvery(GET_ETAT, getEtats)
    yield takeEvery(GET_CATEGORIE, getCategories)
    yield takeEvery(PATCH_SEGMENT, updateSegment)
    yield takeEvery(ADD_REFERENCE, addReference)
    yield takeEvery(UPDATE_REFERENCE, updateReference)
    yield takeEvery(DELETE_SEGMENT, deleteSegment)
    yield takeEvery(PLANIFIED_ACTION, getPlanifiedActions)
    yield takeEvery(CRITERES_SEGMENT, getPlanifiedActions)
}


function* setUser(action)
{
    const { data,history} = action.payload;
    let  photoProfil=''


    const options1 = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}` },
    };
    try {

        const response1 = yield call(fetchJSON, SERVERURI+'/media_objects?filePath='+data.commercial.photo, options1);
        yield put({ type: FILE_RECIVED, file: response1["hydra:member"]});
        photoProfil=response1["hydra:member"][0]['@id']

    }
    catch (error) {


        let message;
        switch (error) {
            case 400:
                message="le Agence existe veuillez choisir un autre nom"
            case 500:
                message = 'Erreur! vérifier les données saisies ';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = "Veuillez Choisir une autre photo";
        }
        //  yield put(addFailure(message,));

        //  yield put({ type: ADD_FAILURE, json: error, status:"error", notifyMessage:message, dateNotify:new Date()});


    }

    let dateNaissance=data.commercial.dateNaissance
    if(data.commercial.dateNaissance!==null){dateNaissance=data.commercial.dateNaissance}else{dateNaissance=moment()}
    let updatePhotoProfil=null
    let commercialData=null
    if( data.commercial.photo===""){
        commercialData={

            telperso1: data.commercial.telperso1,
            telperso2:data.commercial.telperso2,
            emailperso:data.commercial.emailperso,
            dateNaissance:dateNaissance,
            ville:data.commercial.villeCommercial,
            tel:data.commercial.tel,
            id:data.commercial.id,
            cin:data.commercial.cin,
            genre:data.commercial.genre,
            lngCommercial: parseFloat(data.commercial.lngCommercial),
            latCommercial: parseFloat(data.commercial.latCommercial),

            adresse:data.commercial.adresse,



        }
    }
    else {
        commercialData={

            telperso1: data.commercial.telperso1,
            telperso2:data.commercial.telperso2,
            emailperso:data.commercial.emailperso,
            dateNaissance:dateNaissance,
            ville:data.commercial.villeCommercial,
            tel:data.commercial.tel,
            cin:data.commercial.cin,
            genre:data.commercial.genre,
            lngCommercial: parseFloat(data.commercial.lngCommercial),
            latCommercial: parseFloat(data.commercial.latCommercial),
            photo:photoProfil,
            adresse:data.commercial.adresse,



        }
    }


    const options = {
        method: 'POST',
        headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}`
        },
        body:JSON.stringify({
            Nom:data.nom,
            prenom:data.prenom,
            password:data.password,
            email:data.email,
            roles:data.roles,
            isActive:data.isACtive==='true'||data.isACtive===true?true:false,
            isBlocked:data.isBlocked==='true'||data.isBlocked===true?true:false,
            commercial:commercialData,
            ObjectifCommercialsUser:data.objectifs,
            agenceCommerciaux:data.agenceCommerciaux,


        })
    }


    try {

        const response = yield call(insertJSON,SERVERURI+'/users', options);

        yield put({
            type: USER_ADDED,
            data
        });

         yield call(() => {
             history.push('/configuration/Commerciaux');
         });

    }
    catch (error) {


        let message;
        switch (error) {
            case 400:
               message="le Agence existe veuillez choisir un autre nom"
            case 500:
                message=JSON.stringify(error) /*= 'Erreur! vérifier les données saisies '*/;
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = "Une erreur est survenue veuillez contacter l'administateur";
        }
      //  yield put(addFailure(message,));

        yield put({ type: ADD_FAILURE, json: error, status:"error", notifyMessage:message, dateNotify:new Date()});
        yield call(() => {
            history.push('/configuration/NouveauCommercial');
        });

    }
}
/// updating user Data
function* updateUser(action)
{  const { data,history} = action.payload;


    let  photoProfil=''
    const options1 = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}` },
    };
    try {

        const response1 = yield call(fetchJSON, SERVERURI+'/media_objects?filePath='+data.commercial.photo, options1);
        yield put({ type: FILE_RECIVED, file: response1["hydra:member"]});
        photoProfil=response1["hydra:member"][0]['@id']

    }
    catch (error) {


        let message;
        switch (error) {
            case 400:
                message="le Agence existe veuillez choisir un autre nom"
            case 500:
                message = 'Erreur! vérifier les données saisies ';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = "Veuillez Choisir une autre photo";
        }


    }

    let dateNaissance=data.commercial.dateNaissance
    if(data.commercial.dateNaissance!==null){dateNaissance=data.commercial.dateNaissance}else{dateNaissance=moment()}
    let updatePhotoProfil=null
    let commercialData=null
    if( data.commercial.photo===""){
       commercialData={

           telperso1: data.commercial.telperso1,
           telperso2:data.commercial.telperso2,
           emailperso:data.commercial.emailperso,
           dateNaissance:dateNaissance,
           ville:data.commercial.villeCommercial,
           tel:data.commercial.tel,
           id:data.commercial.id,
           cin:data.commercial.cin,
           genre:data.commercial.genre,
           lngCommercial: parseFloat(data.commercial.lngCommercial),
           latCommercial: parseFloat(data.commercial.latCommercial),

           adresse:data.commercial.adresse,

        }


    }
    else {
        commercialData={
            telperso1: data.commercial.telperso1,
            telperso2:data.commercial.telperso2,
            emailperso:data.commercial.emailperso,
            dateNaissance:dateNaissance,
            ville:data.commercial.villeCommercial,
            tel:data.commercial.tel,
            id:data.commercial.id,
            cin:data.commercial.cin,
            genre:data.commercial.genre,
            lngCommercial: parseFloat(data.commercial.lngCommercial),
            latCommercial: parseFloat(data.commercial.latCommercial),
            photo:photoProfil,
            adresse:data.commercial.adresse,

        }

    }

    const options = {
        method: 'PUT',
        headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}`
        },
        body:JSON.stringify({
            Nom:data.nom,
            prenom:data.prenom,
            password:data.password,
            email:data.email,
            roles:data.roles,
            isActive:data.isACtive==="true"||data.isACtive===true?true:false,
            isBlocked:data.isBlocked==="true"||data.isBlocked===true?true:false,
            commercial:commercialData,
            ObjectifCommercialsUser:data.objectifs,
            agenceCommerciaux:data.agenceCommerciaux,
        })
    }
    try {
        const response = yield call(fetchJSON,SERVERURI+'/users/'+data.id, options);
        yield put({
            type: USER_UPDATED,
            data,
            status:"success", notifyMessage:"Mise à jour effectuée", dateNotify:new Date()
        });
       /* yield call(() => {
            history.push('/');
        })*/
      ///  yield put({ type: UPDATE_USER_FAILURE, json: error, status:"error", notifyMessage:message, dateNotify:new Date()});
      /*  yield call(() => {

            history.push('/configuration/Commerciaux');
        });*/

    }
    catch (error) {


        let message;
        switch (error) {
            case 400:
                message="le Agence existe veuillez choisir un autre nom"
            case 500:
                message = 'Erreur! vérifier les données saisies ';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = "Une erreur est survenue veuillez contacter l'administateur";
        }
        //  yield put(addFailure(message,));

        yield put({ type: UPDATE_USER_FAILURE, json: error, status:"error", notifyMessage:message, dateNotify:new Date()});


    }
}


function* getSelectedUser(action)
{
    const options = {
        method: 'GET',headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}` },
        /* headers:{
             Accept: 'application/json',
             'Content-Type': 'application/json'
         },*/
    };

    const{user}=action.payload

    try {

        //   const response = yield call(fetchJSON, SERVERURI+'/canals/'+canal,options);
        yield put({ type:  RECEIVED_SELECTED_USER,user});

    } catch (error) {
        let message;
        switch (error.status) {
            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

    }
}
function* getFileByName(action)
{
    const{name,server}=action.payload


    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}` },
    };
    try {
        const response = yield call(fetchJSON,server+'?filePath='+name, options);
        yield put({ type: FILE_RECIVED, file: response["hydra:member"]});
    } catch (error) {
        let message;
        switch (error.status) {
            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
    }
}


function* getFiles(action)
{
    const{uploadedFiles,filesToRemove,server}=action.payload

    let name=""

    uploadedFiles.map((file,index)=>{
        if(index===0)
        {
            name=file
        }
        else {
            name=name+"&filePath[]="+file
        }

    })
    let remove=""

    filesToRemove.map((file,index)=>{
        if(index===0)
        {
            remove=file
        }
        else {
            remove=name+"&filePath[]="+file
        }

    })




    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}` },
    };
    const optionsDelete = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}` },
    };

    try {
        let response = yield call(fetchJSON, server+'/?filePath[]='+name, options);
        const responseRemove = yield call(fetchJSON, server+'/?filePath[]='+remove, options);


if(responseRemove.length>0)
{
    yield call(
        responseRemove["hydra:member"].map((file)=>{
            deleteJSON(server+'/'+file.id,
                optionsDelete
            )

        })

    )

}

       yield put({type: FILES_RECEIVED, uploadedFiles:response["hydra:member"], filesToRemove: responseRemove["hydra:member"]});
    } catch (error) {
        let message;
        switch (error.status) {
            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
    }
}

function* userWatcher() {
    yield takeEvery(GET_FILES,getFiles)
    yield takeEvery(GET_FILE_BY_NAME,getFileByName)
    yield takeEvery(SET_USER, setUser)
    yield takeEvery(UPDATE_USER, updateUser)
    yield takeEvery(SELECTED_USER, getSelectedUser)
    yield takeEvery(GET_CAUSE_PERTE, getCausePerte)
}



////////////////////////////////////////
//////USERS SAGA////////////////////////
////////////////////////////////////////




export default function* crmSaga() {
    yield all([
        villesWatcher(), usersWatcher(),positionWatcher(),segmentWatcher(),canauxWatcher(),userWatcher(),fcmWatcher()
    ]);
}
