// @flow

import { put, takeLatest,all,call, takeEvery,takeLeading } from 'redux-saga/effects';
import { fetchJSON ,insertJSON,SERVERURI,TOKEN} from '../../../helpers/api';
import {SET_AGENCE,AGENCE_ADDED,AGENCE_REQUEST_FAILED,AGENCE_PATCHED,PATCH_AGENCE, RECEIVED_SELECTED_AGENCE, SELECTED_AGENCE, AGENCES_RECEIVED,GET_AGENCES,DELETE_AGENCE, AGENCE_DELETED} from './constants';
import {GET_USERS, RECEIVED_SELECTED_SEGMENT, USERS_RECEIVED} from "../constants";

function* getAgences( action, baseUrl)
{
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${TOKEN()}`,
        },

    };
    try {
        const { role,selectName,id,agenceId} = action.payload;
        const response = yield call(fetchJSON, SERVERURI+'/agences'+renderRole(role,id,agenceId), options);
        yield put({ type: AGENCES_RECEIVED, json: response,role});
    } catch (error) {
        let message;
        switch (error.status) {
            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
    }
}
function* setAgence(action)
{
    const { data,history} = action.payload;


    const options = {
        method: 'POST',
        headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}`
        },

        body:JSON.stringify({

            Nom:data.Nom,
            villeAgence: data.villeAgence,
            adresse:data.adresse,
            lng:parseFloat(data.lng),
            lat:parseFloat(data.lat),
            email:data.email,
            tel:data.tel,
            isActive:true,
            commerciauxAgence:data.commerciauxAgence,
            chefAgence:data.chefAgence,
            coordinateurAgence:data.coordinateurAgence,
            agentSteg:data.agentSteg,
            objectifAgences:data.objectifAgences,
        })
    }


    try {

        const response = yield call(insertJSON,SERVERURI+'/agences', options);

         yield put({
                type: AGENCE_ADDED,
                data
            });
        yield call(() => {

            history.push('/configuration/Agences');
        });
   }
    catch (error) {
        let message;
        switch (error.status) {
            case 500:

                yield put({
                    type: AGENCE_REQUEST_FAILED,
                    error:error.status,
                    response:"Le Champ doit etre unqiue",
                    //json: response["hydra:member"],
                });
            message="le Agence existe veuillez choisir un autre nom"


            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = "erreur";
        }

    }
}
function* updateAgence(action)
{
    const { data,history} = action.payload;
    const options = {
        method: 'PUT',
        headers:{
          Accept: 'application/json',
          'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}`
        },
        body:JSON.stringify({
            Nom:data.Nom,
            villeAgence: data.villeAgence,
            adresse:data.adresse,
            lng:parseFloat(data.lng),
            lat:parseFloat(data.lat),
            email:data.email,
            tel:data.tel,
            isActive:true,
            commerciauxAgence:data.commerciauxAgence,
            chefAgence:data.chefAgence,
            coordinateurAgence:data.coordinateurAgence,
            agentSteg:data.agentSteg,
          //  agenceSegment:data.agenceSegment,
            objectifAgences:data.objectifAgences,
            opportunitesAgence:data.opportunitesAgence,
            prospectsAgence:data.prospectsAgence,

            // description:data.description,
          //  descriptionCourte:data.descriptionCourte,
           // couleur:data.couleur,
           // id
        })
    }


    try {



        const response = yield call(fetchJSON,SERVERURI+'/agences/'+data.id, options);
        yield put({
            type: AGENCE_PATCHED,
            data,

        });
        yield call(
           // getAgences,
            () => {

            history.push('/configuration/Agences');
        });
   }
    catch (error) {
        let message;
        switch (error.status) {
            case 500:

                yield put({
                    type: AGENCE_REQUEST_FAILED,
                    error:error.status,
                    response:"Le Champ doit etre unqiue",
                    //json: response["hydra:member"],
                });
                message="le Agence existe veuillez choisir un autre nom"


            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

    }
}


function* deleteAgence(action)
{
    const { history,id} = action.payload;
    const options = {
        method: 'DELETE',
        headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}`
        },

        body:JSON.stringify({
            id
        })
    }


    try {




        const response = yield call(fetchJSON,SERVERURI+'/agences/'+id, options);
        yield put({
            type: AGENCE_DELETED,
            //data,

        });


    }
    catch (error) {
        let message;
        switch (error.status) {
            case 500:

                yield put({
                    type: AGENCE_REQUEST_FAILED,
                    error:error.status,
                    response:"Le Champ doit etre unqiue",
                    //json: response["hydra:member"],
                });
                message="le Agence existe veuillez choisir un autre nom"


            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

    }
}
function* getSelectedAgence(action)
{
    const options = {
        method: 'GET',headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}` },
        /* headers:{
             Accept: 'application/json',
             'Content-Type': 'application/json'
         },*/
    };

    const{agence}=action.payload

    try {

        //   const response = yield call(fetchJSON, SERVERURI+'/canals/'+canal,options);
        yield put({ type:  RECEIVED_SELECTED_AGENCE,agence});

    } catch (error) {
        let message;
        switch (error.status) {
            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }

    }
}

function* agenceWatcher() {
    yield takeEvery(GET_AGENCES, getAgences)
   yield takeEvery(SET_AGENCE, setAgence)
   // yield takeEvery(GET_USERS, action=>fetchUsers(action,SERVERURI+'/users'))

   // yield takeLeading(PATCH_AGENC)
   yield takeEvery(PATCH_AGENCE, updateAgence)
   yield takeEvery(DELETE_AGENCE, deleteAgence)
    yield takeEvery(SELECTED_AGENCE, getSelectedAgence)

}
////////////////////////////////////////
//////USERS SAGA////////////////////////
////////////////////////////////////////


function formatString(valeur){
    if(typeof valeur==="string")
        return parseFloat(valeur.replace(/\s/g, ''))
    else return valeur
}
function renderRole(role,id,agenceId){

    switch (role) {

        case 'agencesActives':
            return  "?isActive=1";
        case 'commercial':
            return  "?roles=ROLE_COMMERCIAL";
        case 'coordinateur':
            return  "?roles=ROLE_COORDINATEUR";
        case 'byId':
            return  "/"+id;

        case 'selectedAffectationAgence':
            return  "?agenceCommerciaux="+agenceId;
        case 'all':

            return  "";

        default:

            return "";
    }
}
export default function* crmSaga() {
    yield all([
        agenceWatcher()
    ]);
}
