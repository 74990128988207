import {
    SET_AGENCE,
    AGENCE_ADDED,
    AGENCE_REQUEST_FAILED,
    AGENCES_RECEIVED,
    GET_AGENCES,
    PATCH_AGENCE,
    AGENCE_PATCHED,
    DELETE_AGENCE, AGENCE_DELETED,
    RECEIVED_SELECTED_AGENCE, SELECTED_AGENCE,


} from './constants';
import {GET_USERS, SELECTED_SEGMENT} from "../constants";

export const setAgence = (data,history) => ({
    type: SET_AGENCE,
    payload: {data,history},
});

export const agenceAdded = (data, history) => ({
    type: AGENCE_REQUEST_FAILED,
    payload: { data, history},
});
export const updateAgence = (data,history,id) => ({
    type: PATCH_AGENCE,
    payload: {data,history,id},
});

export const agencePatched = (data, history,id) => ({
    type: AGENCE_PATCHED,
    payload: { data, history,id},
});
export const deleteAgence = (history,id) => ({
    type: DELETE_AGENCE,
    payload: {history,id},
});

export const agenceDeleted = (history,id) => ({
    type: AGENCE_PATCHED,
    payload: { history,id},
});

export const getAgences = (role,id,agenceId) => ({
    type: GET_AGENCES,
    payload: { role,id,agenceId},
});

export const agencesReceived = (role,id,agenceId) => ({
    type: AGENCES_RECEIVED,
    payload: { role,id,agenceId},

});

export const getSelectedAgence = (agence) => ({
    type: SELECTED_AGENCE,
    payload: { agence},
});