import React, { useState, useEffect } from "react";
import { getToken } from "../../firebaseInit.js";
import {
  setFCMToken
} from '../../redux/crm/actions';
import { connect } from 'react-redux';
const Notifications = (props) => {
  const [isTokenFound, setTokenFound] = useState(false);

  console.log("Token found", isTokenFound);

  // To load once
  useEffect(() => {
    let data;

    async function tokenFunc() {
      data = await getToken(setTokenFound);
      if (data) {
        console.log("Token is", data);


        console.log("subscribing fcm token:::", data)
        props.setFCMToken(data)

      }
      return data;
    }

    tokenFunc();
  }, [setTokenFound]);

  return <></>;
};
const mapDispatchToProps = {
  setFCMToken
};

const mapStateToProps = (state) => ({

  fcm:state.Crm.fcm,
  //
  /* expiredTaches: state.Tache.expiredTaches,*/
});
Notifications.propTypes = {};
export default connect(mapStateToProps, mapDispatchToProps, null)(Notifications);