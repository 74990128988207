import React,{Suspense} from 'react';
import { connect } from 'react-redux';
import { getActions, usersReceived, getSelectedAction,getSelectedPriorite } from '../redux/crm/actions';
import { AvField, AvGroup, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import { CustomInput, FormGroup, Label } from 'reactstrap';
import Loader from './Loader';

class ActionSelectSingle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            priorities: [{}],
            selectedPriorite: 0,
            // selectedAction: null,
        };
        this.handleChange = this.handleChange.bind(this);
        this.prioriteChange = this.prioriteChange.bind(this);
        this.getSegmentAction= this.getSegmentAction.bind(this);

        //   this.expose_actions = this.expose_actions.bind(this);
    }

    prioriteChange =(e,priorite)=>{
       // this.props.getSelectedPriorite(priorite)

        this.setState({
            selectedPriorite:priorite
        },function () {
            this.props.getSelectedPriorite(priorite)
        })

    }
    handleChange = (e) => {
        let id = e.target.value;
        let cible = this.state.actions.filter((act) => act.id.toString() === id.toString());
        this.setState({
            selectedAction: cible[0],
            selectedPriorite:cible[0].Priorites[0],
        },function () {
            this.props.getSelectedAction(cible[0])
        });
    };

    getSegmentAction = (segments,id) => {

        let segment= segments.filter((seg)=>seg.id==id)

        let actions=[]
        segment[0].pipelines.map((pipeline)=>{
            actions.push(pipeline.action)
        })
        return actions

    };

    componentDidMount() {
      let actions= this.getSegmentAction(this.props.data.segments,this.props.segment)
        this.props.getSelectedPriorite(actions[0].Priorites[0])
        this.props.getSelectedAction(actions[0])
        this.setState({
            actions:actions,
            selectedSegment:this.props.segment,
            selectedAction:actions[0],
            selectedPriorite:actions[0].Priorites[0],
        },function () {

        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if(prevProps.selectedSegment!==this.props.selectedSegment ){

            let actions= this.getSegmentAction(this.props.data.segments,this.props.selectedSegment.segment.id)

            this.setState({
                actions:actions,
                selectedAction:actions[0],
                selectedPriorite:actions[0].Priorites[0],
            },function () {
                    
            })
        }



    }

    expose_actions = () =>
        (
            <AvGroup>


                <AvField
                    type="select"
                    name={this.props.name}
                    label={this.props.label}
                    helpMessage={this.props.helpMessage}

                    onChange={this.handleChange}
                    /*value={this.state.selectedAction === null ? this.props.actions[0].id : this.state.selectedAction}*/

                    value={
                        this.state.selectedAction === null || this.state.selectedAction === undefined
                            ? this.props.default.id
                            : this.state.selectedAction.id
                    }>
                    {this.state.actions.map((action, index) => (
                        <option key={index} value={action.id}>
                            {action.nom}{' '}
                        </option>
                    ))}
                </AvField>


                  {this.state.selectedAction.Priorites.length > 0 ? (
                    <AvRadioGroup inline name="urgence" label="Priorités" required value={this.state.selectedAction.Priorites[0].id}>
                        {this.state.selectedAction.Priorites.map((priorite, index) => (
                            <AvRadio
                                key={index}
                                type="radio"
                                id={index}
                                label={priorite.nom + ' (' + priorite.delais + 'h)'}
                                value={priorite.id}
                                onChange={(e)=>this.prioriteChange(e,priorite)}
                            />
                        ))}
                    </AvRadioGroup>
                ) : null}
            </AvGroup>
        )

    render() {
        return (
            <div>

                {
                    this.state.selectedAction!==undefined&&this.state.actions!==undefined?
                    this.expose_actions(this.state.actions):
                   <Loader></Loader>

                }
            </div>
        );
    }
}

const mapDispatchToProps = {
    getActions,
    getSelectedAction,
    getSelectedPriorite,
};
const mapStateToProps = (state, action) => ({
    data: state.Crm.data,
  //  actions: state.Crm.actions,
    selectedAction: state.Crm.selectedAction,
    selectedPriorite: state.Crm.selectedPriorite,
    selectedSegment:state.Crm.selectedSegment,

});
ActionSelectSingle = connect(mapStateToProps, mapDispatchToProps, null)(ActionSelectSingle);

export default ActionSelectSingle;
