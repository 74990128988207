import {
    GET_FILES,FILES_RECEIVED,
    UPLOAD_FILE,FILE_UPLOADED,SELECTED_ACTION,RECEIVED_SELECTED_ACTION,ADD_REFERENCE,REFERENCE_ADDED,
    SET_USER,USER_ADDED,
    UPDATE_USER,USER_UPDATED,UPDATE_USER_FAILURE,
    GET_VILLES,
    VILLES_RECEIVED,
    GET_USERS,
    USERS_RECEIVED,
    SET_VILLE_POSITION,
    INSERT_AGENCE,
    AGENCE_ADDED,
    SET_SEGMENT,
    SEGMENT_ADDED,
    SEGMENT_REQUEST_FAILED,
    SEGMENTS_RECEIVED,
    GET_SEGMENTS,
    PATCH_SEGMENT,
    SEGMENT_PATCHED,
    DELETE_SEGMENT,
    SEGMENT_DELETED,
    COMMERCIAUX_AGENCE_ADDED,
    SET_COMMERCIAUX_AGENCE,
    GET_CANAUX,
    CANAUX_RECEIVED,
    SELECTED_CANAL,
    RECEIVED_SELECTED_VILLE,
    SELECTED_VILLE,
    GET_CAUSE_PERTE,
    CAUSE_PERTE_RECEIVED,
    GET_ACTION,GET_FILE_BY_NAME,FILE_RECIVED,SELECTED_PRIORITE,RECEIVED_SELECTED_PRIORITE,GET_ETAT,ETAT_RECEIVED,GET_CATEGORIE,CATEGORIE_RECEIVED,
    ACTION_RECEIVED,PLANIFIED_ACTION_RECEIVED,PLANIFIED_ACTION,UPDATE_REFERENCE,REFERENCE_UPDATED,
    CRITERES_SEGMENT_RECEIVED,CRITERES_SEGMENT,RECEIVED_SELECTED_SEGMENT,SELECTED_SEGMENT,ADD_FAILURE,SET_MARKER_POSITION,MARKER_POSITION_RECEIVED,SELECTED_USER,RECEIVED_SELECTED_USER,
    SET_FCM_TOKEN,FCM_TOKEN_ADDED,GET_FCM_TOKEN,FCM_TOKEN_RECEIVED,CRM_DATA_RECEIVED,GET_CRM_DATA

} from './constants';
import {AGENCE_REQUEST_FAILED, SET_AGENCE} from "./agence/constants";
import {LOGIN_USER_FAILED} from "../auth/constants";
import {GET_PROSPECTS, PROSPECTS_RECEIVED} from "./prospect/constants";

export const getVilles = () => ({
    type: GET_VILLES,
});

export const villesReceived = () => ({
    type: VILLES_RECEIVED,

});

export const getUsers = (role,selectName,id,agenceId) => ({
    type: GET_USERS,
    payload: { role,selectName,id,agenceId},
});

export const usersReceived = (role,selectName,id,agenceId) => ({
    type: USERS_RECEIVED,
    payload: { role,selectName,id,agenceId},
});


export const getCanaux = () => ({
    type: GET_CANAUX,
   // payload: { role,selectName},
});

export const canauxReceived = () => ({
    type: CANAUX_RECEIVED,
   // payload: { role},
});


export const getSelectedCanal = (canal) => ({
    type: SELECTED_CANAL,
     payload: { canal},
});



export const getSelectedVille = (id,ville) => ({
    type: SELECTED_VILLE,
    payload: { id,ville},
});

export const setVillePosition = (lat,long,population) => ({
    type: SET_VILLE_POSITION,
    payload: {lat,long,population},
});

export const setMarkerPosition = (latMarker,longMarker) => ({
    type: SET_MARKER_POSITION,
    payload: {latMarker,longMarker},
});

export const setAgence = (data) => ({
    type: INSERT_AGENCE,
    payload: {data},
});



export const setSegment = (data,history) => ({
    type: SET_SEGMENT,
    payload: {data,history},
});

export const segmentAdded = (data, history) => ({
    type: SEGMENT_REQUEST_FAILED,
    payload: { data, history},
});
export const updateSegment = (data,id) => ({
    type: PATCH_SEGMENT,
    payload: {data,id},
});

export const segmentPatched = (data, id) => ({
    type: SEGMENT_PATCHED,
    payload: { data, id},
});
export const deleteSegment = (id) => ({
    type: DELETE_SEGMENT,
    payload: {id},
});

export const segmentDeleted = (id) => ({
    type: SEGMENT_PATCHED,
    payload: {id},
});
export const getSegments = () => ({
    type: GET_SEGMENTS,
});

export const segmentsReceived = () => ({
    type: SEGMENTS_RECEIVED,

});
export const setCommerciauxAgence = (data) => ({
    type: SET_COMMERCIAUX_AGENCE,
    payload: { data},
});

export const commerciauxAgenceReceived = (data) => ({
    type: COMMERCIAUX_AGENCE_ADDED,
    payload: { data},
});

export const getActions = () => ({
    type: GET_ACTION,
});
export const actionsRecieved = () => ({
    type: ACTION_RECEIVED,
});

export const getEtats= () => ({
    type: GET_ETAT,
});
export const etatsRecieved = () => ({
    type: ETAT_RECEIVED,
});

export const getCategories= () => ({
    type: GET_CATEGORIE,
});
export const categoriesRecieved = () => ({
    type: CATEGORIE_RECEIVED,
});

export const getPlanifiedActions = () => ({
    type: PLANIFIED_ACTION,
});
export const planifiedActionsRecieved = () => ({
    type: PLANIFIED_ACTION_RECEIVED,
});

export const getCriteresSegment = (idSegment) => ({
    type: CRITERES_SEGMENT,
    payload:{idSegment}
});
export const criteresSegmentRecieved = () => ({
    type: CRITERES_SEGMENT_RECEIVED
});

export const getSelectedSegment = (segment) => ({
    type: SELECTED_SEGMENT,
    payload: { segment},
});


export const getSelectedAction = (data) => ({
    type: SELECTED_ACTION,
    payload: {data},
});

export const getReceivedSelectedAction = (data) => ({
    type: RECEIVED_SELECTED_PRIORITE,
    payload: {data},
});
export const getSelectedPriorite = (data) => ({
    type: SELECTED_PRIORITE,
    payload: {data},
});

export const getReceivedSelectedPriorite = (data) => ({
    type: RECEIVED_SELECTED_ACTION,
    payload: {data},
});

export const getCausePerte = (data) => ({
    type: GET_CAUSE_PERTE,
    payload: {data},
});

export const getReceivedCausePerte = (data) => ({
    type: CAUSE_PERTE_RECEIVED,
    payload: {data},
});
export const getSelectedUser = (user) => ({
    type: SELECTED_USER,
    payload: {user},
});



export const setUser = (data,history) => ({
    type: SET_USER,
    payload: {data,history},
});

export const userAdded = (data, history) => ({
    type: USER_ADDED,
    payload: { data, history},
});

export const addFailure= (error) => ({
    type: ADD_FAILURE,
    payload: error,
});

export const updateUser = (data,history) => ({
    type: UPDATE_USER,
    payload: {data,history},
});

export const userUpdated = (data, history) => ({
    type: USER_UPDATED,
    payload: { data, history},
});

export const updateUserFailure= (error) => ({
    type: UPDATE_USER_FAILURE,
    payload: error,
});


export const getFileByName = (name,server) => ({
    type: GET_FILE_BY_NAME,
    payload: { name,server},
});

export const getFiles = (uploadedFiles,filesToRemove,server) => ({
    type: GET_FILES,
    payload: {uploadedFiles,filesToRemove,server},
});

export const filesReceived = (uploadedFiles,filesToRemove) => ({
    type: FILES_RECEIVED,
    payload: { uploadedFiles,filesToRemove},
});
export const updateReference = (data) => ({
    type: UPDATE_REFERENCE,
    payload: {data},
});

export const referenceUpdated = (data) => ({
    type: REFERENCE_UPDATED,
    payload: { data},
});


export const addReference = (data) => ({
    type: ADD_REFERENCE,
    payload: {data},
});

export const referenceAdded = (data) => ({
    type: REFERENCE_ADDED,
    payload: { data},
});


export const setFCMToken = (fcm) => ({
    type: SET_FCM_TOKEN,
    payload: {fcm},
});

export const FCMTokenAdded = (fcm) => ({
    type: FCM_TOKEN_ADDED,
    payload: {fcm},
});


export const getCrmData = () => ({
    type: GET_CRM_DATA,

});
export const crmDataReceived = () => ({
    type: CRM_DATA_RECEIVED,

});