// @flow
import {
   
    SET_AGENCE,
    AGENCE_ADDED,
    AGENCE_REQUEST_FAILED,
    GET_AGENCES,
    AGENCES_RECEIVED, AGENCE_PATCHED,PATCH_AGENCE,DELETE_AGENCE, AGENCE_DELETED,
    RECEIVED_SELECTED_AGENCE, SELECTED_AGENCE,
} from './constants';
import {RECEIVED_SELECTED_SEGMENT, SELECTED_SEGMENT, USERS_RECEIVED} from "../constants";


const Agence = (state ={}, action) => {
    switch (action.type) {
      
        case SET_AGENCE:
            return { ...state, agenceAdded:true,loading: true };
        case AGENCE_ADDED:
            return { ...state, loading:false };

        case PATCH_AGENCE:
            return { ...state, agencePatched:true,loading: true };
        case AGENCE_PATCHED:
            return { ...state, loading:false };

        case DELETE_AGENCE:
            return { ...state, agenceDeleted:true,loading: true };
        case AGENCE_DELETED:
            return { ...state, loading:false };
        case AGENCE_REQUEST_FAILED:
            return { ...state,  error:action.error, loading:false };

        case GET_AGENCES:
            return { ...state, loading: true };

        case AGENCES_RECEIVED:
           // return { ...state, agences:action.json, loading: false }
            switch (action.role) {

                case 'agencesActives':
                    return { ...state, agencesActives: action.json['hydra:member'], role: action.role, loading: false };
                case 'commercial':
                    return { ...state, commercial: action.json['hydra:member'], role: action.role, loading: false };
                case 'coordinateur':
                    return { ...state, coordinateur: action.json['hydra:member'], role: action.role, loading: false };
                case 'byId':
                    return { ...state, agences: action.json, role: action.role, loading: false };
                case 'selectedAffectationAgence':
                    return { ...state, commerciauxAgence: action.json['hydra:member'], role: action.role, loading: false };
                case 'all':
                    return { ...state, agences: action.json['hydra:member'], role: action.role, loading: false };
                default:
                    return { ...state, agences: action.json, role: action.role, loading: false };
            }

        case SELECTED_AGENCE:

            return { ...state,selectedAgence:action.payload, loading: true }
        case RECEIVED_SELECTED_AGENCE:

            return { ...state,selectedAgence:action.agence, loading: false }


        default:
            return state;

    }
};
export default Agence