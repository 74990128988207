import React, {Component} from 'react';
import ReactDOM from 'react-dom'
import MapGL, {NavigationControl, Marker,Popup,setRTLTextPlugin,FlyToInterpolator,AutoSizer } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'mapbox-gl/dist/mapbox-gl.js';
import Loader from "../components/Loader";
import {mapboxgl} from "mapbox-gl";

import { Icon } from 'semantic-ui-react';
//import 'semantic-ui-css/semantic.min.css';
import {connect} from "react-redux";
import * as FeatherIcon from 'react-feather';
import {getVilles, setVillePosition, getSegments,setMarkerPosition} from '../redux/crm/actions';
//import {setAgence} from "../redux/crm/agence/actions";
/*const mapboxgl =  require('mapbox-gl') ;*/

const TOKEN = 'pk.eyJ1IjoiYWJoaWxhc2hhLXNpbmhhIiwiYSI6ImNqdzFwYWN1ajBtOXM0OG1wbHAwdWJlNmwifQ.91s73Dy03voy-wPZEeuV5Q';
const navStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    padding: '10px'
};


class MapAgence extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewport: {
                latitude : undefined,
                longitude :undefined,
                population:this.props.population,
                zoom: 7,
                bearing: 0,
                pitch: 0,
                width: '100%',
                height: 450,

            },
            popupInfo: null,
            markerList:[{
                lat: 36.8667,
                long: 10.2,
                population: 0
            },],

        };
        /*this.handlePosition=this.handlePosition.bind(this)*/
    }
// showDetails=() => {
// this.setState({popupInfo: true});
// }

// hideDetails= ()=> {
// this.setState({popupInfo: null});
// }
    renderPopup(index){
        return this.state.popupInfo && (
            <Popup tipSize={5}
                   anchor="bottom-right"
                   longitude={this.state.markerList[index].long}
                   latitude={this.state.markerList[index].lat}
                   population={this.state.markerList[index].population}
                   onMouseLeave={() => this.setState({popupInfo: null})}
                   closeOnClick={true}>
                <p>Population:{this.state.markerList[index].population}</p>
            </Popup>
        )
    }

    onMarkerDragStart = (event) => {
        const {latitude, longitude} = event;

    }
    onMarkerDragEnd = (event) => {
        let lat= event.lngLat[1];
        let long= event.lngLat[0];


        this.props.setVillePosition(lat,long, this.state.markerList[0].population)
      //  this.props.setMarkerPosition(lat,long)
        // Any functionality for when a drag ends
        // this.setState({latitude, longitude});

        let tabMarker=this.state.markerList
        tabMarker[0].lat=lat
        tabMarker[0].long=long
        tabMarker[0].population=this.state.markerList[0].population
        this.setState({
            markerList: tabMarker                               // return new object jasper object
        })

        //console.log("drag start", event.lngLat)

    }
    onMarkerDrag = (event) => {
        const {latitude, longitude} = event;// console.log("drag start", event.lngLat)
        // Any functionality when marker moves while being dragged
    }
    componentDidMount() {



        this.setState(prevState => ({
            viewport: {                   // object that we want to update
                ...prevState.viewport,    // keep all other key-value pairs
                latitude : this.props.latitude===undefined?parseFloat(this.props.lat):this.props.latitude,
                longitude :this.props.longitude===undefined?parseFloat(this.props.lng):this.props.longitude,
                population:this.props.pop

                // update the value of specific key


            },
            markerList:[{
                lat: this.props.latitude===undefined?parseFloat(this.props.lat):this.props.latitude,
                long: this.props.longitude===undefined?parseFloat(this.props.lng):this.props.longitude,
                population:this.props.pop
            },],

        }),)











    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.lat!==this.props.lat||prevProps.lng!==this.props.lng){

          //  this.props.setVillePosition(this.props.lat,this.props.lng, this.state.markerList[0].population)
            this.setState(prevState => ({
                viewport: {                   // object that we want to update
                    ...prevState.viewport,    // keep all other key-value pairs
                    latitude : parseFloat(this.props.lat),
                    longitude :parseFloat(this.props.lng )  ,
                    //    population:this.props.population

                    // update the value of specific key
                },
                markerList:[{
                    lat: parseFloat(this.props.lat ),
                    long: parseFloat(this.props.lng ),
                    population:this.props.pop
                },],
            }))
        //    this.props.setVillePosition(this.state.markerList[0].lat,this.state.markerList[0].long, this.state.markerList[0].population)


        }

        if(this.props.lat===undefined)
        {
            this.props.setVillePosition(this.state.markerList[0].lat,this.state.markerList[0].long, this.state.markerList[0].population)
        }









    }





    render() {
        let {viewport,markerList} = this.state;





            return (
                this.state.markerList=== undefined || this.state.viewport=== undefined? <Loader></Loader>: this.state.markerList=== undefined || this.state.viewport=== undefined? <Loader></Loader>:

                <MapGL
                    /*onClick={(e)=>{this.handlePosition(this.props.long,this.props.lat)}}*/

                    {...viewport}
                    transitionDuration={"auto"}
                    transitionInterpolator={new FlyToInterpolator()}

                    onViewportChange={(viewport) => this.setState({viewport})}

                    mapStyle="mapbox://styles/mapbox/streets-v9"
                    mapboxApiAccessToken={TOKEN}

                >
                    <div className="nav" style={navStyle}>
                        <NavigationControl />
                        {markerList.map((marker, index)=>{
                                return(<div key={index} >
                                    <Marker
                                        draggable={true}
                                        onDragStart={this.onMarkerDragStart}
                                        onDragEnd={this.onMarkerDragEnd}
                                        onDrag={this.onMarkerDrag}

                                        longitude={this.state.markerList[index].long} latitude={this.state.markerList[index].lat}>
                                        <Icon  size='big' color='red' onMouseEnter={()=>this.setState({popupInfo: true})} onMouseLeave={()=>this.setState({popupInfo: null})}>
                                            {/*<img src=" https://img.icons8.com/color/48/000000/marker.png" />*/}
                                            <FeatherIcon.MapPin className="icon-lg icon-dual-danger" ></FeatherIcon.MapPin>
                                        </Icon>





                                    </Marker> {this.renderPopup(index)}</div> );

                            }
                        ) }
                    </div>
                </MapGL>
            )










    }
}

const mapDispatchToProps = {
    setVillePosition,setMarkerPosition
};

const mapStateToProps = (state) => ({
    //   villes: state.Crm.villes,
    lat: state.Crm.lat,
    lng: state.Crm.long,
    pop:state.Crm.population,
});

MapAgence = connect(mapStateToProps ,mapDispatchToProps,null)(MapAgence)

export default MapAgence;
