import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';
import * as FeatherIcon from 'react-feather';

import { isUserAuthenticated, getLoggedInUser } from '../helpers/authUtils';

// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const Register = React.lazy(() => import('../pages/auth/Register'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));
// dashboard
const Dashboard = React.lazy(() => import('../pages/dashboard'));
///statistiques
const Statisqtiques = React.lazy(() => import('../pages/statistiques'));
const StatAgences = React.lazy(() => import('../pages/statistiques/agences'));
const StatPerformance = React.lazy(() => import('../pages/statistiques/performance'));
//Gestion des commerciaux

const Agences = React.lazy(() => import('../pages/configuration/Agences'));
const EditionAgence = React.lazy(() => import('../pages/configuration/EditionAgence'));
const NouvelleAgence = React.lazy(() => import('../pages/configuration/NouvelleAgence'));
const Commerciaux = React.lazy(() => import('../pages/configuration/Commerciaux'));
const NouveauCommercial = React.lazy(() => import('../pages/configuration/NouveauCommercial'));
const EditionCommercial = React.lazy(() => import('../pages/configuration/EditionCommercial'));
const EditionProfil = React.lazy(() => import('../pages/configuration/EditionProfil'));




const NouveauSegment = React.lazy(() => import('../pages/configuration/NouveauSegment'));
const Segments = React.lazy(() => import('../pages/configuration/Segments'));

const Prospects = React.lazy(() => import('../pages/prospects/Prospects'));
const EditionProspect = React.lazy(() => import('../pages/prospects/EditionProspect'));


const Opportunite = React.lazy(() => import('../pages/prospects/Opportunite'));
const EditionOpportunite = React.lazy(() => import('../pages/opportunites/EditionOpportunite'));
const Taches = React.lazy(() => import('../pages/opportunites/Taches'));
const TachesAccomplies = React.lazy(() => import('../pages/opportunites/TachesAccomplies'));
const TachesEnCours = React.lazy(() => import('../pages/opportunites/TachesEnCours'));

const References = React.lazy(() => import('../pages/opportunites/References'));
const Opportunites = React.lazy(() => import('../pages/opportunites/Opportunites'));


const Calendrier = React.lazy(() => import('../pages/prospects/Calendrier'));
// actions
const Appel = React.lazy(() => import('../pages/actions/Appel'));

const Reference = React.lazy(() => import('../pages/actions/Reference'));
const SuiviCommerciaux = React.lazy(() => import('../pages/suivis/Commerciaux'));
const performanceCommercial = React.lazy(() => import('../pages/suivis/performance'));

// apps
const CalendarApp = React.lazy(() => import('../pages/apps/Calendar'));
const EmailInbox = React.lazy(() => import('../pages/apps/Email/Inbox'));
const EmailDetail = React.lazy(() => import('../pages/apps/Email/Detail'));
const EmailCompose = React.lazy(() => import('../pages/apps/Email/Compose'));
const ProjectList = React.lazy(() => import('../pages/apps/Project/List'));
const ProjectDetail = React.lazy(() => import('../pages/apps/Project/Detail/'));
const TaskList = React.lazy(() => import('../pages/apps/Tasks/List'));
const TaskBoard = React.lazy(() => import('../pages/apps/Tasks/Board'));

// pages
const Starter = React.lazy(() => import('../pages/other/Starter'));
const Profile = React.lazy(() => import('../pages/other/Profile/'));
const Activity = React.lazy(() => import('../pages/other/Activity'));
const Invoice = React.lazy(() => import('../pages/other/Invoice'));
const Pricing = React.lazy(() => import('../pages/other/Pricing'));
const Error404 = React.lazy(() => import('../pages/other/Error404'));
const Error500 = React.lazy(() => import('../pages/other/Error500'));

// ui
const BSComponents = React.lazy(() => import('../pages/uikit/BSComponents/'));
const FeatherIcons = React.lazy(() => import('../pages/uikit/Icons/Feather'));
const UniconsIcons = React.lazy(() => import('../pages/uikit/Icons/Unicons'));
const Widgets = React.lazy(() => import('../pages/uikit/Widgets/'));

// charts
const Charts = React.lazy(() => import('../pages/charts/'));




// forms
const BasicForms = React.lazy(() => import('../pages/forms/Basic'));
const FormAdvanced = React.lazy(() => import('../pages/forms/Advanced'));
const FormValidation = React.lazy(() => import('../pages/forms/Validation'));
const FormWizard = React.lazy(() => import('../pages/forms/Wizard'));
const FileUpload = React.lazy(() => import('../pages/forms/FileUpload'));
const Editor = React.lazy(() => import('../pages/forms/Editor'));

// tables
const BasicTables = React.lazy(() => import('../pages/tables/Basic'));
const AdvancedTables = React.lazy(() => import('../pages/tables/Advanced'));


// handle auth and authorization
const PrivateRoute = ({ component: Component, roles, ...rest }) => (
    <Route
        {...rest}


        render={props => {
            if (!isUserAuthenticated()) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />;
            }

            const loggedInUser = getLoggedInUser();
            // check if route is restricted by role
            if (roles && roles.indexOf(loggedInUser.role) === -1) {
                // role not authorised so redirect to home page
                return <Redirect to={{ pathname: '/' }} />;
            }
            if(getLoggedInUser().role==="ROLE_SUPERADMIN"){
                return <Component {...props} />;
            }

            // authorised so return component
           else return <Component {...props} />;
        }}
    />
);

// root routes
const rootRoute = {
    path: '/',
    exact: true,
    component: () => getLoggedInUser().role==="ROLE_SUPERADMIN"?<Redirect to="/statistiques/Dashboard" />:<Redirect to="/dashboard" />,
    route: PrivateRoute,
};



const actionsRoutes = {
    path: '/actions',
    name: 'actions',
    icon: FeatherIcon.Inbox,
    children: [
        {
            path: '/actions/appel/:oppId/:tacheId',
            name: 'Appel est evaluation',
            component: Appel,
            route: PrivateRoute,
            roles: ['ROLE_SUPERADMIN','ROLE_ADMIN','ROLE_COMMERCIAL'],
        },
        {
            path: '/actions/reference/:oppId/:tacheId',
            name: 'Récuperation de la Réference STEG',
            component: Reference,
            route: PrivateRoute,
            roles: ['ROLE_SUPERADMIN','ROLE_ADMIN'],
        },
        {
            path: '/opportunites/:oppId',
            name: 'Opportunite',
            component: EditionOpportunite,
            route: PrivateRoute,
            roles: ['ROLE_SUPERADMIN','ROLE_ADMIN','ROLE_COMMERCIAL'],
        },

    ]
};

const commercialRoutes = {
    path: '/Commerciaux',
    name: 'Commerciaux',
    icon: FeatherIcon.Inbox,
    children: [
        {
           // path: '/configuration/Commerciaux/Edition/:userId',
            path: '/Commerciaux/Edition/:userId',
            name: 'Edition du profil',
            component: EditionCommercial,
            route: PrivateRoute,
            roles: ['ROLE_SUPERADMIN','ROLE_ADMIN' ],
        },
        {
            // path: '/configuration/Commerciaux/Edition/:userId',
            path: '/configuration/NouveauCommercial',
            name: 'Nouveau Commercial',
            component: NouveauCommercial,
            route: PrivateRoute,
            roles: ['ROLE_SUPERADMIN','ROLE_ADMIN' ],
        },

    ]
};
const agenceRoutes = {
    path: '/Agences',
    name: 'Agences',
    icon: FeatherIcon.Inbox,
    children: [
        {
            // path: '/configuration/Commerciaux/Edition/:userId',
            path: '/Agences/Edition/:agenceId',
            name: "Edition de L'agence",
            component: EditionAgence,
            route: PrivateRoute,
            roles: ['ROLE_SUPERADMIN','ROLE_ADMIN'],
        },
        {
            // path: '/configuration/Commerciaux/Edition/:userId',
            path: '/configuration/NouvelleAgence',
            name: 'Nouvelle Agence',
            component: NouvelleAgence,
            route: PrivateRoute,
            roles: ['ROLE_SUPERADMIN','ROLE_ADMIN' ],
        },

    ]
};

const statistiquesRoutes = {
    path: '/statistiques',
    name: 'Statistiques',
    icon: FeatherIcon.PieChart,

    children: [
        {
            path: '/statistiques/Dashboard',
            name: 'Dashboard GPC',
            component: Statisqtiques,
            route: PrivateRoute,
            roles: ['ROLE_SUPERADMIN','ROLE_ADMIN'],
        },
        {
            path: '/statistiques/Agences',
            name: 'Dashboard Agences',
            component: StatAgences,
            route: PrivateRoute,
            roles: ['ROLE_SUPERADMIN','ROLE_ADMIN'],
        },
        {
            path: '/statistiques/Performance',
            name: 'Performance',
            component: StatPerformance,
            route: PrivateRoute,
            roles: ['ROLE_SUPERADMIN','ROLE_ADMIN'],
        },
    ],
    roles: ['ROLE_SUPERADMIN','ROLE_ADMIN'],
    route: PrivateRoute
};
const suivisRoutes = {
    path: '/suivis',
    name: 'Suivis',
    icon: FeatherIcon.Watch,

    children: [
        {
            path: '/suivis/commerciaux',
            name: 'Suivi Commerciaux',
            component: SuiviCommerciaux,
            route: PrivateRoute,
            roles: ['ROLE_SUPERADMIN'],
        },

    ],
    roles: ['ROLE_SUPERADMIN'],
    route: PrivateRoute
};



// Configuration du crm
const configurationRoutes = {
    path: '/configuration',
    name: 'Configuration',
    icon: FeatherIcon.Inbox,

    children: [

        {
            path: '/configuration/Segments',
            name: 'Segmentation',
            component: Segments,
            route: PrivateRoute,
            roles: ['ROLE_SUPERADMIN','ROLE_ADMIN'],
        },
        {
            path: '/configuration/Agences',
            name: 'Agences',
            component: Agences,
            route: PrivateRoute,
            roles: ['ROLE_SUPERADMIN','ROLE_ADMIN','ROLE_COMMERCIAL'],
        },

        {
            path: '/configuration/Commerciaux',
            name: 'Commerciaux',
            component: Commerciaux,
            route: PrivateRoute,
            roles: ['ROLE_SUPERADMIN','ROLE_ADMIN'],
        },



    ],
    roles: ['ROLE_SUPERADMIN','ROLE_ADMIN','ROLE_COMMERCIAL'],
    route: PrivateRoute
};
const prospectRoutes = {
    path: '/prospects',
    name: 'Prospects',
    icon: FeatherIcon.Users,
    children: [
        {
            path: '/prospects/prospects',
            name: 'Tous les prospects',
            component: Prospects,
            route: PrivateRoute,
            roles: ['ROLE_SUPERADMIN','ROLE_ADMIN','ROLE_COMMERCIAL','ROLE_MARKETEUR' ],
        },




    ],
    roles: ['ROLE_SUPERADMIN','ROLE_ADMIN','ROLE_MARKETEUR','ROLE_COMMERCIAL'],
};
const opportunitesRoutes = {
    path: '/opportunites',
    name: 'Opportunités',
    icon: FeatherIcon.Briefcase,

    children: [


        {
            path: '/opportunites/opportunites',
            name: 'Consulter',
            component: Opportunites,
            route: PrivateRoute,
            roles: ['ROLE_SUPERADMIN','ROLE_ADMIN','ROLE_COMMERCIAL','ROLE_MARKETEUR' ],
        },
      /*  {
            path: '/prospects/Calendrier',
            name: 'Calendrier',
            component: Calendrier,
            route: PrivateRoute,
            roles: ['ROLE_SUPERADMIN','ROLE_ADMIN' ],
        },*/



    ],
    roles: ['ROLE_SUPERADMIN','ROLE_ADMIN','ROLE_COMMERCIAL','ROLE_MARKETEUR'],
};

let travauxRoutes={}
travauxRoutes = {
    path: '/travaux',
    name: 'Travaux',
    icon: FeatherIcon.CheckCircle,

    children: [

        {
            path: '/travaux/taches-en-cours',
            name: 'Tâches en cours',
            component: TachesEnCours,
            route: PrivateRoute,
            roles: ['ROLE_SUPERADMIN','ROLE_ADMIN','ROLE_COMMERCIAL','ROLE_MARKETEUR' ],
        },
        /*{
            path: '/travaux/references',
            name: 'Références STEG',
            component: References,
            route: PrivateRoute,
            roles: ['ROLE_SUPERADMIN','ROLE_ADMIN','ROLE_MARKETEUR'],
        },
*/




    ],
    roles: ['ROLE_SUPERADMIN','ROLE_ADMIN','ROLE_COMMERCIAL','ROLE_MARKETEUR'],
};
if(getLoggedInUser()!==null&&(getLoggedInUser().role===("ROLE_ADMIN" || "ROLE_MARKETEUR")||getLoggedInUser().agenceId===(getLoggedInUser().chef||getLoggedInUser().coordinateur)))
{
    travauxRoutes = {
        path: '/travaux',
        name: 'Travaux',
        icon: FeatherIcon.CheckCircle,

        children: [

            {
                path: '/travaux/taches-en-cours',
                name: 'Tâches en cours',
                component: TachesEnCours,
                route: PrivateRoute,
                roles: ['ROLE_SUPERADMIN','ROLE_ADMIN','ROLE_COMMERCIAL','ROLE_MARKETEUR' ],
            },


            {
                path: '/travaux/references',
                name: 'Références STEG',
                component: References,
                route: PrivateRoute,
                roles: ['ROLE_SUPERADMIN','ROLE_ADMIN','ROLE_MARKETEUR','ROLE_COMMERCIAL' ],
            },

            /*  {
                  path: '/prospects/Calendrier',
                  name: 'Calendrier',
                  component: Calendrier,
                  route: PrivateRoute,
                  roles: ['ROLE_SUPERADMIN','ROLE_ADMIN' ],
              },*/



        ],
        roles: ['ROLE_SUPERADMIN','ROLE_ADMIN','ROLE_COMMERCIAL','ROLE_MARKETEUR'],
    };
}




/*const timelineRoutes = {
    path: '/prospects',
    name: 'TimeLine',
    icon: FeatherIcon.Inbox,
    children: [
        {
            path: '/prospects/TimeLine',
            name: 'Fil des travaux',
            component: TimeLine,
            route: PrivateRoute,
            roles: ['ROLE_DEV' ],
        },

    ]
};*/


// dashboards
const dashboardRoutes = {
    path: '/dashboard',
    name: 'Dashboard',
    icon: FeatherIcon.Home,
    header: 'Navigation',
    badge: {
        variant: 'success',
        text: '1',
    },
    component: Dashboard,
roles: ['ROLE_SUPERADMIN','ROLE_ADMIN','ROLE_COMMERCIAL','ROLE_MARKETEUR'],
    route: PrivateRoute
};

// apps

const calendarAppRoutes = {
    path: '/apps/calendar',
    name: 'Calendar',
    header: 'Apps',
    icon: FeatherIcon.Calendar,
    component: CalendarApp,
    route: PrivateRoute,
    roles: ['ROLE_DEV'],
};
const profilRoutes = {
    // path: '/configuration/Commerciaux/Edition/:userId',
    path: '/account/Profil/Edition/:userId',
    name: 'Edition du profil',
    component: EditionProfil,
    route: PrivateRoute,
    roles: ['ROLE_SUPERADMIN','ROLE_ADMIN','ROLE_COMMERCIAL' ],
};
const editionProspectRoutes = {
    // path: '/configuration/Commerciaux/Edition/:userId',
    path: '/prospects/edition/:prospectId',
    name: 'Edition du prospect',
    component: EditionProspect,
    route: PrivateRoute,
    roles: ['ROLE_SUPERADMIN','ROLE_ADMIN','ROLE_COMMERCIAL' ],
};

const performanceCommercialRoutes = {
    // path: '/configuration/Commerciaux/Edition/:userId',
    path: '/suivis/performance/:userId',
    name: 'Performance',
    component: performanceCommercial,
    route: PrivateRoute,
    roles: ['ROLE_SUPERADMIN','ROLE_ADMIN','ROLE_MARKETEUR'],
};



const emailAppRoutes = {
    path: '/apps/email',
    name: 'Email',
    icon: FeatherIcon.Inbox,
    children: [
        {
            path: '/apps/email/inbox',
            name: 'Inbox',
            component: EmailInbox,
            route: PrivateRoute,
            roles: ['ROLE_DEV'],
        },
        {
            path: '/apps/email/details',
            name: 'Details',
            component: EmailDetail,
            route: PrivateRoute,
            roles: ['ROLE_DEV'],
        },
        {
            path: '/apps/email/compose',
            name: 'Compose',
            component: EmailCompose,
            route: PrivateRoute,
            roles: ['ROLE_DEV'],
        },
    ],
    roles: ['ROLE_DEV'],
};

const projectAppRoutes = {
    path: '/apps/projects',
    name: 'Projects',
    icon: FeatherIcon.Briefcase,
    children: [
        {
            path: '/apps/projects/list',
            name: 'List',
            component: ProjectList,
            route: PrivateRoute,
            roles: ['ROLE_DEV'],
        },
        {
            path: '/apps/projects/detail',
            name: 'Detail',
            component: ProjectDetail,
            route: PrivateRoute,
            roles: ['ROLE_DEV'],
        },
    ],
    roles: ['ROLE_DEV'],
};

const taskAppRoutes = {
    path: '/apps/tasks',
    name: 'Tasks',
    icon: FeatherIcon.Bookmark,
    children: [
        {
            path: '/apps/tasks/list',
            name: 'List',
            component: TaskList,
            route: PrivateRoute,
            roles: ['ROLE_DEV'],
        },
        {
            path: '/apps/tasks/board',
            name: 'Board',
            component: TaskBoard,
            route: PrivateRoute,
            roles: ['ROLE_DEV'],
        },
    ],
    roles: ['ROLE_DEV'],
};

const appRoutes = [calendarAppRoutes, emailAppRoutes, projectAppRoutes, taskAppRoutes];



// pages
const pagesRoutes = {
    path: '/pages',
    name: 'Pages',
    header: 'Custom',
    icon: FeatherIcon.FileText,
    children: [
        {
            path: '/pages/starter',
            name: 'Starter',
            component: Starter,
            route: PrivateRoute,
            roles: ['ROLE_DEV'],
        },
        {
            path: '/pages/profile',
            name: 'Profile',
            component: Profile,
            route: PrivateRoute,
            roles: ['ROLE_DEV'],
        },
        {
            path: '/pages/activity',
            name: 'Activity',
            component: Activity,
            route: PrivateRoute,
            roles: ['ROLE_DEV'],
        },
        {
            path: '/pages/invoice',
            name: 'Invoice',
            component: Invoice,
            route: PrivateRoute,
            roles: ['ROLE_DEV'],
        },
        {
            path: '/pages/pricing',
            name: 'Pricing',
            component: Pricing,
            route: PrivateRoute,
            roles: ['ROLE_DEV'],
        },
        {
            path: '/pages/error-404',
            name: 'Error 404',
            component: Error404,
            route: Route
        },
        {
            path: '/pages/error-500',
            name: 'Error 500',
            component: Error500,
            route: Route
        },
    ],
    roles: ['ROLE_DEV'],
};


// components
const componentsRoutes = {
    path: '/ui',
    name: 'UI Elements',
    header: 'Components',
    icon: FeatherIcon.Package,
    children: [
        {
            path: '/ui/bscomponents',
            name: 'Bootstrap UI',
            component: BSComponents,
            route: PrivateRoute,
            roles: ['ROLE_DEV'],
        },
        {
            path: '/ui/icons',
            name: 'Icons',
            children: [
                {
                    path: '/ui/icons/feather',
                    name: 'Feather Icons',
                    component: FeatherIcons,
                    route: PrivateRoute,
                    roles: ['ROLE_DEV'],
                },
                {
                    path: '/ui/icons/unicons',
                    name: 'Unicons Icons',
                    component: UniconsIcons,
                    route: PrivateRoute,
                    roles: ['ROLE_DEV'],
                },
            ]
        },
        {
            path: '/ui/widgets',
            name: 'Widgets',
            component: Widgets,
            route: PrivateRoute,
            roles: ['ROLE_DEV'],
        },

    ],
    roles: ['ROLE_DEV'],
};

// charts
const chartRoutes = {
    path: '/charts',
    name: 'Charts',
    component: Charts,
    icon: FeatherIcon.PieChart,
    roles: ['ROLE_DEV'],
    route: PrivateRoute
}


// forms
const formsRoutes = {
    path: '/forms',
    name: 'Forms',
    icon: FeatherIcon.FileText,
    children: [
        {
            path: '/forms/basic',
            name: 'Basic Elements',
            component: BasicForms,
            route: PrivateRoute,
            roles: ['ROLE_DEV'],
        },
        {
            path: '/forms/advanced',
            name: 'Advanced',
            component: FormAdvanced,
            route: PrivateRoute,
            roles: ['ROLE_DEV'],
        },
        {
            path: '/forms/validation',
            name: 'Validation',
            component: FormValidation,
            route: PrivateRoute,
            roles: ['ROLE_DEV'],
        },
        {
            path: '/forms/wizard',
            name: 'Wizard',
            component: FormWizard,
            route: PrivateRoute,
            roles: ['ROLE_DEV'],
        },
        {
            path: '/forms/editor',
            name: 'Editor',
            component: Editor,
            route: PrivateRoute,
            roles: ['ROLE_DEV'],
        },
        {
            path: '/forms/upload',
            name: 'File Upload',
            component: FileUpload,
            route: PrivateRoute,
            roles: ['ROLE_DEV'],
        }
    ],roles: ['ROLE_DEV'],
};


const tableRoutes = {
    path: '/tables',
    name: 'Tables',
    icon: FeatherIcon.Grid,
    roles: ['ROLE_DEV'],
    children: [
        {
            path: '/tables/basic',
            name: 'Basic',
            component: BasicTables,
            route: PrivateRoute,
            roles: ['ROLE_DEV'],
        },
        {
            path: '/tables/advanced',
            name: 'Advanced',
            component: AdvancedTables,
            route: PrivateRoute,
            roles: ['ROLE_DEV'],
        }],

};


// auth
const authRoutes = {
    path: '/account',
    name: 'Auth',
    children: [

        {
            path: '/account/login',
            name: 'Login',
            component: Login,
            route: Route,
        },
        {
            path: '/account/logout',
            name: 'Logout',
            component: Logout,
            route: Route,
        },
        {
            path: '/account/register',
            name: 'Register',
            component: Register,
            route: Route,
        },
        {
            path: '/account/confirm',
            name: 'Confirm',
            component: Confirm,
            route: Route,
        },
        {
            path: '/account/forget-password',
            name: 'Forget Password',
            component: ForgetPassword,
            route: Route,
        },
    ],
};

// flatten the list of all nested routes
const flattenRoutes = routes => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach(item => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes
const allRoutes = [
    rootRoute,
    prospectRoutes,
    opportunitesRoutes,
    travauxRoutes,
   // commercialRoutes,
   configurationRoutes,
    statistiquesRoutes,
    suivisRoutes,
   // agenceRoutes,
    dashboardRoutes,
    ...appRoutes,
    pagesRoutes,
    componentsRoutes,
    chartRoutes,
    formsRoutes,
    tableRoutes,
    authRoutes,
    actionsRoutes,
    commercialRoutes,
    agenceRoutes,
    profilRoutes,
    editionProspectRoutes,
    performanceCommercialRoutes
];

const authProtectedRoutes = [dashboardRoutes, statistiquesRoutes,suivisRoutes,configurationRoutes,prospectRoutes,opportunitesRoutes,travauxRoutes  ,...appRoutes, pagesRoutes, componentsRoutes, chartRoutes, formsRoutes, tableRoutes];
const allFlattenRoutes = flattenRoutes(allRoutes);
export { allRoutes, authProtectedRoutes, allFlattenRoutes };
