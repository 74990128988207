import {
    SET_OPPORTUNITE,
    OPPORTUNITE_ADDED,
    OPPORTUNITE_REQUEST_FAILED,
    OPPORTUNITES_RECEIVED,
    GET_OPPORTUNITES,
    PATCH_OPPORTUNITE,
    OPPORTUNITE_PATCHED,
    DELETE_OPPORTUNITE, OPPORTUNITE_DELETED,
    AFFECT_OPPORTUNITE,OPPORTUNITE_AFFECTED,GET_TODAY_OPPORTUNITES,TODAY_OPPORTUNITES_RECEIVED,GET_INCOMING_OPPORTUNITES,INCOMING_OPPORTUNITES_RECEIVED,GET_OTHERS_OPPORTUNITES,OTHERS_OPPORTUNITES_RECEIVED
    , OPPORTUNITE_RECEIVED,GET_OPPORTUNITE,INSTALLATION_CREATED,SET_INSTALLATION,
    GET_INSTALLATIONS,
    INSTALLATIONS_RECEIVED,
    GET_INSTALLATIONS_OPPORTUNITE,
    INSTALLATIONS_OPPORTUNITE_RECEIVED,
    DELETE_INSTALLATION,
    INSTALLATION_DELETED,
    UPDATE_INSTALLATION,
    INSTALLATION_UPDATED,
    GET_OPPORTUNITE_BY_COMMERCIAL,
    OPPORTUNITE_BY_COMMERCIAL_RECIEVED,
    GET_OPPORTUNITE_BY_COMMERCIAL_BY_PERIOD,
    OPPORTUNITE_BY_COMMERCIAL_BY_PERIOD_RECIEVED,
    GET_DASHBOARD_BY_COMMERCIAL,DASHBOARD_BY_COMMERCIAL_RECIEVED,
    GET_DASHBOARD_BY_ADMIN,DASHBOARD_BY_ADMIN_RECIEVED,
    GET_AGENCES_STATS,AGENCES_STATS_RECIEVED,GET_PERFORMANCE,PERFORMANCE_RECIEVED

} from './constants';
import {GET_TACHES_BY_AGENCE, TACHES_BY_AGENCE_RECEIVED} from "../tache/constants";

export const setOpportunite = (data,history) => ({
    type: SET_OPPORTUNITE,
    payload: {data,history},
});

export const opportuniteAdded = (data, history) => ({
    type: OPPORTUNITE_REQUEST_FAILED,
    payload: { data, history},
});
export const updateOpportunite = (data) => ({
    type: PATCH_OPPORTUNITE,
    payload: {data},
});

export const opportunitePatched = (data) => ({
    type: OPPORTUNITE_PATCHED,
    payload: { data},
});

export const affectOpportunite = (opportunites,idCommercial) => ({
    type: AFFECT_OPPORTUNITE,
    payload: {opportunites,idCommercial},
});

export const opportuniteAffected = (opportunites,idCommercial) => ({
    type: OPPORTUNITE_AFFECTED,
    payload: { opportunites,idCommercial},
});





export const deleteOpportunite = (history,id) => ({
    type: DELETE_OPPORTUNITE,
    payload: {history,id},
});

export const opportuniteDeleted = (history,id) => ({
    type: OPPORTUNITE_PATCHED,
    payload: { history,id},
});
export const getOpportunites = (filter) => ({
    type: GET_OPPORTUNITES,
    payload: {filter}
});

export const opportunitesReceived = () => ({
    type: OPPORTUNITES_RECEIVED,

});


export const getTodayOpportunites = () => ({
    type: GET_TODAY_OPPORTUNITES,

});

export const todayOpportunitesReceived = () => ({
    type: TODAY_OPPORTUNITES_RECEIVED,

});


export const getIncomingOpportunites = () => ({
    type: GET_INCOMING_OPPORTUNITES,

});

export const incomingOpportunitesReceived = () => ({
    type: INCOMING_OPPORTUNITES_RECEIVED,

});


export const getOthersOpportunites = () => ({
    type: GET_OTHERS_OPPORTUNITES,

});

export const othersOpportunitesReceived = () => ({
    type: OTHERS_OPPORTUNITES_RECEIVED,
});

export const getOpportunite = (oppId) => ({
    type: GET_OPPORTUNITE,
    payload: {oppId}
});

export const opportuniteReceived = () => ({
    type: OPPORTUNITE_RECEIVED,

});


export const setInstallation = (data) => ({
    type: SET_INSTALLATION,
    payload: {data},
});

export const installationCreated = (data) => ({
    type: INSTALLATION_CREATED,
    payload: {data},

});



export const getInstallationsOpportunite = (data) => ({
    type: GET_INSTALLATIONS_OPPORTUNITE,
    payload: {data},

});

export const installationRecieved= () => ({
    type: INSTALLATIONS_OPPORTUNITE_RECEIVED,

});


export const deleteInstallation = (id,oppId) => ({
    type:DELETE_INSTALLATION,
    payload: {id,oppId},
});

export const installationDeleted = (id,oppId) => ({
    type: INSTALLATION_DELETED,
    //payload: { id,oppId},
});


export const updateInstallation = (data) => ({
    type: UPDATE_INSTALLATION,
    payload: {data},
});

export const installationUpdated = (data) => ({
    type: INSTALLATION_UPDATED,
    payload: {data},

});

export const getOpportuniteByCommercial = (commercial,etat) => ({
    type: GET_OPPORTUNITE_BY_COMMERCIAL,
    payload: {commercial,etat},
});

export const OpportuniteByCommercialReceived = () => ({
    type: OPPORTUNITE_BY_COMMERCIAL_RECIEVED,
});


export const getOpportuniteByCommercialByPeriod = (commercial,etat,startDate,endDate) => ({
    type: GET_OPPORTUNITE_BY_COMMERCIAL_BY_PERIOD,
    payload: {commercial,etat,startDate,endDate},
});

export const OpportuniteByCommercialByPeriodReceived = () => ({
    type: OPPORTUNITE_BY_COMMERCIAL_BY_PERIOD_RECIEVED,
});


export const getDashboardByCommercial= (commercial,etat,startDate,endDate) => ({
    type: GET_DASHBOARD_BY_COMMERCIAL,
    payload: {commercial,etat,startDate,endDate},
});

export const DashboardByCommercialReceived = () => ({
    type: DASHBOARD_BY_COMMERCIAL_RECIEVED,
});


export const getDashboardByAdmin= (startDate,endDate) => ({
    type: GET_DASHBOARD_BY_ADMIN,
    payload: {startDate,endDate},
});

export const DashboardByAdminReceived = () => ({
    type: DASHBOARD_BY_ADMIN_RECIEVED,
});

export const getAgencesStats= (startDate,endDate) => ({
    type: GET_AGENCES_STATS,
    payload: {startDate,endDate},
});

export const AgencesStatsReceived = () => ({
    type: AGENCES_STATS_RECIEVED,
});

export const getPerformance= (startDate,endDate) => ({
    type: GET_PERFORMANCE,
    payload: {startDate,endDate},
});

export const performanceReceived = () => ({
    type: PERFORMANCE_RECIEVED,
});