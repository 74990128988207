// @flow
import { all } from 'redux-saga/effects';
import authSaga from './auth/saga';
import layoutSaga from './layout/saga';
import appMenuSaga from './appMenu/saga';
import crmSaga from './crm/saga';
import agenceSaga from './crm/agence/saga';
import prospectSaga from './crm/prospect/saga';
import tacheSaga from './crm/tache/saga';
import opportuniteSaga from './crm/opportunite/saga';

export default function* rootSaga(getState: any): any {
    yield all([authSaga(), layoutSaga(), appMenuSaga(),crmSaga(), agenceSaga(), prospectSaga(),tacheSaga(),opportuniteSaga()]);
}
