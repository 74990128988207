
export const SET_OPPORTUNITE = 'SET_OPPORTUNITE';
export const OPPORTUNITE_ADDED = 'OPPORTUNITE_ADDED';
export const OPPORTUNITE_REQUEST_FAILED = 'OPPORTUNITE_REQUEST_FAILED';

export const GET_OPPORTUNITES = 'GET_OPPORTUNITES';
export const OPPORTUNITES_RECEIVED = 'OPPORTUNITES_RECEIVED';


export const GET_TODAY_OPPORTUNITES = 'GET_TODAY_OPPORTUNITES';
export const TODAY_OPPORTUNITES_RECEIVED = 'TODAY_OPPORTUNITES_RECEIVED';

export const GET_INCOMING_OPPORTUNITES = 'GET_INCOMING_OPPORTUNITES';
export const INCOMING_OPPORTUNITES_RECEIVED = 'INCOMING_OPPORTUNITES_RECEIVED';

export const GET_OTHERS_OPPORTUNITES = 'GET_OTHERS_OPPORTUNITES ';
export const OTHERS_OPPORTUNITES_RECEIVED = 'OTHERS_OPPORTUNITES_RECEIVED';



export const PATCH_OPPORTUNITE = 'PATCH_OPPORTUNITE';
export const OPPORTUNITE_PATCHED = 'OPPORTUNITE_PATCHED';

export const AFFECT_OPPORTUNITE = 'AFFECT_OPPORTUNITE';
export const OPPORTUNITE_AFFECTED = 'OPPORTUNITE_AFFECTED';

export const DELETE_OPPORTUNITE = 'DELETE_OPPORTUNITE';
export const OPPORTUNITE_DELETED = 'OPPORTUNITE_DELETED';

export const GET_OPPORTUNITE = 'GET_OPPORTUNITE';
export const OPPORTUNITE_RECEIVED = 'OPPORTUNITE_RECEIVED';


export const SET_INSTALLATION = 'SET_INSTALLATION';
export const INSTALLATION_CREATED = 'INSTALLATION_CREATED';

export const GET_INSTALLATIONS = 'GET_INSTALLATIONS';
export const INSTALLATIONS_RECEIVED = 'INSTALLATIONS_RECEIVED';

export const GET_INSTALLATIONS_OPPORTUNITE = 'GET_INSTALLATIONS_OPPORTUNITE';
export const INSTALLATIONS_OPPORTUNITE_RECEIVED = 'INSTALLATIONS_OPPORTUNITE_RECEIVED';

export const DELETE_INSTALLATION = 'DELETE_INSTALLATION';
export const INSTALLATION_DELETED = 'INSTALLATION_DELETED';

export const UPDATE_INSTALLATION = 'UPDATE_INSTALLATION ';
export const INSTALLATION_UPDATED = 'INSTALLATION_UPDATED ';

export const GET_OPPORTUNITE_BY_COMMERCIAL = 'GET_OPPORTUNITE_BY_COMMERCIAL';
export const OPPORTUNITE_BY_COMMERCIAL_RECIEVED = 'OPPORTUNITE_BY_COMMERCIAL_RECIEVED';

export const GET_OPPORTUNITE_BY_COMMERCIAL_BY_PERIOD = 'GET_OPPORTUNITE_BY_COMMERCIAL_BY_PERIOD';
export const OPPORTUNITE_BY_COMMERCIAL_BY_PERIOD_RECIEVED = 'OPPORTUNITE_BY_COMMERCIAL_BY_PERIOD_RECIEVED';


export const GET_DASHBOARD_BY_COMMERCIAL = 'GET_DASHBOARD_BY_COMMERCIAL';
export const DASHBOARD_BY_COMMERCIAL_RECIEVED = 'DASHBOARD_BY_COMMERCIAL_RECIEVED';


export const GET_DASHBOARD_BY_ADMIN = 'GET_DASHBOARD_BY_ADMIN';
export const DASHBOARD_BY_ADMIN_RECIEVED = 'DASHBOARD_BY_ADMIN_RECIEVED';

export const GET_AGENCES_STATS = 'GET_AGENCES_STATS';
export const AGENCES_STATS_RECIEVED = 'AGENCES_STATS_RECIEVED';


export const GET_PERFORMANCE = 'GET_PERFORMANCE';
export const PERFORMANCE_RECIEVED = 'PERFORMANCE_RECIEVED';
