//import firebase from "firebase/app";
import firebase from 'firebase/compat/app';
import "firebase/compat/messaging";

import {getTaches} from "./redux/crm/tache/actions";
import {getUsers} from "./redux/crm/actions";
import Dashboard from "./pages/dashboard";
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  measurementId: "FROM FIREBASE CONSOLE",
  apiKey: "AIzaSyASZW-YyMr9TRJTOF31ajq0UsBiIjlfHiU",
  authDomain: "gpccrm.firebaseapp.com",
  projectId: "gpccrm",
  storageBucket: "gpccrm.appspot.com",
  messagingSenderId: "877944800775",
  appId: "1:877944800775:web:04aeec8159846d65f60ca2"
};

firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging();

const { REACT_APP_VAPID_KEY } = process.env;

const publicKey = REACT_APP_VAPID_KEY;

export const getToken = async (setTokenFound) => {
  let currentToken = "";

  try {
    currentToken = await messaging.getToken({ vapidKey: publicKey });
    if (currentToken) {
      setTokenFound(true);


    } else {
      setTokenFound(false);
    }
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
  }

  return currentToken;
};





export const onMessageListener = () =>
  new Promise((resolve) => {

    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });

const subscribeToken = async (setTokenFound) => {
  let currentToken = "";

  try {
    currentToken = await messaging.getToken({ vapidKey: publicKey });
    if (currentToken) {
      setTokenFound(true);

    } else {
      setTokenFound(false);
    }
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
  }

  return currentToken;
};


