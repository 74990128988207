import React, { Component, Suspense } from 'react';
import {
    Row,
    Col,
    UncontrolledButtonDropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    Button,
    Collapse,
    CardBody,
    FormGroup,
    Label,
    CustomInput,
    Card, UncontrolledTooltip, Badge,
} from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import {
    ChevronDown,
    Mail,
    Printer,
    File,
    Users,
    Image,
    ShoppingBag,
    Briefcase,
    CheckCircle,
    XCircle,
    default as FeatherIcon,

} from 'react-feather';

import Loader from '../../components/Loader';
import OverviewWidget from '../../components/OverviewWidget';
import Statistics from './Statistics';
import RevenueChart from './RevenueChart';
//import TargetChart from './TargetChart';
import SalesChart from './SalesChart';
import Orders from './Orders';
import Performers from './Performers';
import Tasks from './Tasks';
import Chat from './Chat';
import StatisticsChartWidget from '../../components/StatisticsChartWidget';
import moment from 'moment';
import 'moment-duration-format';

import 'moment/min/moment-with-locales';

import 'moment/locale/fr';
import { getLoggedInUser } from '../../helpers/authUtils';
import { updateTache,getTachesByExecuteur } from '../../redux/crm/tache/actions';
import { getCanaux, getSegments, getSelectedCanal, getSelectedVille, getVilles,getUsers } from '../../redux/crm/actions';
import { affectProspect, getProspects, setProspect } from '../../redux/crm/prospect/actions';
import {
    getIncomingOpportunites,
    getOpportunites,
    getOpportuniteByCommercialByPeriod,
    getDashboardByCommercial,
    getOthersOpportunites,
    getTodayOpportunites,
} from '../../redux/crm/opportunite/actions';
import {
    getCrmData
} from '../../redux/crm/actions';
import { connect } from 'react-redux';
import TachesEnCours from '../opportunites/TachesEnCours';
import * as _ from 'lodash';
import avatar from '../../assets/images/users/user.png';
import { SERVERUIMAGESUSERS } from '../../helpers/api';
import StatisticsProgressWidget from "../../components/StatisticsProgressWidget";
import StatisticsChartWidget2 from "../../components/StatisticsChartWidget2";
import StatisticsWidget from "../../components/StatisticsWidget";
import Chart from "react-apexcharts";
import Task from "../apps/Tasks/Task";
import {Link} from "react-router-dom";
const French = require('flatpickr/dist/l10n/fr.js').default.fr;
function Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

function dateJour(date) {
    return moment(date).format('YYYY-MM-DD');
}
function getNewAverage(current, last) {
    let average=0
    if (last <= current) {
        if (
            last !== null &&
            last != undefined &&
            last !== 0 &&
            current !== null
        ) {
            average = (((current - last)/last) * 100).toFixed(0);
        } else {
            average= null;
        }
    } else {
        if (
            current !== null &&
            current != undefined &&
            last !== 0 &&
            current !== null
        ) {
            average = (((last - current)/current) * 100).toFixed(0);
        } else {
            average = null;
        }
    }
    return average
}
function filterDate() {
    let startDate = Date.parse(this.state.filterDate[0]);
    let endDate = Date.parse(this.state.filterDate[1]);
    var admission = moment(startDate).format('YYYY-MM-DD');
    var discharge = moment(endDate).format('YYYY-MM-DD');

    this.setState({
        startDate:admission,
        endDate:discharge,
    })
}
const DonutChart = (props) => {
    //console.log("labels",labels[0])
    const apexDonutOpts = {
        chart: {
            height: 320,
            type: 'pie',
        },
        labels: props.labels,
        colors: props.colors,
        tooltip: {
            theme: 'dark',
            x: { show: false }
        },
        legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'center',
            verticalAlign: 'middle',
            floating: false,
            fontSize: '16px',
            offsetX: 0,
            offsetY: -10,
        },
        responsive: [
            {
                breakpoint: 600,
                options: {
                    chart: {
                        height: 240,
                    },
                    legend: {
                        show: false,
                    },
                },
            },
        ],
    };

    const apexDonutData = [80, 55, 41, 17, 15];

    return (
        <Card>
            <CardBody>
                <Chart
                    options={apexDonutOpts}
                    series={props.data}
                    type="donut"
                    height={320}
                    className="apex-charts"
                />
            </CardBody>
        </Card>
    );
};
const PieChart = (props) => {
    const apexDonutOpts = {
        chart: {
            height: 320,
            type: 'pie',
        },
        labels: props.labels,
        colors: props.colors,
        tooltip: {
            theme: 'dark',
            x: { show: false }
        },
        legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'center',
            verticalAlign: 'middle',
            floating: false,
            fontSize: '14px',
            offsetX: 0,
            offsetY: -10,
        },
        responsive: [
            {
                breakpoint: 600,
                options: {
                    chart: {
                        height: 240,
                    },
                    legend: {
                        show: false,
                    },
                },
            },
        ],
    };

    const apexDonutData = props.data;

    return (
        <Card>
            <CardBody>

                <Chart options={apexDonutOpts} series={apexDonutData} type="pie" height={320} className="apex-charts" />
            </CardBody>
        </Card>
    );
};
const TargetChart = (props) => {
    const options = {
        chart: {
            type: 'bar',
            stacked: false,
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: true,
                columnWidth: '45%',
            },
        },
        dataLabels: {
            enabled: true,
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
        },
        xaxis: {
            categories: props.categories,
            axisBorder: {
                show: false,
            },
        },
        legend: {
            show: true,
        },
        grid: {
            row: {
                colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.2,
            },
            borderColor: '#f3f4f7',
        },
        tooltip: {
            y: {
                formatter: function(val) {
                    return val + ' %';
                },
            },
        },
    };

    const data = props.data;

    return (
        <Card>
            <CardBody className="pb-0">
                 <Chart options={options} series={data} type="bar" className="apex-charts mt-3" height={296} />
            </CardBody>
        </Card>
    );
};
const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
};
class Dashboard extends Component {
    constructor(props) {
        super(props);
        var oneMonthAgo = new Date();
        oneMonthAgo.setDate(oneMonthAgo.getDate() - 30);
        this.state = {
            //user: this.getLoggedInUser(),
            filterDate: [oneMonthAgo, new Date()],
            panel1: false,
            panel2: false,
            panel3: false,
        };
        this.filterDate = this.filterDate.bind(this)
    }

    getAsyncData = async (actions) => {
        return new Promise((resolve) => {
            resolve(actions);
        });
    };
    getUserInfo = async (user) => {
        return new Promise((resolve) => {
            resolve(user);
        });
    };
    filterDate() {
        let startDate = Date.parse(this.state.filterDate[0]);
        let endDate = Date.parse(this.state.filterDate[1]);
        var admission = moment(startDate).format('YYYY-MM-DD');
        var discharge = moment(endDate).format('YYYY-MM-DD');

        this.setState({
            startDate:admission,
            endDate:discharge,
        })
        this.props.getDashboardByCommercial(getLoggedInUser().id,null,admission,discharge)
    }

    segmentsFormatter(nom,couleur) {

        return (
        <Badge style={{backgroundColor: `${couleur}`, marginLeft: "5px"}} pill>
            {nom}
        </Badge>
        )
    }

    villeFormatter(ville) {

            return (
                <Badge color={"primary"} style={{marginLeft: "5px"}} pill
                       className="mr-1">{ville}</Badge>
            );
    }
    componentDidMount() {
        moment.locale('fr');
        let startDate = Date.parse(this.state.filterDate[0]);
        let endDate = Date.parse(this.state.filterDate[1]);
        var admission = moment(startDate).format('YYYY-MM-DD');
        var discharge = moment(endDate).format('YYYY-MM-DD');

        this.setState({
            startDate:admission,
            endDate:discharge,
         })
        this.props.getDashboardByCommercial(getLoggedInUser().id,null,admission,discharge)
        this.props.getCrmData()

    }
    componentDidUpdate(prevProps, prevState, snapshot) {

        if(prevProps.dashboard!==this.props.dashboard){
            this.getAsyncData(this.props.dashboard).then((dashboard) => {
                this.setState({
                    dashboard:dashboard,
                    averageCA:getNewAverage(dashboard.puissance,dashboard.lastPuissance),
                    averageOpp:getNewAverage(dashboard.nbOpportunites,dashboard.nbLastOpportunites),
                    averageRemp:getNewAverage(dashboard.nbOpportunitesRemportees,dashboard.nbLastOpportunitesRemportees),
                    averagePerd:getNewAverage(dashboard.nbOpportunitesPerdues,dashboard.nbLastOpportunitesPerdues),
                    labels: dashboard.segments,
                    colors: dashboard.segmentsColors,
                    segmentsSales: dashboard.segmentsSales,
                    ventes: dashboard.ventes,
                    ventesColors: dashboard.ventesColors,
                    ventesLabels: dashboard.ventesLabels,



                },function () {
                    console.log("hellodash",dashboard)
                    //this.searchByFilter();
                })
            })

        }

    }

    render() {



        return  this.state.dashboard===undefined ? (
            <Loader></Loader>
        ) : (
            <React.Fragment>

                <div className="">
                    <Row className="page-title align-items-center">
                        <Col sm={4} xl={6}>
                            <h5 className="mb-1 mt-0 text-primary">Tableau de bord:{" "+capitalize(getLoggedInUser().firstName)+" "+capitalize(getLoggedInUser().lastName)}</h5>
                        </Col>
                        <Col sm={8} xl={6}>
                            <form className="form-inline float-sm-right mt-3 mt-sm-0">
                                <Flatpickr
                                    value={this.state.filterDate}
                                    style={{ width: '400px' }}
                                    options={{
                                        locale: French,
                                        enableTime: false,
                                        altInput: true,
                                        dateFormat: 'Y/m/d',
                                        altFormat: 'd/m/y',
                                        allowInput: true,
                                        mode: 'range',
                                    }}
                                    onChange={(date) => {
                                        this.setState({ filterDate: date });
                                    }}
                                    className="form-control"
                                />

                                <Button
                                    color={'primary'}
                                    className={'ml-2'}
                                    id="group1"
                                    href="#"
                                    onClick={() => {
                                        this.filterDate();
                                    }}>
                                    <i className="uil uil-calender "> </i>Changer la periode
                                </Button>

                            </form>
                        </Col>

                    </Row>




                    <Row>
                        <Col md={6} xl={3}>
                            <StatisticsChartWidget
                                description="KWC Vendus"
                                title={this.state.dashboard.puissance.toFixed(2) + ' KWc'}
                                data={this.state.dashboard.puissancesInstallees}
                                trend={

                                    this.state.averageCA >= 0
                                        ? {
                                            textClass: 'text-success',
                                            icon: 'uil uil-arrow-up',
                                            value: this.state.averageCA + '%',
                                        }
                                        : this.state.averageCA < 0
                                        ? {
                                            textClass: 'text-danger',
                                            icon: 'uil uil-arrow-down',
                                            value: this.state.averageCA + '%',
                                        }
                                        : {
                                            textClass: 'text-warn',
                                            icon: 'uil uil-sunset',
                                            value: '--' + ' NA',
                                        }
                                }
                            />
                        </Col>
                        <Col md={6} xl={3}>
                            <StatisticsChartWidget
                                description="Opportunités"
                                title={this.state.dashboard.nbOpportunites + " Crées"}
                                data={this.state.dashboard.currentOpportunites }
                                colors={['#f77e53']}
                                trend={
                                    this.state.averageOpp>= 0
                                        ? {
                                            textClass: 'text-success',
                                            icon: 'uil uil-arrow-up',
                                            value: this.state.averageOpp + '% / Préc: '+this.state.dashboard.nbLastOpportunites
                                        }
                                        : this.state.averageOpp < 0
                                        ? {
                                            textClass: 'text-danger',
                                            icon: 'uil uil-arrow-down',
                                            value: this.state.averageOpp + '% / Préc: '+this.state.dashboard.nbLastOpportunites
                                        }
                                        : {
                                            textClass: 'text-warn',
                                            icon: 'uil uil-sunset',
                                            value: '--' + ' NA',
                                        }
                                }
                            />
                        </Col>

                        <Col md={6} xl={3}>
                            <StatisticsChartWidget
                                description="Remportées"
                                title={this.state.dashboard.nbOpportunitesRemportees + " Signées"}
                                data={this.state.dashboard.currentOpportunitesRemportees }
                                colors={['#f77e53']}
                                trend={
                                    this.state.averageRemp> 0
                                        ? {
                                            textClass: 'text-success',
                                            icon: 'uil uil-arrow-up',
                                            value: this.state.averageRemp + '% / Préc: '+this.state.dashboard.nbLastOpportunitesRemportees
                                        }
                                        : this.state.averageRemp < 0
                                        ? {
                                            textClass: 'text-danger',
                                            icon: 'uil uil-arrow-down',
                                            value: this.state.averageRemp + '% / Préc: '+this.state.dashboard.nbLastOpportunitesRemportees
                                        }
                                        : {
                                            textClass: 'text-warn',
                                            icon: 'uil uil-sunset',
                                            value: '--' + ' NA',
                                        }
                                }
                            />
                        </Col>

                        <Col md={6} xl={3}>
                            <StatisticsChartWidget
                                description="Perdues"
                                title={this.state.dashboard.nbOpportunitesPerdues + " Perdues"}
                                data={this.state.dashboard.currentOpportunitesPerdues }
                                colors={['#f77e53']}
                                trend={
                                    this.state.averagePerd>= 0
                                        ? {
                                            textClass: 'text-success',
                                            icon: 'uil uil-arrow-up',
                                            value: this.state.averagePerd + '% / Préc: '+this.state.dashboard.nbLastOpportunitesPerdues
                                        }
                                        : this.state.averagePerd < 0
                                        ? {
                                            textClass: 'text-danger',
                                            icon: 'uil uil-arrow-down',
                                            value: this.state.averagePerd + '% / Préc: '+this.state.dashboard.nbLastOpportunitesPerdues
                                        }
                                        : {
                                            textClass: 'text-warn',
                                            icon: 'uil uil-sunset',
                                            value: '--' + ' NA',
                                        }
                                }
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col xl={4}>
                            <Card>
                                <CardBody className="">
                                    <h5 className="card-title mt-0 mb-0 header-title">Nature des ventes</h5>
                                    <PieChart
                                        labels={this.state.ventesLabels}
                                        colors={this.state.ventesColors}
                                        data={this.state.ventes}
                                    />

                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={4}>
                            <Card>
                                <CardBody className="">
                                    <h5 className="card-title mt-0 mb-0 header-title">KWc vendus par segment</h5>
                                    <DonutChart
                                      /*  options={this.state.segmentSales}
                                        series={this.state.segmentSalesData}*/
                                        type="donut"
                                        className="apex-charts mb-0 mt-4"
                                        height={302}
                                        labels={this.state.labels}
                                        colors={this.state.colors}
                                        data={this.state.dashboard.segmentsPuissances}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={4}>

                            <StatisticsChartWidget2
                                name="Toutes"
                                type="area"
                                title={this.state.dashboard.nbTachesAttentes+ " En Attente"}
                                subtitle={"Tâches à faire"}

                                colors={['#5369f8']}
                                data={this.state.dashboard.tachesAttentes}>

                            </StatisticsChartWidget2>
                            <StatisticsChartWidget2
                                name="Accomplies"
                                type="area"
                                title={this.state.dashboard.nbTachesAccomplies+ " Accomplies"}
                                subtitle={"Tâches accomplies"}
                                colors={['#43d39e']}
                                data={this.state.dashboard.tachesAccomplies}>

                            </StatisticsChartWidget2>
                            <StatisticsChartWidget2
                                name="En retard"
                                type="area" title={this.state.dashboard.nbTachesRetards+ " En Retard"}
                                subtitle={"Tâches urgentes"}
                                colors={['#f77e53']}
                                data={this.state.dashboard.tachesRetards}>

                            </StatisticsChartWidget2>
                        </Col>

                        {
                            this.state.dashboard.opportunitesRemportees.length>0
                                ?<React.Fragment>
                                    <Col xl={12}>
                                        <Card>
                                            <CardBody className="">
                                                <h5 className="card-title mt-0 mb-0 header-title">Listes des opportunités remportées</h5>


                                            </CardBody>
                                        </Card>
                                    </Col>

                                    <Col xl={12}>
                                        <Row>
                                            {
                                                this.state.dashboard.opportunitesRemportees.map((opportunite, index)=>{
                                                    return(



                                                        <Col xl={3} key={index} >
                                                            <Card>
                                                                <CardBody className="">

                                                                    <div className="d-sm-flex justify-content-between">
                                                                        <div className="font-weight-semibold success" style={{width:"100%",fontSize: "10px",color:"#000",fontStyle:"italic",textAlign:"left",overflow:"hidden", paddingRight:"5px" , float:"left"}}>

                                                                            {"Créé le: "+opportunite.startDate}

                                                                        </div>
                                                                        <div  className="font-weight-semibold text-dark" style={{width:"100%",fontWeight:"bold",fontSize: "10px",color:"#000",fontStyle:"italic",textAlign:"right",overflow:"hidden", paddingRight:"5px", float:"right"}}>

                                                                            {"Cloturée le: "+opportunite.dateCloture}

                                                                        </div>
                                                                    </div>
                                                                    <Row>
                                                                        <Col xl={12}>






                                                                            <Link key={index}  target="_blank" to={{
                                                                                pathname: `/opportunites/${opportunite.id}`}} >
                                                               <span style={{marginBottom:"10px",fontSize: "18px" ,fontStyle:"italic", float:'left',overflow:"hidden",textOverflow:"ellipsis",display:"-webkit-box", webkitLineClamp:"1",webkitBoxOrient:"vertical", paddingRight:"5px"}} className="font-weight-semibold">

                                                                        {"Opp:"+opportunite.id+"-"+capitalize(opportunite.prospect.fullName)}

                                                               </span>
                                                                            </Link>

                                                                        </Col>
                                                                    </Row>

                                                                    {
                                                                        opportunite.ca==0||opportunite.puissance==0
                                                                            ?<Row>

                                                                                <span  className="font-weight-semibold blink_me " style={{position:"absolute" ,top:"60px",width:"100%",fontSize: "11px",color:"#f80003",fontStyle:"italic", float:'right',textAlign:"right",overflow:"hidden", paddingRight:"25px"}}>

                                                                                   {"Le chiffrage est manquant"}

                                                                                </span>

                                                                            </Row>
                                                                            :""
                                                                    }

                                                                    <Row>
                                                                        <Col xl={12}>
                                                                            <div className="d-sm-flex justify-content-between">
                                                               <span style={{fontSize: "18px",paddingLeft:"15px", color:"#000"}} className="font-weight-bold">

                                                                        {opportunite.puissance + " KWc"}

                                                                </span>
                                                                                <span style={{fontSize: "18px",}} className="font-weight-semibold">

                                                                        {opportunite.ca + " DT"}

                                                                </span>
                                                                            </div>

                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                                <span  className="font-weight-semibold success" style={{width:"100%",fontSize: "10px",color:"#5369f8",fontStyle:"italic", float:'right',textAlign:"right",overflow:"hidden", paddingRight:"5px"}}>

                                                                                   {capitalize(opportunite.canalProspect.type)+ " >> " + capitalize(opportunite.canalProspect.nom)}

                                                                                </span>
                                                                    </Row>

                                                                    <Row>
                                                                        <Col xl={12}>


                                                                            <img key={index} src={
                                                                                opportunite.owner.photo===undefined||opportunite.owner.photo===""?
                                                                                    avatar: SERVERUIMAGESUSERS+ opportunite.owner.photo} alt=""
                                                                                 className="avatar-xs rounded-circle ml-1" id={`task-avtc-${index}`}/>
                                                                            <UncontrolledTooltip placement="bottom"
                                                                                                 target={`task-avtc-${index}`}>{ opportunite.owner.fullName === undefined ? "" : opportunite.owner.fullName}
                                                                            </UncontrolledTooltip>

                                                                            {opportunite.nom === undefined ? "" : this.segmentsFormatter(opportunite.nom,opportunite.couleur)}
                                                                            {opportunite.villesOpportunites === undefined ? "" : this.villeFormatter(opportunite.villesOpportunites.ville)}


                                                                        </Col>
                                                                    </Row>





                                                                </CardBody>
                                                            </Card>
                                                        </Col>



                                                    )
                                                })
                                            }
                                        </Row>
                                    </Col>
                                    <Col xl={12}>
                                        <Card>
                                            <CardBody className="pb-0">
                                                <h5 className="card-title header-title">Atteinte des objectifs en pourcentage (%)</h5>
                                                <div className="" style={{paddingBottom:"10px"}}>

                                                    {
                                                        this.state.dashboard.objectifsCommercial.map((objectifCom, index) => {
                                                            return(
                                                                <React.Fragment key={index}>
                                                                    {

                                                                        this.state.dashboard.objectifsresults.filter((obres) => objectifCom.segmentName===obres.segment&&obres.result).length>0
                                                                            ?<CheckCircle className="icon-xs icon-dual-success font-size-11 mr-1"></CheckCircle>
                                                                            :<XCircle className="icon-xs icon-dual-danger font-size-11 mr-1"></XCircle>
                                                                    }

                                                                    <span className="text-muted mt-1 pr-1 font-weight-bold" style={{minHeight:"50px"}} >{objectifCom.segmentName}
                                                                        <span className="text-muted mt-1 small pr-1"  ><span className="text-primary font-weight-bold"> Nb pro.:</span>{" "+((objectifCom.nbProjets)/30*this.state.dashboard.periode).toFixed(0)+ " "}</span>
                                                                                      <span className="text-muted mt-1 small pr-1"  ><span className="text-primary font-weight-bold"> Puiss.:</span>{" "+((objectifCom.puissance)/30*this.state.dashboard.periode).toFixed(2)+ " KWc "}</span>
                                                                                      <span className="text-muted mt-1 small pr-1"  ><span className="text-primary font-weight-bold">  C.A.:</span>{" "+((objectifCom.ca)/30*this.state.dashboard.periode).toFixed(3)+ " DT "}</span>

                                                                        </span>

                                                                </React.Fragment>
                                                            )
                                                            /* */
                                                        })
                                                    }
                                                </div>

                                                <TargetChart
                                                 categories={this.state.dashboard.objectifsLabels}
                                                 data={this.state.dashboard.performed}

                                                />

                                             </CardBody>
                                        </Card>
                                    </Col>
                                </React.Fragment>
                                :""
                        }





                    </Row>




                </div>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = {
    /*    getSegments,
    getCanaux,
    getSelectedCanal,
    getSelectedVille,
    getVilles,
    setProspect,
    getProspects,
    affectProspect,*/

    //getOpportunites,
   // getUsers,
   // getTachesByExecuteur,
   // getOpportuniteByCommercialByPeriod,
    getDashboardByCommercial,
    getCrmData
    /*,
    getTodayOpportunites,
    getIncomingOpportunites,
    getOthersOpportunites,
    updateTache*/
};

const mapStateToProps = (state) => ({

   // taches: state.Tache.taches,
   // opportunites: state.Opportunite.opportunites1,
   // users: state.Crm.user,
    data:state.Crm.data,

    dashboard: state.Opportunite.dashboard,
    /* expiredTaches: state.Tache.expiredTaches,*/
});

Dashboard = connect(mapStateToProps, mapDispatchToProps, null)(Dashboard);
export default Dashboard;
