import {
    VERIFY_PROSPECT,VALID_PROSPECT_RECEIVED,NOTIFY_PROSPECT_USER,
    SET_PROSPECT,
    PROSPECT_ADDED,
    PROSPECT_REQUEST_FAILED,
    PROSPECTS_RECEIVED,
    GET_PROSPECTS,
    PATCH_PROSPECT,
    PROSPECT_PATCHED,
    DELETE_PROSPECT, PROSPECT_DELETED,
    AFFECT_PROSPECT,PROSPECT_AFFECTED,
    GET_PROSPECT,
    PROSPECT_RECEIVED, SET_OPPORTUNITE_FROM_PROSPECT,
    OPPORTUNITE_ADDED_FROM_PROSPECT

} from './constants';

export const setProspect = (data,history) => ({
    type: SET_PROSPECT,
    payload: {data,history},
});

export const prospectAdded = (data, history) => ({
    type: PROSPECT_REQUEST_FAILED,
    payload: { data, history},
});
export const updateProspect = (data, history) => ({
    type: PATCH_PROSPECT,
    payload: {data, history},
});

export const prospectPatched = (data,id) => ({
    type: PROSPECT_PATCHED,
    payload: { data,id},
});

export const affectProspect = (data,history) => ({
    type: AFFECT_PROSPECT,
    payload: {data,history},
});

export const prospectAffected = (data,history) => ({
    type: PROSPECT_AFFECTED,
    payload: { data,history},
});
/*
export const affectProspect = (prospects,agence,segment,idCommercial,idAction,idPriorite,remarque,owner,etat,startDate) => ({
    type: AFFECT_PROSPECT,
    payload: {prospects,agence,segment,idCommercial,idAction,idPriorite,remarque,owner,etat,startDate},
});

export const prospectAffected = (prospects,agence,segment,idCommercial,idAction,idPriorite,remarque,owner,etat,startDate) => ({
    type: PROSPECT_AFFECTED,
    payload: { prospects,agence,segment,idCommercial,idAction,idPriorite,remarque,owner,etat,startDate},
});
*/


export const deleteProspect = (history,id) => ({
    type: DELETE_PROSPECT,
    payload: {history,id},
});
export const prospectDeleted = (history,id) => ({
    type: PROSPECT_PATCHED,
    payload: { history,id},
});


export const getProspects = (idCommercial,idAgence) => ({
    type: GET_PROSPECTS,
    payload: { idCommercial,idAgence},
});
export const prospectsReceived = (idCommercial,idAgence) => ({
    type: PROSPECTS_RECEIVED,
    payload: {idCommercial,idAgence},

});




export const getProspect = (idProspect) => ({
    type: GET_PROSPECT,
    payload: { idProspect}
});
export const prospectReceived = (idProspect) => ({
    type: PROSPECT_RECEIVED,
    payload: { idProspect}

});
export const verifyProspect = (tel1,tel2,email) => ({
    type: VERIFY_PROSPECT,
    payload: { tel1,tel2,email}
});
export const prospectVerified = (tel1,tel2,email) => ({
    type: VALID_PROSPECT_RECEIVED

});

export const createOpportuniteFromProspect = (data,history) => ({
    type: SET_OPPORTUNITE_FROM_PROSPECT,
    payload: {data,history},
});

export const OpportuniteFromProspectAdded = (data, history) => ({
    type: OPPORTUNITE_ADDED_FROM_PROSPECT,
    payload: { data, history},
});