// @flow

import { combineReducers } from 'redux';
import Layout from './layout/reducers';
import Auth from './auth/reducers';
import AppMenu from './appMenu/reducers';
import Crm from './crm/reducers';
import Agence from './crm/agence/reducers';
import Prospect from './crm/prospect/reducers';

import Tache from './crm/tache/reducers';
import Opportunite from './crm/opportunite/reducers';

export default combineReducers({
    Auth,
    Crm,
    AppMenu,
    Layout,
    Agence,
    Prospect,
    Tache,
    Opportunite,


});
