// @flow
import {
    UPLOAD_FILE,
    FILE_UPLOADED,
    GET_FILES,
    FILES_RECEIVED,
    GET_VILLES,
    VILLES_RECEIVED,
    UPDATE_USER,USER_UPDATED,UPDATE_USER_FAILURE,
    USERS_RECEIVED,
    GET_USERS,
    SET_VILLE_POSITION,
    VILLE_POSITION_RECEIVED,
    INSERT_AGENCE,
    AGENCE_ADDED,
    SET_SEGMENT,GET_ETAT,ETAT_RECEIVED,GET_CATEGORIE,CATEGORIE_RECEIVED,ADD_REFERENCE,REFERENCE_ADDED,
    SEGMENT_ADDED,
    SEGMENT_REQUEST_FAILED,
    SELECTED_ACTION,
    RECEIVED_SELECTED_ACTION,
    GET_SEGMENTS,
    SEGMENTS_RECEIVED,
    SEGMENT_PATCHED,
    PATCH_SEGMENT,
    DELETE_SEGMENT,
    SEGMENT_DELETED,
    COMMERCIAUX_AGENCE_ADDED,
    SET_COMMERCIAUX_AGENCE,
    GET_CANAUX,
    CANAUX_RECEIVED,
    SELECTED_CANAL,
    RECEIVED_SELECTED_CANAL,
    RECEIVED_SELECTED_VILLE,
    SELECTED_VILLE,
    ACTION_RECEIVED,
    GET_ACTION,
    PLANIFIED_ACTION_RECEIVED,
    PLANIFIED_ACTION,
    CRITERES_SEGMENT_RECEIVED,
    CRITERES_SEGMENT,
    RECEIVED_SELECTED_SEGMENT,
    SELECTED_SEGMENT,
    SET_USER,
    USER_ADDED,
    ADD_FAILURE,
    SET_MARKER_POSITION,
    MARKER_POSITION_RECEIVED,
    GET_FILE_BY_NAME,
    FILE_RECIVED,
    SELECTED_USER,
    RECEIVED_SELECTED_USER,
    SELECTED_PRIORITE,RECEIVED_SELECTED_PRIORITE,UPDATE_REFERENCE,REFERENCE_UPDATED,
    GET_CAUSE_PERTE,
    CAUSE_PERTE_RECEIVED,
    SET_FCM_TOKEN,FCM_TOKEN_ADDED,GET_FCM_TOKEN,FCM_TOKEN_RECEIVED,GET_CRM_DATA,CRM_DATA_RECEIVED
} from './constants';
import { SET_AGENCE } from './agence/constants';
import { LOGIN_USER_FAILED, LOGIN_USER_SUCCESS } from '../auth/constants';
import {GET_PROSPECTS, PROSPECTS_RECEIVED} from "./prospect/constants";

const Crm = (state = {}, action) => {
    switch (action.type) {
        case GET_CRM_DATA:
            return { ...state, loading: true};
        case CRM_DATA_RECEIVED:
            return { ...state, data:action.json,loading:false }
        case GET_VILLES:
            return { ...state, loading: true };
        case VILLES_RECEIVED:
            return { ...state, villes: action.json, loading: false };
        case GET_USERS:
            return { ...state, loading: true };
        case USERS_RECEIVED:
            switch (action.role) {
                case 'affectationAgence':
                    return { ...state, commerciaux: action.json['hydra:member'], role: action.role, loading: false };
                case 'selectedAffectationAgence':
                    return { ...state, selectedCommerciaux: action.json['hydra:member'], role: action.role, loading: false };
                case 'commercial':
                    return { ...state, commercial: action.json['hydra:member'], role: action.role, loading: false };
                case 'coordinateur':
                    return { ...state, coordinateur: action.json['hydra:member'], role: action.role, loading: false };
                case 'byId':
                    return { ...state, user: action.json, role: action.role, loading: false };

                case 'byAgence':
                    return { ...state, users: action.json['hydra:member'], role: action.role, loading: false };
                default:
                    return { ...state, users: action.json['hydra:member'], role: action.role, loading: false };
            }

        case GET_CANAUX:
            return { ...state, canaux: action.json, loading: true };
        case CANAUX_RECEIVED:
            return { ...state, canaux: action.json, loading: false };
        case GET_CAUSE_PERTE:
            return { ...state, causes: action.json, loading: true };
        case CAUSE_PERTE_RECEIVED:
            return { ...state, causes: action.json, loading: false };

        case SELECTED_CANAL:
            return { ...state, selectedCanal: action.payload, loading: true };
        case RECEIVED_SELECTED_CANAL:
            return { ...state, selectedCanal: action.canal, loading: false };

        case SELECTED_VILLE:
            return { ...state, selectedVille: [{ id: action.id, ville: action.ville }], loading: true };
        case RECEIVED_SELECTED_VILLE:
            return { ...state, selectedVille: [{ id: action.id, ville: action.ville }], loading: false };

        case SET_VILLE_POSITION:
            return { ...state, loading: true };
        case VILLE_POSITION_RECEIVED:
            return { ...state, lat: action.lat, long: action.long, loading: true };

        case SET_MARKER_POSITION:
            return { ...state, loading: true };
        case MARKER_POSITION_RECEIVED:
            return { ...state, latMarker: action.latMarker, longMarker: action.longMarker, loading: true };
        case INSERT_AGENCE:
            return { ...state, loading: false };
        case AGENCE_ADDED:
            return { ...state, agenceAdded: action.json, loading: true };

        case SET_SEGMENT:
            return { ...state, segmentAdded: true, loading: true };
        case SEGMENT_ADDED:
            return { ...state, loading: true };

        case PATCH_SEGMENT:
            return { ...state, segmentPatched: true, loading: true };
        case SEGMENT_PATCHED:
            return { ...state, loading: true };

        case DELETE_SEGMENT:
            return { ...state, segmentDeleted: true, loading: true };
        case SEGMENT_DELETED:
            return { ...state, loading: true };
        case SEGMENT_REQUEST_FAILED:
            return { ...state, error: action.error, loading: false };

        case GET_SEGMENTS:
            return { ...state, loading: true };
        case SEGMENTS_RECEIVED:
            return { ...state, segments: action.json, loading: false };

        case GET_ACTION:
            return { ...state, loading: true };
        case ACTION_RECEIVED:
            return { ...state, actions: action.json, loading: false };
        case GET_ETAT:
            return { ...state, loading: true };
        case ETAT_RECEIVED:
            return { ...state, etats: action.json, loading: false };
        case GET_CATEGORIE:
            return { ...state, loading: true };
        case CATEGORIE_RECEIVED:
            return { ...state, categories: action.json, loading: false };
        case SET_COMMERCIAUX_AGENCE:
            return { ...state, agenceCommerciaux: action.data, loading: true };
        case COMMERCIAUX_AGENCE_ADDED:
            return { ...state, agenceCommerciaux: action.data, loading: false };

        case PLANIFIED_ACTION:
            return { ...state, loading: true };
        case PLANIFIED_ACTION_RECEIVED:
            return { ...state, planifiedActions: action, loading: false };

        case CRITERES_SEGMENT:
            return { ...state, loading: true };
        case CRITERES_SEGMENT_RECEIVED:
            return { ...state, criteresSegment: action, loading: false };

        case SELECTED_SEGMENT:
            return { ...state, selectedSegment: action.payload, loading: true };
        case RECEIVED_SELECTED_SEGMENT:
            return { ...state, selectedSegment: action.segment, loading: false };


        case SELECTED_ACTION:
            return { ...state, selectedAction:action.payload, loading: true };
        case RECEIVED_SELECTED_ACTION:
            return { ...state, selectedAction: action.data, loading: false };

        case SELECTED_PRIORITE:
            return { ...state, selectedPriorite:action.payload, loading: true };
        case RECEIVED_SELECTED_PRIORITE:
            return { ...state, selectedPriorite: action.data, loading: false };





        case SELECTED_USER:
            return { ...state, selectedUser: action.payload, loading: true };
        case RECEIVED_SELECTED_USER:
            return { ...state, selectedUser: action.user, loading: false };
        case SET_USER:
            return { ...state, userAdded: true, loading: true };
        case USER_ADDED:
            return { ...state, loading: false };

        case ADD_FAILURE:
            return {
                ...state,
                error: action.payload,
                loading: false,
                notifyMessage: action.notifyMessage,
                status: action.status,
                dateNotify: action.dateNotify,
            };

        case UPDATE_USER:
            return { ...state, userUpdated: true, loading: true };
        case USER_UPDATED:
            return { ...state, loading: false, notifyMessage: action.notifyMessage,
                status: action.status,
                dateNotify: action.dateNotify, };

        case UPDATE_USER_FAILURE:
            return {
                ...state,
                error: action.payload,
                loading: false,
                notifyMessage: action.notifyMessage,
                status: action.status,
                dateNotify: action.dateNotify,
            };

        case GET_FILE_BY_NAME:
            return { ...state, loading: false };

        case FILE_RECIVED:
            return { ...state, file: action.file, loading: true };

        case GET_FILES:
            return {
                ...state,
                loading: false,
                uploadedFiles: action.uploadedFiles,
                filesToRemove: action.filesToRemove,
            };

        case FILES_RECEIVED:
            return {
                ...state,
                loading: false,
                uploadedFiles: action.uploadedFiles,
                filesToRemove: action.filesToRemove,
            };



        case ADD_REFERENCE:
            return { ...state, addedReference: action.payload.data.refValue, loading: true  };
        case REFERENCE_ADDED:
            return { ...state, loading: true,
                statusAddReference: action.statusAddReference };


        case UPDATE_REFERENCE:
            return { ...state, updatedReference: action.payload.data.refValue, loading: true  };
        case REFERENCE_UPDATED:
            return { ...state, loading: true, notifyMessageUpdateReference: action.notifyMessageUpdateReference,
                statusUpdateReference: action.statusUpdateReference };
        case SET_FCM_TOKEN:
            return { ...state, fcmAdded: true };
        case FCM_TOKEN_ADDED:
            return { ...state, fcm: action.fcm };

        default:
            return state;
    }
};
export default Crm;
