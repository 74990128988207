export const SET_AGENCE = 'SET_AGENCE';
export const AGENCE_ADDED = 'AGENCE_ADDED';
export const AGENCE_REQUEST_FAILED = 'AGENCE_REQUEST_FAILED';

export const GET_AGENCES = 'GET_AGENCES';
export const AGENCES_RECEIVED = 'AGENCES_RECEIVED';

export const PATCH_AGENCE = 'PATCH_AGENCE';
export const AGENCE_PATCHED = 'AGENCE_PATCHED';

export const DELETE_AGENCE = 'DELETE_AGENCE';
export const AGENCE_DELETED = 'AGENCE_DELETED';



export const SELECTED_AGENCE = 'SELECTED_AGENCE';
export const RECEIVED_SELECTED_AGENCE = 'RECEIVED_SELECTED_AGENCE';