// @flow
import {
   
    SET_TACHE,
    TACHE_ADDED,
    TACHE_REQUEST_FAILED,
    GET_TACHES,
    TACHES_RECEIVED, TACHE_PATCHED,PATCH_TACHE,DELETE_TACHE,
    TACHE_DELETED,AFFECT_TACHE,TACHE_AFFECTED,GET_TACHE,
    TACHE_RECEIVED,OPPORTUNITE_RECEIVED,GET_OPPORTUNITE,
    SET_REMARQUE_TACHE,REMARQUE_TACHE_ADDED,AFFECTED_TACHE_FROM_OPPORTUNITE,
    AFFECT_TACHE_FROM_OPPORTUNITE,
    GET_TACHES_BY_EXECUTEUR,TACHES_BY_EXECUTEUR_RECEIVED,
    GET_TACHES_BY_DEMANDEUR,TACHES_BY_DEMANDEUR_RECEIVED,
    GET_ALL_TACHES,ALL_TACHES_RECEIVED,
    GET_TACHES_BY_AGENCE, TACHES_BY_AGENCE_RECEIVED,
} from './constants';
import {AFFECT_PROSPECT, PROSPECT_AFFECTED} from "../prospect/constants";
const Tache = (state ={}, action) => {
    switch (action.type) {
        case SET_TACHE:
            return { ...state, tacheAdded:true,loading: true };
        case SET_REMARQUE_TACHE:
            return { ...state, loading: true };
        case TACHE_ADDED:
            return { ...state, loading:true };
        case PATCH_TACHE:
            return { ...state, tachePatched:true,loading: true };
        case TACHE_PATCHED:
            return { ...state, loading:false };
        case AFFECT_TACHE:
            return { ...state, tacheAffected:true,loading: true };
        case TACHE_AFFECTED:
            return { ...state, loading:true };
        case DELETE_TACHE:
            return { ...state, tacheDeleted:true,loading: true };
        case TACHE_DELETED:
            return { ...state, loading:false };
        case TACHE_REQUEST_FAILED:
            return { ...state,  error:action.error, loading:false };
        case GET_TACHES:
            return { ...state, loading: true };
        case TACHES_RECEIVED:

            switch (action.role) {
                case 'byExecuteur':
                    return { ...state, taches:action.json,role: action.role ,loading: false }
                 case 'byDemandeur':
                    return { ...state, taches:action.json,role: action.role ,loading: false }
                 case 'mesTaches':
                    return { ...state, taches:action.json,role: action.role ,loading: false }
                case 'tachesAgence':
                    return { ...state, taches:action.json,role: action.role ,loading: false }
                case 'expiredTaches':
                    return { ...state, expiredTaches:action.json,role: action.role ,loading: false }
                case 'expiredTachesAgence':
                    return { ...state, expiredTaches:action.json,role: action.role ,loading: false }
                case 'expiredTachesTous':
                    return { ...state, expiredTaches:action.json,role: action.role ,loading: false }
                case 'references':
                    return { ...state, tachesReferences:action.json,role: action.role ,loading: false }
                default:
                    return { ...state, taches:action.json,role: action.role ,loading: false }
            }
        case GET_TACHE:
            return { ...state, loading: true };
        case TACHE_RECEIVED:
            return { ...state, tache:action.json, loading: false }
        case GET_OPPORTUNITE:
            return { ...state, opploading: true };
        case OPPORTUNITE_RECEIVED:
            return { ...state, opportunite:action.json, opploading: false }
        default:
            return state;
        case AFFECT_TACHE_FROM_OPPORTUNITE:
            return { ...state, prospectAffected:true,loading: true, };
        case AFFECTED_TACHE_FROM_OPPORTUNITE:
            return { ...state, loading:false,notifyMessage:action.notifyMessage ,status:action.status};
        case GET_TACHES_BY_EXECUTEUR:
            return { ...state, loading: true };
        case TACHES_BY_EXECUTEUR_RECEIVED:
            return { ...state, taches1:action.json,loading: false }
        case GET_TACHES_BY_DEMANDEUR:
            return { ...state, loading: true };
        case TACHES_BY_DEMANDEUR_RECEIVED:
            return { ...state, taches1:action.json,loading: false }
        case GET_ALL_TACHES:
            return { ...state, loading: true };
        case ALL_TACHES_RECEIVED:
            return { ...state, taches1:action.json,loading: false }
        case GET_TACHES_BY_AGENCE:
            return { ...state, loading: true };
        case TACHES_BY_AGENCE_RECEIVED:
            return { ...state, taches1:action.json,loading: false }
    }
};
export default Tache