import React from "react";
import PropTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ReactNotificationComponent = ({ title, body, icon,href }) => {
  let hideNotif = title === "";

  if (!hideNotif) {
    toast.info(<Display />);
  }

  function Display() {
    return (
        <React.Fragment>
          <div style={{float:'left'}}>
            <img src={icon}
                 className="mr-2 rounded-circle" height="36" alt=""/>
          </div>
          <div style={{float:'left'}}>
            <h4 style={{ color: '#FFF', fontSize:'15px', lineHeight:'0' }}>{title}</h4>
            <p>{body}</p>

            {/*<p>{href}</p>*/}
          </div>
        </React.Fragment>

    );
  }

  return (
    <ToastContainer
      autoClose={3000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss={false}
      draggable
      pauseOnHover={false}
    />
  );
};

ReactNotificationComponent.defaultProps = {
  title: "This is title",
  body: "Some body",
  icon: "Some icon",
  href: "Some link",
};

ReactNotificationComponent.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  icon: PropTypes.string,
  href: PropTypes.string,
};

export default ReactNotificationComponent;
