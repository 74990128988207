import React from 'react';

import ReactDOM from 'react-dom';
import Switch from 'react-switch';
import Sticky from 'react-sticky-el';
import { SERVERUIMAGESUSERS, FILES, SEARCHAPITACHE, SERVERURI, TOKEN } from '../../helpers/api';

import {
    Row,
    Col,
    Card,
    CardBody,
    Label,
    InputGroupAddon,
    FormGroup,
    CustomInput,
    Button,
    ModalBody,
    ModalFooter,
    Modal,
    Progress,
    Input,
    Badge,
    ButtonGroup,
    UncontrolledTooltip,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    ModalHeader,
    UncontrolledButtonDropdown,
    Collapse,
    Media,
    Spinner,
    UncontrolledAlert,
    NavLink,
    CardHeader,
} from 'reactstrap';
import avatar from '../../assets/images/users/user.png';
//import Button1 from  '../../components/Button';
import {
    AvForm,
    AvField,
    AvGroup,
    AvInput,
    AvFeedback,
    AvCheckbox,
    AvCheckboxGroup,
} from 'availity-reactstrap-validation';
import Select from 'react-select';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import PageTitle from '../../components/PageTitle';
import VillesSelectSingle from '../../components/VillesSelectSingle';
import CanauxSelectSingle from '../../components/CanauxSelectSingle';
import ActionSelectSingle from '../../components/ActionSelectSingle';

import UsersSelectSingle from '../../components/UsersSelectSingle';
import UsersSelectMultiple from '../../components/UsersSelectMultiple';
import CoordinateursSelectSingle from '../../components/CoordinateursSelectSingle';
import AgenceMap from '../../components/AgenceMap';
import { setProspect, getProspects, affectProspect } from '../../redux/crm/prospect/actions';
import { getTaches, updateTache, getTachesByExecuteur,getTachesByDemandeur,getAllTaches,
getTachesByAgence} from '../../redux/crm/tache/actions';
import {
    getOpportunites,
    getTodayOpportunites,
    getIncomingOpportunites,
    getOthersOpportunites,
} from '../../redux/crm/opportunite/actions';
import * as FeatherIcon from 'react-feather';
import {
    getSegments,
    setVillePosition,
    getCanaux,
    getVilles,
    getSelectedCanal,
    getSelectedVille,
} from '../../redux/crm/actions';
import { connect } from 'react-redux';
import { setSegment } from '../../redux/crm/actions';
import { Briefcase } from 'react-feather';
import { Step, Steps, Wizard } from 'react-albus/lib';
import { getLoggedInUser } from '../../helpers/authUtils';
import BootstrapTable from 'react-bootstrap-table-next';

import filterFactory, {
    textFilter,
    selectFilter,
    Comparator,
    dateFilter,
    FILTER_TYPES,
} from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Loader from '../../components/Loader';
import moment from 'moment';
import 'moment-duration-format';

import 'moment/min/moment-with-locales';

import 'moment/locale/fr';
import avatarImg2 from '../../assets/images/users/avatar-9.jpg';
import avatarImg3 from '../../assets/images/users/avatar-3.jpg';
import classNames from 'classnames';
import { otherTasks, todayTasks, upcomingTasks } from '../apps/Tasks/Data';
import { Link } from 'react-router-dom';
//import Task from "../apps/Tasks/Task";
import RichTextEditor from '../../components/RichTextEditor';
import avatar3 from '../../assets/images/users/avatar-8.jpg';
import avatar1 from '../../assets/images/users/user.png';
import { get, isEmpty, find, filter, has, debounce } from 'lodash';
import * as _ from 'lodash';

const ChatItem = ({ children, placement, className }) => {
    return <li className={classNames('clearfix', { odd: placement === 'left' }, className)}>{children}</li>;
};
const ChatItemText = ({ userName, text }) => {
    return (
        <div className="conversation-text">
            <div className="ctext-wrap">
                <i>{userName}</i>
                <p>{text}</p>
            </div>
        </div>
    );
};
const ChatItemAvatar = ({ userAvatar, postedOn }) => {
    return (
        <div className="chat-avatar">
            <img src={userAvatar} alt={userAvatar} />
            <i>{postedOn}</i>
        </div>
    );
};

const TaskComment = (item) => {
    return (
        <Media className="mt-3 p-1">
            <img src={item.author_avatar} className="mr-2 rounded-circle" height="36" alt="" />
            <Media body>
                <h5 className="mt-0 mb-0 font-size-14">
                    <span className="float-right text-muted font-size-12">{item.posted_on}</span>
                    {item.author}
                </h5>
                <p className="mt-1 mb-0 text-muted">{item.text}</p>
            </Media>
        </Media>
    );
};

const RemarquesOpportunite = (tache) => {
    if (tache.opportuniteParent !== undefined) {
        return (
            <React.Fragment>
                {/* {tache.opportuniteParent.remarqueOpportunite.map((rem, index) => {
                //return <p key={index}>{JSON.stringify(tache)} </p>

                return <React.Fragment key={index}>
                    <div className="toast fade show" role="alert">
                        <div className="toast-header">

                            <div className="mr-auto">

                                <img  src= {rem.editeur === undefined ? avatar : rem.editeur.photo===undefined? avatar :  SERVERUIMAGESUSERS+"/"+rem.editeur.photo.filePath} alt=""
                                      className="avatar-xs rounded-circle mr-2"/>


                                <span className="mr-2">{rem.editeur === undefined ? "" : rem.editeur.fullName}</span>
                                <small>{rem.dateRedaction=== undefined ? "" : moment(rem.dateRedaction).calendar()}</small>
                            </div></div>
                        <div className="toast-body">{rem.note === undefined ?"" : rem.note}.</div>
                    </div>

                </React.Fragment>
            })}*/}
            </React.Fragment>
        );
    } else return <React.Fragment />;
};

const Commerciaux = (tache) => {
    if (tache.opportuniteParent !== undefined) {
        return (
            <React.Fragment>
                {tache.opportuniteParent.commerciaux.map((commercial, index) => {
                    //return <p key={index}>{JSON.stringify(tache)} </p>

                    return (
                        <React.Fragment key={index}>
                            <img
                                src={
                                    commercial.commercial.photo === undefined
                                        ? avatar
                                        : SERVERUIMAGESUSERS + commercial.commercial.photo.filePath
                                }
                                alt=""
                                className="avatar-xs rounded-circle ml-1"
                                id={`task-avtc-${index}`}
                            />
                            <UncontrolledTooltip placement="bottom" target={`task-avtc-${index}`}>
                                {commercial.commercial === undefined ? '' : commercial.commercial.fullName}
                            </UncontrolledTooltip>
                        </React.Fragment>
                    );
                })}
            </React.Fragment>
        );
    } else {
        return <React.Fragment />;
    }
};

const ScoreOpportunite = (tache) => {
    if (tache.opportuniteParent === undefined || tache.opportuniteParent.tachesAccomplies === undefined) {
        return <React.Fragment></React.Fragment>;
    } else {
        let accomplis = tache.opportuniteParent.tachesAccomplies.length;
        let pipline = tache.opportuniteParent.segment.pipelines.length;
        let score = (accomplis / pipline) * 100;

        return (
            <React.Fragment>
                <Col className="offset-sm-1 ">
                    {score < 30 && <Progress value={score} color="warning" className="progress-sm " />}
                    {score > 30 && score < 100 && <Progress value={score} color="info" className="progress-sm" />}
                    {score === 100 && <Progress value={score} color="success" className="progress-sm" />}
                </Col>
            </React.Fragment>
        );
    }
};

const Tache = (tache) => {
    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody className="pt-0 shadow">
                                    <Row>
                                        <h6
                                            className="p-2 mb-2 rounded med-gradient mb-0"
                                            style={{ color: '#f8f9fa', width: '100%' }}>
                                            Aperçu Rapide
                                        </h6>
                                    </Row>
                                   {/* <Row>
                                        <h6
                                            className="text-muted font-italic fluid mt-1 mr-2"
                                            style={{ width: '100%' }}>
                                            <a href="#" className="text-dark float-right ">
                                                Opportunité N°:{' '}
                                                {tache.opportuniteParent === undefined
                                                    ? ''
                                                    : tache.opportuniteParent.id}
                                                {tache.opportuniteParent === undefined
                                                    ? ''
                                                    : segmentsFormatter(tache.opportuniteParent)}
                                            </a>
                                        </h6>
                                    </Row>
                                    <Row className={'mt-0'}>
                                        <h4 className="float-left text-capitalize mt-0" color={'primary'}>
                                            <i className="uil-briefcase font-size-18 pr-1"></i>
                                            {tache.opportuniteParent === undefined ? '' : tache.opportuniteParent.nom}
                                        </h4>
                                    </Row>

                                    <h6 className="">
                                        Création effectuée par{' '}
                                        {tache.opportuniteParent === undefined
                                            ? ''
                                            : tache.opportuniteParent.owner.commercial.fullName}{' '}
                                    </h6>

                                    <RemarquesOpportunite {...tache} />

                                    <p className="mt-3  mb-1 text-muted">Liste des interveants</p>
                                    <Commerciaux
                                        className={'border-top card-body'}
                                        {...tache}
                                        className="float-right"
                                    />
                                    <Row
                                        className="align-items-center  p-2 mt-2 rounded"
                                        style={{ backgroundColor: '#ebecee' }}>
                                        <Col className="col-sm-auto">
                                            <ul className="list-inline mb-0">
                                                <li className="list-inline-item pr-2">
                                                    <a
                                                        href="/"
                                                        className="text-muted d-inline-block"
                                                        id={`dueDate-${
                                                            tache.opportuniteParent === undefined
                                                                ? ''
                                                                : tache.opportuniteParent.id
                                                        }`}>
                                                        <i className="uil uil-calender mr-1"></i>{' '}
                                                        {moment(
                                                            tache.opportuniteParent === undefined
                                                                ? ''
                                                                : tache.opportuniteParent.startDate
                                                        ).format('L')}
                                                    </a>
                                                    <UncontrolledTooltip
                                                        placement="top"
                                                        target={`dueDate-${
                                                            tache.opportuniteParent === undefined
                                                                ? ''
                                                                : tache.opportuniteParent.id
                                                        }`}>
                                                        Date de création
                                                    </UncontrolledTooltip>
                                                </li>

                                                <li className="list-inline-item">
                                                    <a
                                                        href="/"
                                                        className="text-muted d-inline-block"
                                                        id={`noComments-${
                                                            tache.opportuniteParent === undefined
                                                                ? ''
                                                                : tache.opportuniteParent.id
                                                        }`}>
                                                        <i className="uil uil-comments-alt mr-1"></i>{' '}
                                                        {tache.opportuniteParent === undefined
                                                            ? ''
                                                            : tache.opportuniteParent.nbRemarques}
                                                    </a>
                                                    <UncontrolledTooltip
                                                        placement="top"
                                                        target={`noComments-${
                                                            tache.opportuniteParent === undefined
                                                                ? ''
                                                                : tache.opportuniteParent.id
                                                        }`}>
                                                        Commentaires
                                                    </UncontrolledTooltip>
                                                </li>
                                                <li className="list-inline-item pr-2">
                                                    <a
                                                        href="/"
                                                        className="text-muted d-inline-block"
                                                        id={`noTasks1-${
                                                            tache.opportuniteParent === undefined
                                                                ? ''
                                                                : tache.opportuniteParent.id
                                                        }`}>
                                                        <i className="uil uil-check-square mr-1"></i>{' '}
                                                        {tache.opportuniteParent === undefined
                                                            ? 0
                                                            :  tache.opportuniteParent.tachesAccomplies.length + '/'}
                                                        {tache.opportuniteParent === undefined
                                                            ? ''
                                                            : tache.opportuniteParent.segment.pipelines.length}
                                                    </a>
                                                    <UncontrolledTooltip
                                                        placement="top"
                                                        target={`noTasks1-${
                                                            tache.opportuniteParent === undefined
                                                                ? ''
                                                                : tache.opportuniteParent.id
                                                        }`}>
                                                        Action Effectuées
                                                    </UncontrolledTooltip>
                                                </li>
                                            </ul>
                                        </Col>
                                        <ScoreOpportunite {...tache} />
                                    </Row>*/}
                                </CardBody>
                            </Card>


                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

function remainingTime(tache) {
    if (tache === undefined) {
        return null;
    } else {
        //86400000

        if (moment.duration(moment(`${tache.deadline}`).diff(moment().toDate(), 'milliSeconds')) < 0) {
            if (moment.duration(moment(`${tache.deadline}`).diff(moment().toDate(), 'milliSeconds')) * -1 > 86400000) {
                return `Expiré il y'a: ${moment
                    .duration(moment(`${tache.deadline}`).diff(moment().toDate(), 'milliSeconds') * -1)
                    .format('D [jour], H [heures et] m [min]')}`;
            } else {
                return `Expiré il y'a: ${moment
                    .duration(moment(`${tache.deadline}`).diff(moment().toDate(), 'milliSeconds') * -1)
                    .format('HH:mm:ss')}`;
            }
        } else {
            if (moment.duration(moment(`${tache.deadline}`).diff(moment().toDate(), 'milliSeconds')) > 86400000) {
                return moment
                    .duration(moment(`${tache.deadline}`).diff(moment().toDate(), 'milliSeconds'))
                    .format('D [jour], H [heures et] m [min]');
            } else {
                return moment
                    .duration(moment(`${tache.deadline}`).diff(moment().toDate(), 'milliSeconds'))
                    .format('HH:mm:ss');
            }
        }
    }
}

function segmentsFormatter(tache) {
    if (tache.opportuniteParent === undefined) {
        return null;
    } else {
        return (
            <Badge style={{ backgroundColor: `${tache.opportuniteParent.segment.couleur}`, marginLeft: '20px' }} pill>
                {tache.opportuniteParent.segment.nom}
            </Badge>
        );
    }
}

function villeFormatter(tache) {
    if (tache.opportuniteParent === undefined || tache.opportuniteParent.ville === undefined) {
        return null;
    } else
        return (
            <Badge color={'primary'} style={{ marginLeft: '20px' }} pill className="mr-1">
                {tache.opportuniteParent.ville.ville}
            </Badge>
        );
}

const TacheComponent = (tache) => {
    const hoverHandler = tache.onHoverHandler || {};
    // const clickHandler = tache.onClickHandler || {};
    return (
        <React.Fragment>
            <Row
                id="tache"
                className={classNames('justify-content-sm-between', 'border-bottom', 'mt-2', 'pt-2', 'cursor-pointer')}
                style={{
                    position: 'absoulute',
                    backgroundColor: `${
                        moment.duration(moment(`${tache.deadline}`).diff(moment().toDate(), 'milliSeconds')) < 0 ||
                        tache.retard === true
                            ? 'rgba(255, 92, 117, 0.2)'
                            : ''
                    }`,
                    borderRadius: '5px',
                }}
                onMouseEnter={() => hoverHandler(tache)}>
                <Col lg={6} md={12} className={tache.retard === true ? 'med-bar-gradient-danger' : 'med-bar-gradient'}>
                    <div className="text-dark text-capitalize text-center text-lg-left">
                        <strong>
                            <i>{`${tache.opportuniteParent === undefined ? '' : tache.opportuniteParent.nom} `}</i>
                        </strong>
                    </div>
                </Col>
                <Col lg={6} md={12} className={tache.retard === true ? 'med-bar-gradient-danger' : 'med-bar-gradient'}>
                    <div className=" text-uppercase text-lg-right text-center font-size-11 pt-1 text-dark">
                        <strong>
                            <i>{`${tache.prospect === undefined ? '' : tache.action.nom}`}</i>
                        </strong>
                    </div>
                </Col>

                <Col lg={6} className="mb-lg-0">
                    <div>
                        <div className="float-left  mt-2 ">
                            <img
                                src={
                                    tache.executeur.photo === undefined ||tache.executeur.photo===null
                                        ? avatar
                                        : SERVERUIMAGESUSERS + tache.executeur.photo.filePath
                                }
                                alt=""
                                className="avatar-xs rounded-circle float-left"
                                id={`task-avte-${tache.id}`}
                            />
                            <UncontrolledTooltip placement="bottom" target={`task-avte-${tache.id}`}>
                                Executeur{' '}
                                {tache.executeur.commercial === undefined ? '' : tache.executeur.commercial.fullName}
                            </UncontrolledTooltip>
                            <div className="ml-2 text-capitalize float-left text-muted font-size-11 pt-1">
                                {tache.executeur.fullName} (exécuteur){' '}
                            </div>

                            <Badge style={{ marginLeft: '20px' }} pill color="primary">
                                {tache.opportuniteParent.agence.Nom}
                            </Badge>
                        </div>
                    </div>
                </Col>
                <Col lg={6}>
                    <div className="float-left  mt-2">
                        <div>
                            <img
                                src={
                                    tache.demandeur.photo === undefined ||tache.demandeur.photo===null
                                        ? avatar
                                        : SERVERUIMAGESUSERS + tache.demandeur.photo.filePath
                                }
                                alt=""
                                className="avatar-xs rounded-circle float-left"
                                id={`task-avt-${tache.id}`}
                            />
                            <UncontrolledTooltip placement="bottom" target={`task-avt-${tache.id}`}>
                                Assigné par{' '}
                                {tache.demandeur.commercial === undefined ? '' : tache.demandeur.fullName}
                            </UncontrolledTooltip>

                            <div className="ml-2 text-capitalize float-left text-muted font-size-11 pt-1">
                                {tache.demandeur.fullName} (Demandeur){' '}
                            </div>
                            {tache.prospect === undefined ? '' : segmentsFormatter(tache)}
                        </div>
                    </div>
                </Col>
                <Col lg={12}>
                    <div className="mt-3 mt-sm-0">
                        <ul className="list-inline font-13 text-sm-right float-left">
                            <li className="list-inline-item pr-1 mt-2">
                                <i className="uil uil-calender font-16 mr-1"></i>
                                {tache.startDate === undefined ? '' : moment(tache.startDate).format('L')}
                            </li>

                            <li className="list-inline-item">
                                <span
                                    className={classNames('badge', 'p-1')}
                                    style={{
                                        color: '#fff',
                                        backgroundColor: 'rgb(9 122 10)',
                                    }}>
                                    <i className="uil  uil-map-marker font-size-11 mr-1 icon-xs" size="xs"></i>

                                    {tache.prospect.villeProspect === undefined
                                        ? ''
                                        : tache.prospect.villeProspect.ville}
                                </span>
                            </li>
                        </ul>
                        <ul className="list-inline font-13 text-sm-right float-right">
                            <li className="list-inline-item pr-1 mt-2">
                                <i className="uil uil-clock font-16 mr-1"></i>
                                {tache.deadline === undefined ? '' : remainingTime(tache)}
                                {/*moment.duration(moment(`${tache.deadline}`).diff(moment().toDate(),'milliSeconds')).format("D [jour], H [heures et] m [min]")*/}
                            </li>

                            <li className="list-inline-item pr-2 mt-2">
                                <i className="uil uil-comment-message font-16 mr-1"></i>
                                {tache.remarque === undefined
                                    ? 0
                                    : tache.remarque + (tache.demande === '' ? 0 : 1)}
                            </li>

                            {tache.retard === true ? (
                                <li className="list-inline-item mt-2">
                                    <span
                                        className={classNames('badge', 'p-1')}
                                        style={{
                                            color: tache.priorite === undefined ? '' : 'rgb(255,255,255)',
                                            backgroundColor: 'rgb(139,0,0)',
                                        }}>
                                        {tache.priorite === undefined ? '' : 'Retard'}
                                    </span>
                                </li>
                            ) : (
                                ''
                            )}
                            <li className="list-inline-item mt-2">
                                <span
                                    className={classNames('badge', 'p-1')}
                                    style={{
                                        color: tache.priorite === undefined ? '' : tache.priorite.couleur,
                                        backgroundColor:
                                            tache.priorite === undefined
                                                ? '#ccc'
                                                : hexToRgbA(tache.priorite === undefined ? '' : tache.priorite.couleur),
                                    }}>
                                    {tache.priorite === undefined ? '' : tache.priorite.nom}
                                </span>
                            </li>
                        </ul>

                    </div>
                </Col>
                {
                    tache.opportuniteParent.lastRemarque === ""
                            ? ""
                            :
                        <div lg={12}
                             style={{ backgroundColor: '#fff',
                                 marginBottom:'10px',
                                 marginLeft:'10px',
                                 marginRight:'10px' ,
                                 width:'100%',
                                 zIndex:'999959',
                                 paddingLeft:'10px',
                                 paddingRight:'10px',
                                 color:"#000",
                                 opacity:'0.9'
                             }}>
                            <li className="list-inline-item pr-2 mt-2 mb-2">
                                <i className="uil uil-comment-message font-16 mr-1 ">
                                    {"  "+tache.opportuniteParent.lastRemarque}
                                </i>

                            </li>

                        </div>
                }

            </Row>
        </React.Fragment>
    );
};

function hexToRgbA(hex) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length == 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',0.2)';
    }
    throw new Error('Bad Hex');
}
function Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

class TachesEnCours extends React.Component {
    constructor(props) {
        super(props);

        this.togglePanel = this.togglePanel.bind(this);
        this.selectTask = this.selectTask.bind(this);
        this.selectTache = this.selectTache.bind(this);
        this.selectOpportunite = this.selectOpportunite.bind(this);
        this.performTache = this.performTache.bind(this);
        this.resetStates = this.resetStates.bind(this);

        this.search = this.search.bind(this);

        this.state = {
            opportunites: [{}],
            todayOpportunites: [{}],
            incomingOpportunites: [{}],
            othersOpportunites: [{}],
            todayTaskCollapse: true,
            expiredTaskCollapse: true,
            upcomingTaskCollapse: true,
            otherTaskCollapse: true,
            selectedTask: todayTasks[0],
            selectedOpportunite: null,
            selectedTache: null,
            compteurExpiredTache: 0,
            compteurTache: 0,
            panel1: false,
            panel2: false,
            panel3: false,
            nbRetard: 0,
            nbReport: 0,
            nbUrgente: 0,
            nbNormal: 0,
            nbEncours: 0,
        };
        // this.getData = this.getData.bind(this);
        this.handleTacheRetard = this.handleTacheRetard.bind(this);
        this.togglePanel = this.togglePanel.bind(this);
        this.customInputSwitched = this.customInputSwitched.bind(this);

        this.searchByFilter = this.searchByFilter.bind(this);
        this.customInputSwitchedEtat = this.customInputSwitchedEtat.bind(this);

        /* this.getTodayData = this.getTodayData.bind(this);
        this.getIncomingData = this.getIncomingData.bind(this);
        */
    }
    resetStates(){
        this.setState({
            taches: undefined,
            result: undefined,
        },function () {

        });
    }
    searchByFilter(e) {
        if(this.state.taches!==undefined)
        {
            this.setState(
                (prevState) => ({
                    // object that we want to update
                    ...prevState.keyword, // keep all other key-value pairs
                    keyword: e,
                }),
                function () {
                    let keyword;
                    if (this.state.keyword === undefined) {
                        keyword = '';
                    } else {
                        keyword = this.state.keyword.trim();
                    }

                    let result = this.state.taches === undefined ? [] :

                    this.state.taches.filter((tache) => tache.action.id !== 2)

                    let references= this.state.taches.filter((tache) => tache.action.id === 2)
                    {
                        this.setState({
                            references:references.length
                        })
                    }

                    //result.filter((tache) => tache.retard !== this.state.enCours&&tache.accompli===false);
                    let searchByKey;



                    let searchByEnCours = [];
                    if (this.state.enCours === true) {
                        searchByEnCours = result.filter((tache) => tache.retard !== this.state.enCours&&tache.accompli===false);
                    }



                    let searchByRetard = [];
                    if (this.state.retard === true) {
                        searchByRetard = result.filter((tache) => tache.retard === this.state.retard&&tache.accompli===false);
                    }
                    let searchBySegment = [];

                    if (this.state.segmentCibles !== undefined) {
                        this.state.segmentCibles.map((segment) => {
                            if (
                                this.state['segment' + `${segment.nom}` + `${segment.id}`] !== undefined &&
                                this.state['segment' + `${segment.nom}` + `${segment.id}`]
                            ) {
                                searchBySegment = searchBySegment.concat(
                                    result.filter((tache) => tache.opportuniteParent.segment.id === segment.id)
                                );
                            }
                        });
                        searchBySegment = _.uniqBy(searchBySegment, 'id');
                    }
                    let searchByAgence = [];

                    if (this.state.agenceCibles !== undefined) {
                        this.state.agenceCibles.map((agence) => {
                            if (
                                this.state['agence' + `${agence.nom}` + `${agence.id}`] !== undefined &&
                                this.state['agence' + `${agence.nom}` + `${agence.id}`]
                            ) {
                                searchByAgence = searchByAgence.concat(
                                    result.filter((tache) => tache.opportuniteParent.agence.id === agence.id)
                                );
                            }
                        });
                        searchByAgence = _.uniqBy(searchByAgence, 'id');
                    }
                    let searchByVille = [];

                    if (this.state.villeCibles !== undefined) {
                        this.state.villeCibles.map((ville) => {
                            if (
                                this.state['ville' + `${ville.nom}` + `${ville.id}`] !== undefined &&
                                this.state['ville' + `${ville.nom}` + `${ville.id}`]
                            ) {
                                searchByVille = searchByVille.concat(
                                    result.filter((tache) => tache.prospect.villeProspect.id === ville.id)
                                );
                            }
                        });

                        searchByVille = _.uniqBy(searchByVille, 'id');

                    }
                    let searchByAction = [];

                    if (this.state.actionCibles !== undefined) {
                        this.state.actionCibles.map((action) => {
                            if (
                                this.state['action' + `${action.nom}` + `${action.id}`] !== undefined &&
                                this.state['action' + `${action.nom}` + `${action.id}`]
                            ) {
                                searchByAction = searchByAction.concat(
                                    result.filter((tache) => tache.action.id === action.id)
                                );
                            }
                        });

                        searchByAction = _.uniqBy(searchByAction, 'id');

                    }

                    let searchByExecuteur = [];

                    if (this.state.executeurCibles !== undefined) {
                        this.state.executeurCibles.map((exec) => {
                            if (
                                this.state['executeur' + `${exec.nom}` + `${exec.id}`] !== undefined &&
                                this.state['executeur' + `${exec.nom}` + `${exec.id}`]
                            ) {
                                searchByExecuteur = searchByExecuteur.concat(
                                    result.filter((tache) => tache.executeur.id === exec.id)
                                );
                            }
                        });

                        searchByExecuteur = _.uniqBy(searchByExecuteur, 'id');

                    }

                    let searchByDemandeur = [];

                    if (this.state.demandeurCibles !== undefined) {
                        this.state.demandeurCibles.map((dem) => {
                            if (
                                this.state['demandeur' + `${dem.nom}` + `${dem.id}`] !== undefined &&
                                this.state['demandeur' + `${dem.nom}` + `${dem.id}`]
                            ) {
                                searchByDemandeur = searchByDemandeur.concat(
                                    result.filter((tache) => tache.demandeur.id === dem.id)
                                );
                            }
                        });

                        searchByDemandeur = _.uniqBy(searchByDemandeur, 'id');

                    }

                    let searchByAvancement = _.union(searchByEnCours, searchByRetard);

                    let searchByUrgente = [];
                    if (this.state.urgente === true) {
                        searchByUrgente = result.filter((tache) => !tache.priorite.nom.includes('Normal'));
                    }
                    let searchByNormal = [];
                    if (this.state.normal === true) {
                        searchByNormal = result.filter((tache) => tache.priorite.nom.includes('Normal'));
                    }
                    let searchByUrgence = _.union(searchByUrgente, searchByNormal);

                    let searchByReport = result;


                    if (this.state.report !== true) {
                        searchByReport = result.filter((tache) => tache.report === this.state.report);
                    }

                    if (keyword === undefined || keyword === null || keyword.length < 1) {
                        searchByKey = result;


                    } else {
                        searchByKey = result.filter(
                            (tache) =>
                                tache.prospect.fullName&&
                                tache.prospect.fullName.toLowerCase().includes(this.state.keyword.toLowerCase()) === true ||
                                tache.prospect.tel1&&
                                tache.prospect.tel1.toLowerCase().includes(this.state.keyword.toLowerCase()) === true||
                                tache.prospect.tel2&&
                                tache.prospect.tel2.toLowerCase().includes(this.state.keyword.toLowerCase()) === true||
                                tache.prospect.email&&
                                tache.prospect.email.toLowerCase().includes(this.state.keyword.toLowerCase()) === true||
                                tache.refCompteur&&
                                tache.refCompteur.toLowerCase().includes(this.state.keyword.toLowerCase()) === true
                        );


                    }

                    result = _.intersection(
                        result,
                        searchByKey,
                        searchBySegment,
                        searchByAgence,
                        searchByAvancement,
                        searchByAction,
                        searchByReport,
                        searchByUrgence,
                        searchByVille,
                        searchByExecuteur,
                        searchByDemandeur
                    );

                    let nbEnCours = 0;
                    let nbRetard = 0;
                    let nbUrgente = 0;
                    let nbNormal = 0;
                    let nbReport = 0;
                    result.map((res) => {
                        if (res.retard) {
                            nbRetard++;
                        } else {
                            nbEnCours++;
                        }
                        if (res.report) {
                            nbReport++;
                        }
                        if (res.priorite.nom.includes('Normal')) {
                            nbNormal++;
                        } else {
                            nbUrgente++;
                        }
                        this.state.segmentCibles.map((segment) => {
                            this.setState(
                                {
                                    ['nb' + `${Capitalize(segment.nom)}` + `${segment.id}`]: result.filter(
                                        (tache) => tache.opportuniteParent.segment.id === segment.id
                                    ).length,
                                },
                                function () {

                                }
                            );
                        });
                        this.state.villeCibles.map((ville) => {
                            this.setState(
                                {
                                    ['nb' + `${Capitalize(ville.nom)}` + `${ville.id}`]: result.filter(
                                        (tache) => tache.prospect.villeProspect.id === ville.id
                                    ).length,
                                },
                                function () {

                                }
                            );
                        });
                        this.state.agenceCibles.map((agence) => {
                            this.setState(
                                {
                                    ['nb' + `${Capitalize(agence.nom)}` + `${agence.id}`]: result.filter(
                                        (tache) => tache.opportuniteParent.agence.id === agence.id
                                    ).length,
                                },
                                function () {

                                }
                            );
                        });
                        this.state.actionCibles.map((action) => {
                            this.setState(
                                {
                                    ['nb' + `${Capitalize(action.nom)}` + `${action.id}`]: result.filter(
                                        (tache) => tache.action.id === action.id
                                    ).length,
                                },
                                function () {

                                }
                            );
                        });
                        this.state.executeurCibles.map((exec) => {
                            this.setState(
                                {
                                    ['nbe' + `${Capitalize(exec.nom)}` + `${exec.id}`]: result.filter(
                                        (tache) => tache.executeur.id === exec.id
                                    ).length,
                                },
                                function () {

                                }
                            );
                        });
                        this.state.demandeurCibles.map((demandeur) => {
                            this.setState(
                                {
                                    ['nbd' + `${Capitalize(demandeur.nom)}` + `${demandeur.id}`]: result.filter(
                                        (tache) => tache.demandeur.id === demandeur.id
                                    ).length,
                                },
                                function () {

                                }
                            );
                        });
                    });
                    this.setState({
                        result: result,
                        nbEnCours: nbEnCours,
                        nbRetard: nbRetard,
                        nbReport: nbReport,
                        nbUrgente: nbUrgente,
                        nbNormal: nbNormal,
                    });
                }
            );
        }

       //this.setState({keyword:e})
    }
    customInputSwitchedEtat(e) {
        this.setState(
            {
                [`${e.target.name}`]: e.target.checked,
            },
            function () {

            }
        );
        this.searchByFilter(this.state.keyword);
    }
    customInputSwitched(e, obj, index) {
        let chk=e.target.checked
        this.setState(
            {
                [`${e.target.name}` + `${obj.nom}` + `${obj.id}`]: chk,
            },
            function () {


            }
        );
        this.searchByFilter(this.state.keyword);
    }
    togglePanel(panel) {
        var state = { ...this.state };
        state[panel] = !state[panel];
        this.setState(state);
    }
    search(e) {
        let result = this.state.taches.filter((tache) => tache.prospect.fullName.includes(e) === true);
        this.setState({
            result: result,
            keyword: e,
        });
    }
    handleTacheRetard(tache) {
        if ((tache.retard !== true && tache.accompli !== true)&&moment.duration(moment(`${tache.deadline}`).diff(moment().toDate(), 'milliSeconds')) < 0 ) {
            let data = {
                id: tache.id,
                retard: true,
            };
            this.props.updateTache(data);
        }
    }
    getTachesFiltre(role, idCommercial, idAgence, dateDebut, DateFin) {
        this.props.getTaches(role, idCommercial, idAgence, dateDebut, DateFin);
        this.setState({ role, idCommercial, idAgence, dateDebut, DateFin });
    }
    getAsyncData = async (actions) => {
        return new Promise((resolve) => {
            resolve(actions);
        });
    };
    componentDidMount() {
        moment.locale('fr');
      //  this.props.getTaches('mesTaches', getLoggedInUser().id, '', '', '');
        this.props.getTachesByExecuteur(getLoggedInUser().id, false,false);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
         if (prevProps.taches1 !== this.props.taches1 && this.props.taches1 !==undefined) {
            this.getAsyncData(this.props.taches1).then((taches) => {

              let result = this.searchByFilter();
                this.setState({
                    taches: taches,
                    result: result,
                },function () {

                });
                let compt = 0;
                let segmentCibles = [];
                let villeCibles = [];
                let executeurCibles = [];
                let demandeurCibles = [];
                let agenceCibles = [];
                let actionCibles = [];
                let retard = false;
                let report = false;
                let urgente = false;
                let normal = false;
                let enCours = false;
                taches.map((tache, index) => {
                    if (tache.accompli !== true && tache.annulee !== true) {
                        this.handleTacheRetard(tache)
                        segmentCibles.push({
                            id: tache.opportuniteParent.segment.id,
                            nom: tache.opportuniteParent.segment.nom,
                        });
                        villeCibles.push({ id: tache.prospect.villeProspect.id, nom: tache.prospect.villeProspect.ville });
                        executeurCibles.push({
                            id: tache.executeur.id,
                            nom: tache.executeur.fullName,
                            photo: tache.executeur.photo!==undefined&&tache.executeur.photo!==null?tache.executeur.photo.filePath:undefined,
                        });
                        demandeurCibles.push({
                            id: tache.demandeur.id,
                            nom: tache.demandeur.fullName,
                            photo: tache.demandeur.photo!==undefined&&tache.demandeur.photo!==null?tache.demandeur.photo.filePath:undefined,
                        });
                        agenceCibles.push({
                            id: tache.opportuniteParent.agence.id,
                            nom: tache.opportuniteParent.agence.Nom,
                        });
                        actionCibles.push({ id: tache.action.id, nom: tache.action.nom });
                        if (
                            tache.retard === true ||
                            moment.duration(moment(`${tache.deadline}`).diff(moment().toDate(), 'milliSeconds'))
                        ) {
                            retard = true;
                        }
                        if (!tache.retard) {
                            enCours = true;
                        }

                        if (tache.report) {
                            report = true;
                        }
                        if (!tache.report) {
                            report = false;
                        }
                        if (tache.priorite.nom.includes('Normal')) {
                            normal = true;
                        } else {
                            urgente = true;
                        }
                        compt = compt + 1;
                        this.setState({
                            compteurTache: compt,
                        });
                    }
                });
                segmentCibles = _.uniqBy(segmentCibles, 'id');
                villeCibles = _.uniqBy(villeCibles, 'id');
                villeCibles = _.orderBy(villeCibles, ['nom'], ['asc']);
                executeurCibles = _.uniqBy(executeurCibles, 'id');
                executeurCibles = _.orderBy(executeurCibles, ['nom'], ['asc']);
                demandeurCibles = _.uniqBy(demandeurCibles, 'id');
                demandeurCibles = _.orderBy(demandeurCibles, ['nom'], ['asc']);
                agenceCibles = _.uniqBy(agenceCibles, 'id');
                agenceCibles = _.orderBy(agenceCibles, ['nom'], ['asc']);
                actionCibles = _.uniqBy(actionCibles, 'id');
                actionCibles = _.orderBy(actionCibles, ['nom'], ['asc']);
                this.setState({
                    segmentCibles: segmentCibles,
                    villeCibles: villeCibles,
                    executeurCibles: executeurCibles,
                    demandeurCibles: demandeurCibles,
                    agenceCibles: agenceCibles,
                    actionCibles: actionCibles,
                    report: true,
                    urgente: urgente,
                    retard: retard,
                    normal: normal,
                    enCours: enCours,
                });
                villeCibles.map((ville) => {
                    this.setState(
                        {
                            ['nb' + `${Capitalize(ville.nom)}` + `${ville.id}`]: 0,
                            ['ville' + `${ville.nom}` + `${ville.id}`]: true,
                        },
                        function () {

                        }
                    );
                });
                segmentCibles.map((seg) => {
                    this.setState(
                        {
                            ['nb' + `${Capitalize(seg.nom)}` + `${seg.id}`]: 0,
                            ['segment' + `${seg.nom}` + `${seg.id}`]: true,
                        },
                        function () {

                        }
                    );
                });
                agenceCibles.map((agence) => {
                    this.setState(
                        {
                            ['nb' + `${Capitalize(agence.nom)}` + `${agence.id}`]: 0,
                            ['agence' + `${agence.nom}` + `${agence.id}`]: true,
                        },
                        function () {

                        }
                    );
                });
                actionCibles.map((action) => {
                    this.setState(
                        {
                            ['nb' + `${Capitalize(action.nom)}` + `${action.id}`]: 0,
                            ['action' + `${action.nom}` + `${action.id}`]: true,
                        },
                        function () {

                        }
                    );
                });
                executeurCibles.map((exec) => {
                    this.setState(
                        {
                            ['nbe' + `${Capitalize(exec.nom)}` + `${exec.id}`]: 0,
                            ['executeur' + `${exec.nom}` + `${exec.id}`]: true,
                        },
                        function () {

                        }
                    );
                });
                demandeurCibles.map((demandeur) => {
                    this.setState(
                        {
                            ['nbd' + `${Capitalize(demandeur.nom)}` + `${demandeur.id}`]: 0,
                            ['demandeur' + `${demandeur.nom}` + `${demandeur.id}`]: true,
                        },
                        function () {

                        }
                    );
                });
                this.searchByFilter();

            });
        }

        if (prevProps.segments != this.props.segments) {
        }


    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    /**
     * Toggle panel
     */
    togglePanel(panel) {
        var state = { ...this.state };
        state[panel] = !state[panel];
        this.setState(state);
    }

    /**
     * Selects the task
     * @param {*} task
     */
    selectTask(task) {
        this.setState({ selectedTask: task });
    }

    selectTache(tache) {
        this.setState({ selectedTache: tache });
    }

    selectOpportunite(opportunite) {
        this.setState({ selectedOpportunite: opportunite });
    }
    performTache(tache) {

    }

    render() {
        return this.state.executeurCibles === undefined ||
            this.state.demandeurCibles === undefined ||
            this.state.agenceCibles === undefined ||
            this.state.segmentCibles === undefined ||
            this.state.villeCibles === undefined ||
            this.state.taches === undefined ||
            this.state.references===undefined||
            this.props.taches1 === undefined ? (
            <Loader></Loader>
        ) : (
            <React.Fragment>
                <Row className="page-title pb-3">
                    <Col md={12}>
                        <PageTitle
                            breadCrumbItems={[
                                { label: 'Prospects', path: '#' },
                                { label: 'Liste des prospects', path: '/opportunites/taches', active: true },
                            ]}
                            title={'Liste des tâches en cours'}
                        />
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col md={12} xl={6} className="text-md-left">
                        <div className="mt-4 mt-md-0">
                            {/*<button type="button" className="btn btn-danger mr-4 mb-3  mb-sm-0"><i className="uil-plus mr-1"></i> Create Project</button>*/}


                            <div className="btn-group ml-1">



                                {
                                    getLoggedInUser().role==="ROLE_SUPERADMIN"||getLoggedInUser().role==="ROLE_ADMIN"||getLoggedInUser().role==="ROLE_COORDINATEUR"||getLoggedInUser().role==="ROLE_MARKETEUR"||getLoggedInUser().agenceId===getLoggedInUser().chef||getLoggedInUser().agenceId===getLoggedInUser().coordinateur?
                                        <React.Fragment>
                                            <button type="button"
                                                    className={this.state.filtre==="mesProspects"?"btn btn-primary":"btn btn-white"}
                                                    onClick={(event) => {
                                                        this.resetStates()
                                                        this.props.getTachesByExecuteur(getLoggedInUser().id, false,false);

                                                    }}
                                            >Mes Tâches</button>
                                            <button type="button" className={this.state.filtre==="prospectsAgence"?"btn btn-primary":"btn btn-white"}
                                                    onClick={(event) => {
                                                        this.resetStates()
                                                        this.props.getTachesByDemandeur(getLoggedInUser().id, false,false);



                                                        //this.getTachesFiltre('tachesAgence',"","","");


                                                    }}
                                            >Mes affectations</button>
                                            <button type="button" className={this.state.filtre==="prospectsAgence"?"btn btn-primary":"btn btn-white"}
                                                    onClick={(event) => {
                                                        this.resetStates()
                                                        this.props.getTachesByAgence(getLoggedInUser().agenceId, false,false);



                                                        //this.getTachesFiltre('tachesAgence',"","","");


                                                    }}
                                            >Tâches de l'agence</button>
                                        </React.Fragment>

                                        :""
                                }
                                {
                                    getLoggedInUser().role==="ROLE_SUPERADMIN"||getLoggedInUser().role==="ROLE_ADMIN"||getLoggedInUser().role==="ROLE_COORDINATEUR"||getLoggedInUser().role==="ROLE_MARKETEUR"?
                                        <button type="button"
                                                className={this.state.filtre===""?"btn btn-primary":"btn btn-white"}
                                                onClick={(event) => {
                                                    this.resetStates()
                                                    //this.getTachesFiltre('all',"","","","");
                                                    this.props.getAllTaches(false,false);

                                                    //
                                                }} >Toutes les tâches</button>

                                        :""
                                }


                            </div>
                            {/*  <div className="btn-group ml-2 d-none d-sm-inline-block">
                                <button type="button" className="btn btn-primary btn-sm"><i
                                    className="uil uil-apps"></i></button>
                            </div>*/}
                            {/*<div className="btn-group d-none d-sm-inline-block ml-1">
                                <button type="button" className="btn btn-white btn-sm"><i
                                    className="uil uil-align-left-justify"></i></button>
                            </div>*/}
                        </div>
                    </Col>
                    <Col md={12} xl={6} className="text-md-right">

                        {
                            getLoggedInUser().role==="ROLE_SUPERADMIN"||getLoggedInUser().role==="ROLE_ADMIN"||getLoggedInUser().role==="ROLE_MARKETEUR"||getLoggedInUser().agenceId===getLoggedInUser().chef||getLoggedInUser().agenceId===getLoggedInUser().coordinateur?
                                <Button
                                    className="btn-soft-primary mt-1 mb-1"
                                    id="group1"
                                    href="references"
                                    onClick={() => {
                                        this.togglePanel('panel2');
                                    }}>

                                    <i className="uil uil-ticket mr-1 "> </i>liste des réferences
                                    <Badge pill color={"danger"} className="ml-2 font-size-11">
                                        {this.state.references}
                                    </Badge>
                                </Button>:""
                        }

                    </Col>
                </Row>

                <React.Fragment>
                    <Row className="">
                        <Col md={12} xl={12} className="text-md-left">
                            <React.Fragment>
                                <Card>
                                    <CardBody className="pb-0">
                                        <div id="accordion" className="accordion custom-accordionwitharrow">
                                            <Card className="shadow-none ">
                                                <Row>
                                                    <Col lg={10} md={8} sm={8} xs={12}>
                                                        <input
                                                            type="text"
                                                            name="searchInput"
                                                            className="form-control  mt-1 mb-1 float-left "
                                                            placeholder="Tél, email, chargé commercial.."
                                                            onChange={(e) => this.searchByFilter(e.target.value)}
                                                        />
                                                    </Col>
                                                    <Col lg={2} md={4} sm={4} xs={12}>
                                                        <Button
                                                            className="btn-block btn btn-success mt-1 mb-1"
                                                            id="group1"
                                                            href="#"
                                                            onClick={() => {
                                                                this.togglePanel('panel1');
                                                            }}>
                                                            {this.state.panel1 && (
                                                                <i className="uil uil-angle-down float-right accordion-arrow "></i>
                                                            )}
                                                            {!this.state.panel1 && (
                                                                <i className="uil uil-angle-up float-right accordion-arrow"></i>
                                                            )}
                                                            <i className="uil uil-flask "> </i>Filtres
                                                        </Button>
                                                    </Col>
                                                </Row>

                                                <Collapse isOpen={this.state.panel1}>
                                                    <CardBody>
                                                        <Row>
                                                            <Col lg={12}>
                                                                <FormGroup className={'filtre'}>
                                                                    <Label
                                                                        for="exampleCheckbox"
                                                                        className="med-gradient pl-2 "
                                                                        style={{ color: '#fff', width: '100%' }}>
                                                                        Actions
                                                                    </Label>
                                                                    <div>
                                                                        {this.state.actionCibles.map(
                                                                            (action, index) => {
                                                                                return (
                                                                                    <CustomInput
                                                                                        key={index}
                                                                                        type="checkbox"
                                                                                        name={'action'}
                                                                                        id={action.id}
                                                                                        label={
                                                                                            action.nom +
                                                                                            ' (' +
                                                                                            this.state[
                                                                                                'nb' +
                                                                                                    `${Capitalize(
                                                                                                        action.nom
                                                                                                    )}` +
                                                                                                    `${action.id}`
                                                                                            ] +
                                                                                            ')'
                                                                                        }
                                                                                        inline
                                                                                        onChange={(e) =>
                                                                                            this.customInputSwitched(
                                                                                                e,
                                                                                                action,
                                                                                                index
                                                                                            )
                                                                                        }
                                                                                        checked={
                                                                                            this.state[
                                                                                                'action' +
                                                                                                    `${action.nom}` +
                                                                                                    `${action.id}`
                                                                                            ]
                                                                                        }
                                                                                    />
                                                                                );
                                                                            }
                                                                        )}
                                                                    </div>
                                                                </FormGroup>
                                                            </Col>

                                                            <Col lg={4}>
                                                                <FormGroup className={'filtre'}>
                                                                    <Label
                                                                        for="exampleCheckbox "
                                                                        className="med-gradient  pl-2 "
                                                                        style={{ color: '#fff', width: '100%' }}>
                                                                        Avancement
                                                                    </Label>
                                                                    <div>
                                                                        <CustomInput
                                                                            type="checkbox"
                                                                            id="enCours"
                                                                            name="enCours"
                                                                            label={
                                                                                'En Cours (' +
                                                                                this.state.nbEnCours +
                                                                                ')'
                                                                            }
                                                                            inline
                                                                            onChange={(e) =>
                                                                                this.customInputSwitchedEtat(e)
                                                                            }
                                                                            checked={this.state.enCours}
                                                                        />
                                                                        <CustomInput
                                                                            type="checkbox"
                                                                            id="retard"
                                                                            name="retard"
                                                                            label={
                                                                                'Expirées (' + this.state.nbRetard + ')'
                                                                            }
                                                                            inline
                                                                            onChange={(e) =>
                                                                                this.customInputSwitchedEtat(e)
                                                                            }
                                                                            checked={this.state.retard}
                                                                        />
                                                                    </div>
                                                                </FormGroup>
                                                                <FormGroup className={'filtre'}>
                                                                    <Label
                                                                        for="exampleCheckbox"
                                                                        className="med-gradient  pl-2"
                                                                        style={{ color: '#fff', width: '100%' }}>
                                                                        Urgence
                                                                    </Label>
                                                                    <div>
                                                                        <CustomInput
                                                                            type="checkbox"
                                                                            id="report"
                                                                            name="report"
                                                                            label={
                                                                                'Reportées (' +
                                                                                this.state.nbReport +
                                                                                ')'
                                                                            }
                                                                            inline
                                                                            onChange={(e) =>
                                                                                this.customInputSwitchedEtat(e)
                                                                            }
                                                                            checked={this.state.report}
                                                                        />

                                                                        <CustomInput
                                                                            type="checkbox"
                                                                            id="normal"
                                                                            name="normal"
                                                                            label={
                                                                                'Normales (' + this.state.nbNormal + ')'
                                                                            }
                                                                            inline
                                                                            onChange={(e) =>
                                                                                this.customInputSwitchedEtat(e)
                                                                            }
                                                                            checked={this.state.normal}
                                                                        />
                                                                        <CustomInput
                                                                            type="checkbox"
                                                                            id="urgente"
                                                                            name="urgente"
                                                                            label={
                                                                                'Urgentes (' +
                                                                                this.state.nbUrgente +
                                                                                ')'
                                                                            }
                                                                            inline
                                                                            onChange={(e) =>
                                                                                this.customInputSwitchedEtat(e)
                                                                            }
                                                                            checked={this.state.urgente}
                                                                        />
                                                                    </div>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg={4}>
                                                                <FormGroup className={'filtre'}>
                                                                    <Label
                                                                        for="exampleCheckbox"
                                                                        className="med-gradient  pl-2"
                                                                        style={{ color: '#fff', width: '100%' }}>
                                                                        Segments
                                                                    </Label>
                                                                    <div>
                                                                        {this.state.segmentCibles.map(
                                                                            (segment, index) => {
                                                                                return (
                                                                                    <CustomInput
                                                                                        key={index}
                                                                                        type="checkbox"
                                                                                        name="segment"
                                                                                        id={segment.id}
                                                                                        label={
                                                                                            segment.nom +
                                                                                            ' (' +
                                                                                            this.state[
                                                                                                'nb' +
                                                                                                    `${Capitalize(
                                                                                                        segment.nom
                                                                                                    )}` +
                                                                                                    `${segment.id}`
                                                                                            ] +
                                                                                            ')'
                                                                                        }
                                                                                        inline
                                                                                        onChange={(e) =>
                                                                                            this.customInputSwitched(
                                                                                                e,
                                                                                                segment,
                                                                                                index
                                                                                            )
                                                                                        }
                                                                                        checked={
                                                                                            this.state[
                                                                                                'segment' +
                                                                                                    `${segment.nom}` +
                                                                                                    `${segment.id}`
                                                                                            ]
                                                                                        }
                                                                                    />
                                                                                );
                                                                            }
                                                                        )}
                                                                    </div>
                                                                </FormGroup>
                                                                <FormGroup className={'filtre'}>
                                                                    <Label
                                                                        for="agenceCheckbox"
                                                                        className="med-gradient  pl-2"
                                                                        style={{ color: '#fff', width: '100%' }}>
                                                                        Agences
                                                                    </Label>
                                                                    <div>
                                                                        {this.state.agenceCibles.map(
                                                                            (agence, index) => {
                                                                                return (
                                                                                    <CustomInput
                                                                                        key={index}
                                                                                        type="checkbox"
                                                                                        name="agence"
                                                                                        id={agence.id}
                                                                                        label={
                                                                                            agence.nom +
                                                                                            ' (' +
                                                                                            this.state[
                                                                                                'nb' +
                                                                                                    `${Capitalize(
                                                                                                        agence.nom
                                                                                                    )}` +
                                                                                                    `${agence.id}`
                                                                                            ] +
                                                                                            ')'
                                                                                        }
                                                                                        inline
                                                                                        onChange={(e) =>
                                                                                            this.customInputSwitched(
                                                                                                e,
                                                                                                agence,
                                                                                                index
                                                                                            )
                                                                                        }
                                                                                        checked={
                                                                                            this.state[
                                                                                                'agence' +
                                                                                                    `${agence.nom}` +
                                                                                                    `${agence.id}`
                                                                                            ]
                                                                                        }
                                                                                    />
                                                                                );
                                                                            }
                                                                        )}
                                                                    </div>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg={4}>
                                                                <FormGroup className={'filtre'}>
                                                                    <Label
                                                                        for="exampleCheckbox"
                                                                        className="med-gradient  pl-2"
                                                                        style={{ color: '#fff', width: '100%' }}>
                                                                        Villes
                                                                    </Label>
                                                                    <div>
                                                                        {this.state.villeCibles.map((ville, index) => {
                                                                            return (
                                                                                <CustomInput
                                                                                    key={index}
                                                                                    type="checkbox"
                                                                                    name={'ville'}
                                                                                    id={ville.id}
                                                                                    label={
                                                                                        ville.nom +
                                                                                        ' (' +
                                                                                        this.state[
                                                                                            'nb' +
                                                                                                `${Capitalize(
                                                                                                    ville.nom
                                                                                                )}` +
                                                                                                `${ville.id}`
                                                                                        ] +
                                                                                        ')'
                                                                                    }
                                                                                    inline
                                                                                    onChange={(e) =>
                                                                                        this.customInputSwitched(
                                                                                            e,
                                                                                            ville,
                                                                                            index
                                                                                        )
                                                                                    }
                                                                                    checked={
                                                                                        this.state[
                                                                                            'ville' +
                                                                                                `${ville.nom}` +
                                                                                                `${ville.id}`
                                                                                        ]
                                                                                    }
                                                                                />
                                                                            );
                                                                        })}
                                                                    </div>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={6}>
                                                                <FormGroup className={'filtre'}>
                                                                    <Label
                                                                        for="exampleCheckbox"
                                                                        className="med-gradient  pl-2"
                                                                        style={{ color: '#fff', width: '100%' }}>
                                                                        Executeurs
                                                                    </Label>
                                                                    <div>
                                                                        {this.state.executeurCibles.map(
                                                                            (exec, index) => {
                                                                                return (

                                                                                        <CustomInput
                                                                                            key={index}
                                                                                            type="checkbox"
                                                                                            name={'executeur'}
                                                                                            id={exec.id}
                                                                                            inline
                                                                                            onChange={(e) =>
                                                                                                this.customInputSwitched(
                                                                                                    e,
                                                                                                    exec,
                                                                                                    index
                                                                                                )
                                                                                            }
                                                                                            checked={
                                                                                                this.state[
                                                                                                    'executeur' +
                                                                                                        `${exec.nom}` +
                                                                                                        `${exec.id}`
                                                                                                ]
                                                                                            }>
                                                                                            <img
                                                                                                src={
                                                                                                    exec.photo ===
                                                                                                    undefined
                                                                                                        ? avatar
                                                                                                        : SERVERUIMAGESUSERS +
                                                                                                          exec.photo
                                                                                                }
                                                                                                alt=""
                                                                                                className="avatar-xs rounded-circle mr-2 "
                                                                                            />
                                                                                            {exec.nom +
                                                                                                ' (' +
                                                                                                this.state[
                                                                                                    'nbe' +
                                                                                                        `${Capitalize(
                                                                                                            exec.nom
                                                                                                        )}` +
                                                                                                        `${exec.id}`
                                                                                                ] +
                                                                                                ')'}
                                                                                        </CustomInput>

                                                                                );
                                                                            }
                                                                        )}
                                                                    </div>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg={6}>
                                                                <FormGroup className={'filtre'}>
                                                                    <Label
                                                                        for="exampleCheckbox"
                                                                        className="med-gradient  pl-2"
                                                                        style={{ color: '#fff', width: '100%' }}>
                                                                        Demandeurs
                                                                    </Label>
                                                                    <div>
                                                                        {this.state.demandeurCibles.map(
                                                                            (demandeur, index) => {
                                                                                return (

                                                                                        <CustomInput
                                                                                            key={index}
                                                                                            type="checkbox"
                                                                                            name={'demandeur'}
                                                                                            id={demandeur.id}
                                                                                            inline
                                                                                            onChange={(e) =>
                                                                                                this.customInputSwitched(
                                                                                                    e,
                                                                                                    demandeur,
                                                                                                    index
                                                                                                )
                                                                                            }
                                                                                            checked={
                                                                                                this.state[
                                                                                                    'demandeur' +
                                                                                                        `${demandeur.nom}` +
                                                                                                        `${demandeur.id}`
                                                                                                ]
                                                                                            }>
                                                                                            <img
                                                                                                src={
                                                                                                    demandeur.photo ===
                                                                                                    undefined
                                                                                                        ? avatar
                                                                                                        : SERVERUIMAGESUSERS +
                                                                                                          demandeur.photo
                                                                                                }
                                                                                                alt=""
                                                                                                className="avatar-xs rounded-circle mr-2 "
                                                                                            />
                                                                                            {demandeur.nom +
                                                                                                ' (' +
                                                                                                this.state[
                                                                                                    'nbd' +
                                                                                                        `${Capitalize(
                                                                                                            demandeur.nom
                                                                                                        )}` +
                                                                                                        `${demandeur.id}`
                                                                                                ] +
                                                                                                ')'}
                                                                                        </CustomInput>

                                                                                );
                                                                            }
                                                                        )}
                                                                    </div>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Button
                                                            className="btn-block btn btn-soft-primary mt-1 mb-1"
                                                            id="group1"
                                                            href="#"
                                                            onClick={() => {
                                                                this.togglePanel('panel1');
                                                            }}>
                                                            <i className="uil uil-flask "> </i>Masquer les filtres
                                                        </Button>
                                                    </CardBody>
                                                </Collapse>
                                            </Card>
                                        </div>
                                    </CardBody>
                                </Card>
                            </React.Fragment>
                        </Col>
                    </Row>
                </React.Fragment>

                <Row>
                    <Col xl={12}>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col lg={12}>
                                                <div>
                                                    {this.state.result === undefined ||
                                                    this.state.result === undefined ||
                                                    this.state.result.length < 1
                                                        ? ''
                                                        : this.state.result.map((tache, index) => {
                                                              //return <p key={index}>{JSON.stringify(tache)} </p>

                                                              if (tache.accompli !== true && tache.annulee !== true) {
                                                                  // this.setState({compteurTache:index+1})
                                                                  return (
                                                                      <React.Fragment key={index}>
                                                                          <Row>
                                                                              <Col lg={12}>
                                                                                  {tache.report === true ? (
                                                                                      <UncontrolledAlert
                                                                                          color="primary"
                                                                                          className="mt-5 mb-0">
                                                                                          <strong> Report: </strong>{' '}
                                                                                          Cette tâche a été créée le{' '}
                                                                                          {moment(
                                                                                              tache.startDate
                                                                                          ).format('DD/MM/YYYY')}
                                                                                      </UncontrolledAlert>
                                                                                  ) : (
                                                                                      ''
                                                                                  )}
                                                                              </Col>
                                                                          </Row>
                                                                          <Link
                                                                              to={{
                                                                                  pathname: `/actions/${tache.action.interface}/${tache.opportuniteParent.id}/${tache.id}`,
                                                                              }}
                                                                              onClick={this.performTache}>
                                                                              <TacheComponent
                                                                                  {...tache}
                                                                                  onHoverHandler={this.selectTache}
                                                                                  //      onClickHandler={this.performTache}
                                                                              />
                                                                          </Link>
                                                                      </React.Fragment>
                                                                  );
                                                              }
                                                          })}
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <div className="mt-4">
                                                    {this.props.taches1 === undefined ? (
                                                        <Loader></Loader>
                                                    ) : (
                                                        <React.Fragment>
                                                            {this.state.compteurExpiredTache === 0 ? (
                                                                ''
                                                            ) : (
                                                                <React.Fragment>

                                                                </React.Fragment>
                                                            )}


                                                        </React.Fragment>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>

                                       {/* <Row className="mb-3 mt-5">
                                            <Col>
                                                <div className="text-center">
                                                    <a href="/" className="btn btn-white mb-3">
                                                        <Loader className="icon-dual icon-xs mr-2"></Loader>
                                                        Charger plus
                                                    </a>
                                                </div>
                                            </Col>
                                        </Row>*/}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>

                    {/*<Col xl={3}>
                        <Sticky topOffset={50}>
                            <Tache {...this.state.selectedTache} />
                        </Sticky>
                    </Col>*/}
                </Row>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = {
    getSegments,
    getCanaux,
    getSelectedCanal,
    getSelectedVille,
    getVilles,
    setProspect,
    getProspects,
    affectProspect,
    getTaches,
    getOpportunites,
    getTodayOpportunites,
    getIncomingOpportunites,
    getOthersOpportunites,
    updateTache,
    getTachesByExecuteur,
    getTachesByDemandeur,
    getAllTaches,getTachesByAgence,
};

const mapStateToProps = (state) => ({
    segments: state.Crm.segments,
    selectedCanal: state.Crm.selectedCanal,
    selectedVille: state.Crm.selectedVille,
    Canaux: state.Crm.canaux,
    Villes: state.Crm.villes,
    Prospects: state.Prospect.prospects,
    isLoading: state.Tache.loading,
    isTodayLoading: state.Opportunite.todayLoading,
    isIncomingLoading: state.Opportunite.incomingLoading,
    isOthersLoading: state.Opportunite.othersLoading,
    opportunites: state.Opportunite.opportunites,
    todayOpportunites: state.Opportunite.todayOpportunites,
    incomingOpportunites: state.Opportunite.incomingOpportunites,
    othersOpportunites: state.Opportunite.othersOpportunites,
    taches: state.Tache.taches,
    taches1: state.Tache.taches1,
    expiredTaches: state.Tache.expiredTaches,
});

TachesEnCours = connect(mapStateToProps, mapDispatchToProps, null)(TachesEnCours);
export default TachesEnCours;
