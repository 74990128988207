// @flow

import { put, takeLatest,all,call, takeEvery,takeLeading } from 'redux-saga/effects';
import { fetchJSON ,insertJSON,SERVERURI,TOKEN} from '../../../helpers/api';
import {
    SET_PROSPECT,
    PROSPECT_ADDED,
    PROSPECT_REQUEST_FAILED,
    PROSPECT_PATCHED,
    PATCH_PROSPECT,

    PROSPECTS_RECEIVED,
    GET_PROSPECTS,
    DELETE_PROSPECT,
    PROSPECT_DELETED,
    AFFECT_PROSPECT,
    PROSPECT_AFFECTED,
    GET_PROSPECT,
    VERIFY_PROSPECT,
    VALID_PROSPECT_RECEIVED,
    NOTIFY_PROSPECT_USER,
    PROSPECT_RECEIVED,
    SET_OPPORTUNITE_FROM_PROSPECT,
    OPPORTUNITE_ADDED_FROM_PROSPECT
} from './constants';

import {SET_OPPORTUNITE,OPPORTUNITE_ADDED} from '../opportunite/constants';

import moment from 'moment';
import 'moment-duration-format';

import 'moment/min/moment-with-locales'
import {getProspects} from "./actions";
//import {AGENCE_REQUEST_FAILED} from "./agence/constants";
function renderRole(role,idCommercial,idAgence){


    switch (role) {

        case 'mesProspects':
            return  "?createdBy.id="+idCommercial;
        case 'prospectsAgence':
            return  "?agenceCreatrice.id="+idAgence;
        case 'allProspects':
            return  "";
        default:

            return "";
    }
}
function* getProspectsSaga(action, baseUrl)
{
    const { idCommercial,idAgence} = action.payload;
    const options = {
        method: 'POST',
        headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}`
        },
        body:JSON.stringify({
            comm:idCommercial,
            ag:idAgence

        })
    };
    try {


        const response = yield call(fetchJSON, SERVERURI+'/prospects', options);
        yield put({ type: PROSPECTS_RECEIVED, json: response.data});
    }
    catch (error) {
        let message;
        switch (error) {
            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            case 404:
                message = 'Invalid credentials';
                break;
            default:
                //message = error;

        }
        yield put({ type: PROSPECTS_RECEIVED, json: error,status:"error", notifyMessage:"Une erreur est survenue veuillez contacter l'administrateur"});

    }
}



function* verifyProspect(action)
{
    const { tel1,tel2,email} = action.payload;

    const options = {
        method: 'GET',headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}` },
    };


    try {

        let response=[]
        let isValid=true

        if(tel1!==null&&tel1.tel1?.trim().length > 0)
        {
            const verifTel10 = yield call(fetchJSON,SERVERURI+'/prospects?tel1='+tel1.tel1, options);
            if(verifTel10["hydra:member"].length>0){
                isValid=false
                response.push(verifTel10["hydra:member"][0])
            }
            const verifTel11 = yield call(fetchJSON,SERVERURI+'/prospects?tel2='+tel1.tel1, options);
            if(verifTel11["hydra:member"].length>0){
                isValid=false
                response.push(verifTel11["hydra:member"][0])
            }
        }
        if(tel2!==null&&tel2.tel2?.trim().length > 0)
        {
            const verifTel20 = yield call(fetchJSON,SERVERURI+'/prospects?tel1='+tel2.tel2, options);
            if(verifTel20["hydra:member"].length>0){
                isValid=false
                response.push(verifTel20["hydra:member"][0])
            }
            const verifTel21 = yield call(fetchJSON,SERVERURI+'/prospects?tel2='+tel2.tel2, options);
            if(verifTel21["hydra:member"].length>0){
                isValid=false
                response.push(verifTel21["hydra:member"][0])
            }
        }
        if(email!==null&&email?.trim().length > 0){
            const verifEMAIL = yield call(fetchJSON,SERVERURI+'/prospects?email='+email, options);
            if(verifEMAIL["hydra:member"].length>0){
                response.push(verifEMAIL["hydra:member"][0])
                isValid=false
            }
        }




        yield put({
            type: VALID_PROSPECT_RECEIVED,
            isValid,
            response,
        });


    }
    catch (error) {

        let message;
        switch (error.status) {
            case 500:

                yield put({
                    type: PROSPECT_REQUEST_FAILED,
                    error:error.status,
                    response:"Le Champ doit etre unqiue",
                    //json: response["hydra:member"],
                });
                message="le Prospect existe veuillez choisir un autre nom"


            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
    }
}


function* setProspect(action)
{
    const { data,history} = action.payload;

        

    const options = {
        method: 'POST',
        Accept: 'application/json',
        headers:{
           'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}`
        },
        body:JSON.stringify({
            prenom: data.prenom,
            nom: data.nom,
            ste: data.ste,
            sm: data.sm,
            email: data.email===''?null:data.email,
            tel1: data.tel1.tel1,
            tel2: data.tel2===null?null:data.tel2.tel2,
            adresse:data.adresse,
            longitude:parseFloat(data.lng),
            latitude:parseFloat(data.lat),
            isSociete:data.isSociete,
            remarque: data.remarque,
            villeProspect:data.ville,
            canalProspect:data.canaux,
            segments:data.segments,
            createdBy:data.createdBy,
            agenceCreatrice:data.agence,
        })
    }
    try {

        const response = yield call(insertJSON,SERVERURI+'/setProspect', options);

        yield put({
            type: PROSPECT_ADDED,
            data,
            status:"success",notifyMessage:"Félicitations le prospect a été ajouté avec succés",
            addedProspect:response
        });

      /*  yield call(() => {

            history.push('/prospects/prospects');
        });*/

     //   yield* getProspects()
       // yield* createOpportuniteFromProspect(action)



    }
    catch (error) {

        let message;
        switch (error.status) {
            case 500:

                yield put({
                    type: PROSPECT_REQUEST_FAILED,
                    error:error.status,
                    response:"Le Champ doit etre unqiue",
                    //json: response["hydra:member"],
                });
                message="le Prospect existe veuillez choisir un autre nom"


            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put({
            type: PROSPECT_ADDED,

            status:"error",notifyMessage:"Une erreur est survenue veuillez réessayer"
        });
    }
}

function* createOpportuniteFromProspect(action)
{
    const { data,history} = action.payload;

    const options = {
        method: 'POST',
        Accept: 'application/json',
        headers:{
            'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}`
        },
        body:JSON.stringify({
            prenom: data.prenom,
            nom: data.nom,
            ste: data.ste,
            sm: data.sm,
            adresse:data.adresse,
            email: data.email,
            tel1: data.tel1,
            tel2: data.tel2,
            refSteg: data.refSteg,
            remarque: data.remarque,
            villeOpportunite:data.ville[0],
            canalOpportunite:data.canaux[0],
            segments:data.segments,
            createdBy:data.owner,
            chargeCommercial:data.owner
        })
    }
    try {

        const response = yield call(insertJSON,SERVERURI+'/opportunites', options);
        yield put({
            type: OPPORTUNITE_ADDED_FROM_PROSPECT,
            data,
            statusOppProspectMessage:"success",notifyOppProspectMessage:"Félicitations l'opportunité a été créee"

        });
        //     yield put({ type: PROSPECTS_RECEIVED, json: response["hydra:member"],status:"info",notifyMessage:response["hydra:totalItems"]+" prospects trouvés"});
    }
    catch (error) {

        let message;
        switch (error.status) {
            case 500:

                 message="le Prospect existe veuillez choisir un autre nom"


            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put({
            type: OPPORTUNITE_ADDED_FROM_PROSPECT,

            statusOppProspectMessage:"error",notifyOppProspectMessage:"Une erreur est survenue lors de la création de l'opportunité"
        });
    }
}


function* updateProspect(action)
{
    const { data,history} = action.payload;
    const options = {
        method: 'PUT',
        headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}`
        },
        body:JSON.stringify({
            nom:data.nom,
            prenom:data.prenom,
            ste:data.ste,
            isSociete:data.isSociete,
            tel1:data.tel1,
            tel2:data.tel2,
            email:data.email,
            adresse:data.adresse,
            longitude:parseFloat(data.longitude),
            latitude:parseFloat(data.latitude),
            villeProspect:data.villeProspect['@id'],
            agenceCreatrice:data.agenceCreatrice['@id'],
            createdBy:data.createdBy['@id']
        })
    }
    try {

        const response = yield call(insertJSON,SERVERURI+'/prospects/'+data.id, options);
        yield put({
            type: PROSPECT_PATCHED,
            updatedProspect:response.json,
        });
    }
    catch (error) {
        let message;
        switch (error.status) {
            case 500:

                yield put({
                    type: PROSPECT_REQUEST_FAILED,
                    error:error.status,
                    response:"Le Champ doit etre unqiue",
                    //json: response["hydra:member"],
                });
                message="le Prospect existe veuillez choisir un autre nom"
            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
    }
}
function* affectProspectToCommercial(action)
{

    const{data,history}=action.payload


    let options = {
        method: 'POST', Accept: 'application/json',
        headers: {
            'Content-Type': 'application/json', 'Authorization': `Bearer ${TOKEN()}`
        },
        body: JSON.stringify({
            // prospect:prospects[0],
            segment: data.segment,
            agence: data.agence,
            agenceTache: data.agenceTache,
            date: data.date,
            demandeur: data.demandeur,
            executeur: data.executeur,
            prospects: data.prospects,
            etat: data.etat,
            selectedAction: data.selectedAction,
            selectedPriorite: data.selectedPriorite,
        })


    }
    try {

        let dt= {
            idCommercial:data.demandeur,
            idAgence:""
        }

        const response = yield call(insertJSON,SERVERURI+'/affectProspect', options)//.then(yield call(getProspects(dt,"")));

        yield call({
            type: PROSPECT_AFFECTED,status:"info", notifyMessage:"Affectation effectuée"
        },

            );
      //  yield* getProspects(dt,"")
      ///  yield put(getProspects)



        yield call(() => {
             alert("hello")

         });
    }
        catch (error) {
                let message;
                switch (error.status) {
                    case 500:
                        yield put({
                            type: PROSPECT_REQUEST_FAILED,
                            error:error.status,
                            response:"Le Champ doit etre unqiue",
                            //json: response["hydra:member"],
                        });
                        message="le Prospect existe veuillez choisir un autre nom"
                    case 400:
                        message = 'Echec connexion';
                        break;
                    case 401:
                        message = 'Invalid credentials';
                        break;
                    default:
                        message = error;
                }
            }


  //  yield* getProspects(action)
}

function* getProspect(action)
{
    const{idProspect}=action.payload

    const options = {
        method: 'GET',headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}` },
    };
    try {
        const response = yield call(fetchJSON, SERVERURI+'/prospects/'+idProspect, options);


        yield put({ type: PROSPECT_RECEIVED, json: response});
    } catch (error) {
        let message;
        switch (error.status) {
            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
    }
}






function* deleteProspect(action)
{
    const { history,id} = action.payload;
    const options = {
        method: 'DELETE',
        headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}`
        },
        body:JSON.stringify({
            id
        })
    }
    try {

        const response = yield call(fetchJSON,SERVERURI+'/prospects/'+id, options);
        yield put({
            type: PROSPECT_DELETED,
            //data,

        });
    }
    catch (error) {
        let message;
        switch (error.status) {
            case 500:

                yield put({
                    type: PROSPECT_REQUEST_FAILED,
                    error:error.status,
                    response:"Le Champ doit etre unqiue",
                    //json: response["hydra:member"],
                });
                message="le Prospect existe veuillez choisir un autre nom"
            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
    }
}
function* prospectWatcher() {
    yield takeEvery(GET_PROSPECTS, getProspectsSaga)
    yield takeEvery(SET_PROSPECT, setProspect)
    yield takeEvery(PATCH_PROSPECT, updateProspect)
    yield takeEvery(AFFECT_PROSPECT, affectProspectToCommercial)
    yield takeEvery(DELETE_PROSPECT, deleteProspect)
    yield takeEvery(GET_PROSPECT, getProspect)
    yield takeEvery(VERIFY_PROSPECT, verifyProspect)
    yield takeEvery(SET_OPPORTUNITE_FROM_PROSPECT,createOpportuniteFromProspect)

}
////////////////////////////////////////
//////USERS SAGA////////////////////////
////////////////////////////////////////
export default function* prospectSaga() {
    yield all([
        prospectWatcher()
    ]);
}
