import React from 'react';
import { connect } from 'react-redux';
import { getUsers,usersReceived,getSelectedVille,getSelectedUser } from '../redux/crm/actions';
import {AvField} from "availity-reactstrap-validation";
import {getLoggedInUser} from "../helpers/authUtils";
import AgencesSelectSingle from "./AgencesSelectSingle";

class UsersSelectSingle extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            selectedUser:null
        }
        this.handleChange = this.handleChange.bind(this);
        this.getCommerciauxAgence = this.getCommerciauxAgence.bind(this);
    }
    handleChange=(e)=>{
        let id=e.target.value
        let cible = this.state.commerciaux.filter(us =>
            (us.id.toString() === id.toString()))
        this.setState({
            selectedUser:cible[0]
        })

        this.props.getSelectedUser(cible[0].id)
    }
    getCommerciauxAgence = (agences,id) => {
        let agence= agences.filter((ag)=>ag.id==id)
        let commerciaux=agence[0].commerciauxAgence
        return commerciaux
    };
    componentDidMount() {
       let commerciaux=this.getCommerciauxAgence(this.props.data.agences,getLoggedInUser().agenceId)
             this.setState({
            commerciaux:commerciaux,
        })
    }
    getAsyncData = async (actions) => {
        return new Promise((resolve) => {
            resolve(actions);
        });
    };
    componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.selectedAgence!==this.props.selectedAgence){
        let commerciaux=this.getCommerciauxAgence(this.props.data.agences,this.props.selectedAgence.id)

        this.setState({
            commerciaux:commerciaux,
            selectedUser:commerciaux[0],
            selectedAgence:this.props.selectedAgence
        })
        this.props.getSelectedUser(commerciaux[0].id)
    }




   /* if (prevProps.selectedUser !== this.props.selectedUser) {
        if(this.props.selectedUser===undefined){
            this.getAsyncData(this.props.users).then((users) => {
                this.setState({
                    selectedUser: users[0].id
                }, function () {
                })
            })
        }
        else{
            this.setState({
                selectedUser: this.props.selectedUser.id
            }, function () {
            })
        }
    }
    if(prevProps.agenceId!==this.props.agenceId){
        let role = this.props.role
        let selectName=this.props.name
        let userId=this.props.userId
        let agenceId=this.props.agenceId
        //console.log("hello saga", this.props.role)
        //const  testFilter ={"filter":"?roles=ROLE_COORDINATEUR"}
        console.log("Role",role)
        console.log("UserId",userId)
        console.log("agenceId",agenceId)
        this.props.getUsers( role,"",userId,agenceId)
    }
    if(prevProps.users!==this.props.users){
        this.props.getSelectedUser(this.props.users[0]);
    }
    console.log("uuuuuuuuuuuuuuuuuuuuser",this.props.selectedUser)*/
}

      expose_users =({})=> (
       this.state.commerciaux&&<AvField type="select" name={this.props.name}  label={this.props.label}  helpMessage={this.props.helpMessage}
                                        onChange={this.handleChange}
                                        /*value={this.state.selectedUser===null?this.props.users[0].id:this.state.selectedUser}*/
                                        value={ this.props.selectedUser===null||this.props.selectedUser===undefined?this.props.default:this.props.selectedUser}
       >
       {this.state.commerciaux.map((user,index)=><option key={index} value={user.id}>{user.prenom} {user.Nom} </option>)}
       </AvField>
);

render(){
return(

                   <div>
                       {console.log("users received", this.props.users)}
                       {this.expose_users(this.props)}
                  </div>

)
}


}

const mapDispatchToProps = {
getUsers,getSelectedUser
};
const mapStateToProps = (state,action) => ({
data:state.Crm.data,
users: state.Crm.users,
selectedUser: state.Crm.selectedUser,
selectedAgence: state.Agence.selectedAgence,
});
UsersSelectSingle = connect(mapStateToProps,mapDispatchToProps ,null)(UsersSelectSingle)

export default UsersSelectSingle;