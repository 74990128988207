import React from 'react';
import { connect } from 'react-redux';
import { getAgences,agencesReceived,getSelectedAgence } from '../redux/crm/agence/actions';
import {AvField} from "availity-reactstrap-validation";
import Loader from "./Loader";

class AgencesSelectSingle extends React.Component{
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            selectedAgence:null
        }
        this.handleChange = this.handleChange.bind(this);


    }

    getSelectedAgence = async (agences) => {
        return new Promise((resolve) => {
            resolve(agences);
        });
    };
    handleChange=(e)=>{

        this.setState({selectedAgence:e.target.value})
        let nom = e.target.options[e.nativeEvent.target.selectedIndex].attributes.nom.value
        let id = e.target.value
        // this.props.setVillePosition(lat, long, population)
        let cible = this.state.agences.filter(ag =>
            (ag.id.toString() === id.toString()))
        this.setState({
            selectedAgence:cible[0]
        })
        // this.props.setVillePosition(lat, long, population)
        this.props.getSelectedAgence(cible[0])


    }
    componentDidMount() {
       /*  this.props.getAgences("agencesActives",'','','')
          this.setState({selectedAgence: this.props.default},function () {
            })*/

       let agences=this.props.data.agences
        this.setState({
            agences:agences,
            slectedAgence:this.props.default
        })

    }
    componentWillUnmount() {
        this._isMounted=false
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
       /* if(prevProps.default!==this.props.default ){
            this.getSelectedAgence(this.props.default).then((agence) => {
               // setTimeout(() => {
                    this.setState(prevState => ({
                        selectedAgence: {                   // object that we want to update
                            ...prevState.selectedAgence,    // keep all other key-value pairs
                            selectedAgence: agence.selectedAgence
                        },
                    }))

                    //}, 1000)
           });
        }*/
    }

    expose_agences =()=> (

            <AvField type="select" name={this.props.name}  label={this.props.label}  helpMessage={this.props.helpMessage}
                onChange={this.handleChange} value={this.state.selectedAgence===null?this.props.default:this.state.selectedAgence.id} >

                {this.state.agences.map((agence,index)=><option key={index} value={agence.id} nom={agence.Nom}>{agence.Nom} </option>)}


            </AvField>

);

render(){
return(



    <div>
    {this.state.agences&&this.expose_agences(this.props)}

</div>

)
}


}

const mapDispatchToProps = {
getAgences,getSelectedAgence
};
const mapStateToProps = (state,action) => ({
     data:state.Crm.data,
  //   agences: state.Agence.agencesActives,
     loading: state.Agence.loading,
     selectedAgence: state.Agence.selectedAgence,
});

AgencesSelectSingle = connect(mapStateToProps,mapDispatchToProps ,null)(AgencesSelectSingle)

export default AgencesSelectSingle;