// @flow
import {
   
    SET_OPPORTUNITE,
    OPPORTUNITE_ADDED,
    OPPORTUNITE_REQUEST_FAILED,
    GET_OPPORTUNITES,
     OPPORTUNITE_RECEIVED,GET_OPPORTUNITE,
    OPPORTUNITES_RECEIVED, OPPORTUNITE_PATCHED,PATCH_OPPORTUNITE,DELETE_OPPORTUNITE, OPPORTUNITE_DELETED,AFFECT_OPPORTUNITE,OPPORTUNITE_AFFECTED,GET_TODAY_OPPORTUNITES,TODAY_OPPORTUNITES_RECEIVED,GET_INCOMING_OPPORTUNITES,INCOMING_OPPORTUNITES_RECEIVED,GET_OTHERS_OPPORTUNITES,OTHERS_OPPORTUNITES_RECEIVED,
    INSTALLATION_CREATED,SET_INSTALLATION,GET_INSTALLATIONS,
    INSTALLATIONS_RECEIVED,
    GET_INSTALLATIONS_OPPORTUNITE,
    INSTALLATIONS_OPPORTUNITE_RECEIVED,
    DELETE_INSTALLATION,
    INSTALLATION_DELETED,
    UPDATE_INSTALLATION,
    INSTALLATION_UPDATED,
    GET_OPPORTUNITE_BY_COMMERCIAL,
    OPPORTUNITE_BY_COMMERCIAL_RECIEVED,
    GET_OPPORTUNITE_BY_COMMERCIAL_BY_PERIOD,
    OPPORTUNITE_BY_COMMERCIAL_BY_PERIOD_RECIEVED,
    GET_DASHBOARD_BY_COMMERCIAL,DASHBOARD_BY_COMMERCIAL_RECIEVED,
    GET_DASHBOARD_BY_ADMIN,DASHBOARD_BY_ADMIN_RECIEVED,GET_AGENCES_STATS,AGENCES_STATS_RECIEVED,GET_PERFORMANCE,PERFORMANCE_RECIEVED

} from './constants';
import {GET_TACHES_BY_AGENCE, TACHES_BY_AGENCE_RECEIVED} from "../tache/constants";


const Opportunite = (state ={}, action) => {
    switch (action.type) {
        case GET_PERFORMANCE:
            return { ...state, loading: true };
        case PERFORMANCE_RECIEVED:
            return { ...state, performance:action.json,loading: false }
        case GET_AGENCES_STATS:
            return { ...state, loading: true };
        case AGENCES_STATS_RECIEVED:
            return { ...state, agencesStats:action.json,loading: false }
        case GET_DASHBOARD_BY_ADMIN:
            return { ...state, loading: true };
        case DASHBOARD_BY_ADMIN_RECIEVED:
            return { ...state, dashboardAdmin:action.json,loading: false }
        case GET_DASHBOARD_BY_COMMERCIAL:
            return { ...state, loading: true };
        case DASHBOARD_BY_COMMERCIAL_RECIEVED:
            return { ...state, dashboard:action.json,loading: false }
        case GET_OPPORTUNITE_BY_COMMERCIAL:
            return { ...state, loading: true };
        case OPPORTUNITE_BY_COMMERCIAL_RECIEVED:
            return { ...state, opportunites1:action.json,loading: false }
        case GET_OPPORTUNITE_BY_COMMERCIAL_BY_PERIOD:
            return { ...state, loading: true };
        case OPPORTUNITE_BY_COMMERCIAL_BY_PERIOD_RECIEVED:
            return { ...state, opportunites1:action.json,loading: false }
      
        case SET_OPPORTUNITE:
            return { ...state, opportuniteAdded:true,loading: true };
        case OPPORTUNITE_ADDED:
            return { ...state, loading:true };

        case PATCH_OPPORTUNITE:
            return { ...state, opportunitePatched:true,loading: true };
        case OPPORTUNITE_PATCHED:
            return { ...state, loading:false };


        case AFFECT_OPPORTUNITE:
            return { ...state, opportuniteAffected:true,loading: true };
        case OPPORTUNITE_AFFECTED:
            return { ...state, loading:true };

        case DELETE_OPPORTUNITE:
            return { ...state, opportuniteDeleted:true,loading: true };
        case OPPORTUNITE_DELETED:
            return { ...state, loading:false };
        case OPPORTUNITE_REQUEST_FAILED:
            return { ...state,  error:action.error, loading:false };

        case GET_OPPORTUNITES:
            return { ...state, loading: true };
        case OPPORTUNITES_RECEIVED:
            return { ...state, opportunites:action.json, loading: false }

 case GET_TODAY_OPPORTUNITES:
            return { ...state, todayLoading: true };
        case TODAY_OPPORTUNITES_RECEIVED:
            return { ...state, todayOpportunites:action.json, todayLoading: false }

 case GET_INCOMING_OPPORTUNITES:
            return { ...state, incomingLoading: true };
        case INCOMING_OPPORTUNITES_RECEIVED:
            return { ...state, incomingOpportunites:action.json, incomingLoading: false }


 case GET_OTHERS_OPPORTUNITES:
            return { ...state, othersLoading: true };
        case OTHERS_OPPORTUNITES_RECEIVED:
            return { ...state, othersOpportunites:action.json, othersLoading: false }
        case GET_OPPORTUNITE:
            return { ...state, loading: true };
        case OPPORTUNITE_RECEIVED:
            return { ...state, opportunite:action.json, loading: false }
        case SET_INSTALLATION:
            return { ...state, installationAdded:true,loading: true };
        case INSTALLATION_CREATED:
            return { ...state, loading:true };
        case GET_INSTALLATIONS_OPPORTUNITE:
            return { ...state, installationsOpportunite:action.json,loading: true };
        case INSTALLATIONS_OPPORTUNITE_RECEIVED:
            return { ...state, installationsOpportunite:action.json,loading: false };
        case DELETE_INSTALLATION:
            return { ...state, installatinonDeleted:true,loading: true };
        case INSTALLATION_DELETED:
            return { ...state, loading:false };
        case UPDATE_INSTALLATION:
            return { ...state, installationUpdated:true,loading: true };
        case INSTALLATION_UPDATED:
            return { ...state, loading:true };
        default:
            return state;
    }
};
export default Opportunite