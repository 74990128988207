
export const SET_PROSPECT = 'SET_PROSPECT';
export const PROSPECT_ADDED = 'PROSPECT_ADDED';
export const PROSPECT_REQUEST_FAILED = 'PROSPECT_REQUEST_FAILED';

export const GET_PROSPECTS = 'GET_PROSPECTS';
export const PROSPECTS_RECEIVED = 'PROSPECTS_RECEIVED';

export const PATCH_PROSPECT = 'PATCH_PROSPECT';
export const PROSPECT_PATCHED = 'PROSPECT_PATCHED';

export const AFFECT_PROSPECT = 'AFFECT_PROSPECT';
export const PROSPECT_AFFECTED = 'PROSPECT_AFFECTED';

export const DELETE_PROSPECT = 'DELETE_PROSPECT';
export const PROSPECT_DELETED = 'PROSPECT_DELETED';

export const GET_PROSPECT = 'GET_PROSPECT';
export const PROSPECT_RECEIVED = 'PROSPECT_RECEIVED';

export const VERIFY_PROSPECT = 'VERIFY_PROSPECT';
export const VALID_PROSPECT_RECEIVED = 'VALID_PROSPECT_RECEIVED';
export const NOTIFY_PROSPECT_USER = 'NOTIFY_PROSPECT_USER';


export const SET_OPPORTUNITE_FROM_PROSPECT = 'SET_OPPORTUNITE_FROM_PROSPECT';
export const OPPORTUNITE_ADDED_FROM_PROSPECT = 'OPPORTUNITE_ADDED_FROM_PROSPECT';

export const SET_TACHE_FROM_PROSPECT = 'SET_TACHE_FROM_PROSPECT';
export const TACHE_ADDED_FROM_PROSPECT = 'TACHE_ADDED_FROM_PROSPECT';