import {
    SET_TACHE,
    TACHE_ADDED,
    TACHE_REQUEST_FAILED,
    TACHES_RECEIVED,
    GET_TACHES,
    PATCH_TACHE,
    TACHE_PATCHED,
    DELETE_TACHE, TACHE_DELETED,
    AFFECT_TACHE,TACHE_AFFECTED,
    GET_TACHE,
    TACHE_RECEIVED,
    OPPORTUNITE_RECEIVED,GET_OPPORTUNITE,
    SET_REMARQUE_TACHE,REMARQUE_TACHE_ADDED,
    AFFECTED_TACHE_FROM_OPPORTUNITE,
    AFFECT_TACHE_FROM_OPPORTUNITE,
    GET_TACHES_BY_EXECUTEUR,TACHES_BY_EXECUTEUR_RECEIVED,
    GET_TACHES_BY_DEMANDEUR,TACHES_BY_DEMANDEUR_RECEIVED,
    GET_ALL_TACHES,ALL_TACHES_RECEIVED,
    GET_TACHES_BY_AGENCE, TACHES_BY_AGENCE_RECEIVED




} from './constants';
import {GET_PROSPECTS, OPPORTUNITE_ADDED_FROM_PROSPECT, SET_OPPORTUNITE_FROM_PROSPECT} from "../prospect/constants";

export const setTache = (data,history) => ({
    type: SET_TACHE,
    payload: {data,history},
});

export const tacheAdded = (data, history) => ({
    type: TACHE_REQUEST_FAILED,
    payload: { data, history},
});
export const updateTache = (data,id) => ({
    type: PATCH_TACHE,
    payload: {data,id},
});

export const tachePatched = (data,id) => ({
    type: TACHE_PATCHED,
    payload: { data,id},
});

export const affectTache = (taches,idCommercial) => ({
    type: AFFECT_TACHE,
    payload: {taches,idCommercial},
});

export const tacheAffected = (taches,idCommercial) => ({
    type: TACHE_AFFECTED,
    payload: { taches,idCommercial},
});





export const deleteTache = (history,id) => ({
    type: DELETE_TACHE,
    payload: {history,id},
});

export const tacheDeleted = (history,id) => ({
    type: TACHE_DELETED,
    payload: { history,id},
});

export const getProspects = (role,idCommercial,idAgence) => ({
    type: GET_PROSPECTS,
    payload: { role,idCommercial,idAgence},
});
export const getTaches = (role,idCommercial,idAgence,dateDebut,DateFin) => ({
    type: GET_TACHES,
    payload: {role,idCommercial,idAgence,dateDebut,DateFin},
});

export const tachesReceived = (role,idCommercial,idAgence,dateDebut,DateFin) => ({
    type: TACHES_RECEIVED,
    payload: {role,idCommercial,idAgence,dateDebut,DateFin},

});


export const setRemarqueTache = (id,commercial,remarque) => ({
    type: SET_REMARQUE_TACHE,
    payload: {id,commercial,remarque},
});

export const remarqueTacheAdded = (id,commercial,remarque) => ({
    type: REMARQUE_TACHE_ADDED,
    payload: {id,commercial,remarque},
});

export const getTache = (tacheId) => ({
    type: GET_TACHE,
    payload: {tacheId},
});

export const tacheReceived = () => ({
    type: TACHE_RECEIVED,

});

export const getOpportunite = (oppId) => ({
    type: GET_OPPORTUNITE,
    payload: {oppId}
});

export const opportuniteReceived = () => ({
    type: OPPORTUNITE_RECEIVED,

});


export const createOpportuniteFromProspect = (data,history) => ({
    type: SET_OPPORTUNITE_FROM_PROSPECT,
    payload: {data,history},
});

export const affectTacheFromOpportunite = (data,history) => ({
    type: AFFECT_TACHE_FROM_OPPORTUNITE,
    payload: {data,history},
});

export const affectedTacheFromOpportunite = (data, history) => ({
    type: AFFECTED_TACHE_FROM_OPPORTUNITE,
    payload: { data, history},
});


export const getTachesByExecuteur = (commId,annulee,accompli) => ({
    type: GET_TACHES_BY_EXECUTEUR,
    payload: {commId,annulee,accompli},
});

export const tachesByExecuteurReceived = () => ({
    type: TACHES_BY_EXECUTEUR_RECEIVED,

});

export const getTachesByDemandeur = (commId,annulee,accompli) => ({
    type: GET_TACHES_BY_DEMANDEUR,
    payload: {commId,annulee,accompli},
});

export const tachesByDemandeurReceived = () => ({
    type: TACHES_BY_DEMANDEUR_RECEIVED,
});

export const getAllTaches = (annulee,accompli) => ({
    type: GET_ALL_TACHES,
    payload: {annulee,accompli},
});

export const allTachesReceived = () => ({
    type: ALL_TACHES_RECEIVED,
});
export const getTachesByAgence = (agence,annulee,accompli) => ({
    type: GET_TACHES_BY_AGENCE,
    payload: {agence,annulee,accompli},
});

export const tachesByAgenceReceived = () => ({
    type: TACHES_BY_AGENCE_RECEIVED,
});

