import React from 'react';
import { connect } from 'react-redux';
import { getCanaux,canauxReceived,getSelectedCanal } from '../redux/crm/actions';
import {AvField} from "availity-reactstrap-validation";
import Select from "react-select";
import ReactDOM from "react-dom";
import Loader from "./Loader";
class CanauxSelectSingle extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            loading:true,
            selectedCanal:null,
            Canaux:[{}]
        }
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange=(e)=>{
        let nom= e.target.options[e.nativeEvent.target.selectedIndex].attributes.nom.value
        let id=e.target.value
        let cible = this.props.Canaux.filter(can =>
            (can.id.toString() === id.toString()))
        this.setState({
            selectedCanal:cible[0]
        })

        // this.props.setVillePosition(lat, long, population)
        this.props.getSelectedCanal(cible[0])
        //this.props.getSelectedCanal(id,nom)
    }
    componentDidMount() {
        this.props.getCanaux()
        this.setState({selectedCanal: this.props.default})
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.selectedCanal !== this.props.selectedCanal) {
            this.setState({
                selectedCanal: this.props.selectedCanal.id
            }, function () {

            })
        }
    }

    expose_Canaux =({Canaux})=> (
        Canaux ?
            <AvField type="select" name="canaux" label="Provenance du prospect" helpMessage="Veuillez choisir une villle"
                     value={ this.state.selectedCanal===null||this.state.selectedCanal===undefined?this.props.default:this.state.selectedCanal.id}    onChange={this.handleChange}>
                {Canaux.map((canal,index)=><option key={index} value={canal.id}   nom={canal.nom}
                   >{canal.nom}</option>)}
            </AvField>
        :null
);
render(){

   // this.setState({Canaux:this.props})
    return(
    <div>
        {this.props.loading==true&&this.props.getCanaux()? <Loader />:this.expose_Canaux(this.props)}
    </div>
        )
    }
}
const mapDispatchToProps = {
    getCanaux,getSelectedCanal,canauxReceived
};
const mapStateToProps = (state) => ({
    Canaux: state.Crm.canaux,
    loading:state.Crm.loading,
    selectedCanal:state.Crm.selectedCanal,
});
CanauxSelectSingle = connect(mapStateToProps,mapDispatchToProps ,null)(CanauxSelectSingle)
export default CanauxSelectSingle;