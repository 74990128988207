import React from 'react';
import { connect } from 'react-redux';
import { getUsers,usersReceived } from '../redux/crm/actions';
import {AvField} from "availity-reactstrap-validation";

class CoordinateursSelectSingle extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            selectUser:null
        }
        this.handleChange = this.handleChange.bind(this);


    }


    handleChange=(event)=>{
        console.log("hello change", event.target.value);
        this.setState({selectUser:event.target.value})
    }

    componentDidMount() {
        let role = this.props.role
        let selectName=this.props.name
        //console.log("hello saga", this.props.role)
        //const  testFilter ={"filter":"?roles=ROLE_COORDINATEUR"}
        this.props.getUsers( role,selectName)
       // this.setState({selectUser:1})
    // this.setState({selectedVille:52})
    }



    expose_users =({users})=> (
        users ?
            <AvField type="select" name={this.props.name}  label={this.props.label}  helpMessage={this.props.helpMessage}
                onChange={this.handleChange} value={this.state.selectUser} >

                {users.map((user,index)=><option key={index} value={user.id}>{user.prenom} {user.Nom} </option>)}
            </AvField>
        :null
);

render(){
return(
                   <div>
                       {console.log("users received", this.props)}
                       {this.expose_users(this.props)}
                  </div>

)
}


}

const mapDispatchToProps = {
getUsers
};
const mapStateToProps = (state,action) => ({
users: state.Crm.coordinateur,

});
CoordinateursSelectSingle = connect(mapStateToProps,mapDispatchToProps ,null)(CoordinateursSelectSingle)

export default CoordinateursSelectSingle;