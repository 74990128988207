
export const SET_TACHE = 'SET_TACHE';
export const TACHE_ADDED = 'TACHE_ADDED';
export const TACHE_REQUEST_FAILED = 'TACHE_REQUEST_FAILED';

export const GET_TACHES = 'GET_TACHES';
export const TACHES_RECEIVED = 'TACHES_RECEIVED';

export const GET_TACHES_BY_EXECUTEUR = 'GET_TACHES_BY_EXECUTEUR';
export const TACHES_BY_EXECUTEUR_RECEIVED = 'TACHES_BY_EXECUTEUR_RECEIVED';

export const GET_TACHES_BY_DEMANDEUR = 'GET_TACHES_BY_DEMANDEUR';
export const TACHES_BY_DEMANDEUR_RECEIVED = 'TACHES_BY_DEMANDEUR_RECEIVED';


export const GET_ALL_TACHES = 'GET_ALL_TACHES';
export const ALL_TACHES_RECEIVED = 'ALL_TACHES RECEIVED';

export const GET_TACHES_BY_AGENCE = 'GET_TACHES_BY_AGENCE';
export const TACHES_BY_AGENCE_RECEIVED = 'TACHES_BY_AGENCE_RECEIVED';


export const GET_TACHE = 'GET_TACHE';
export const TACHE_RECEIVED = 'TACHE_RECEIVED';

export const PATCH_TACHE = 'PATCH_TACHE';
export const TACHE_PATCHED = 'TACHE_PATCHED';

export const AFFECT_TACHE = 'AFFECT_TACHE';
export const TACHE_AFFECTED = 'TACHE_AFFECTED';

export const DELETE_TACHE = 'DELETE_TACHE';
export const TACHE_DELETED = 'TACHE_DELETED';

export const GET_OPPORTUNITE = 'GET_OPPORTUNITE';
export const OPPORTUNITE_RECEIVED = 'OPPORTUNITE_RECEIVED';

export const SET_REMARQUE_TACHE = 'SET_REMARQUE_TACHE';
export const REMARQUE_TACHE_ADDED = 'REMARQUE_TACHE_ADDED';


export const AFFECT_TACHE_FROM_OPPORTUNITE = 'AFFECT_TACHE_FROM_OPPORTUNITE';
export const AFFECTED_TACHE_FROM_OPPORTUNITE = 'AFFECTED_TACHE_FROM_OPPORTUNITE';